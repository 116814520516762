import { Auth, Storage } from 'aws-amplify';
import * as XLSX from "xlsx";
import { getInstanceParameterFromManager } from "../../services/InstanceUtils";
import { PDFDocument } from 'pdf-lib';
import { Buffer } from "buffer";
import { v4 as uuid } from "uuid";
import { S3Client, HeadObjectCommand, ListObjectsV2Command } from '@aws-sdk/client-s3';
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";
import { loadS3File, fetchMsg, logAudit, notifyUser, invokeLambdaWithRetry, uint8Decode } from '../../services/Helper';
import config from '../../aws-exports';
export const getSpFolderPath = async (item) => {
    let spUrl = await getInstanceParameterFromManager("SHAREPOINT_ROOT_PATH");
    return decodeURIComponent(spUrl.sharePointURL.replace("{subid}", item.submissionIdentifier).match(/\?.+/)[0].match(/id=(.+?)&/)[1].replace("%2FShared%20Documents%2F", ""))
}

export async function getDocDetails(document, showArchived) {
    try {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        var body = {
            "access_token": user.signInUserSession.idToken.jwtToken,
            "target": "fetchDocDetails",
            "document": document
        };
        if (showArchived) {
            body["showArchived"] = showArchived;
        }
        const init = {
            body: body
        };
        const resp = await invokeLambdaWithRetry("instanceHandler", init);
        return resp.response;
    } catch (err) {
        console.error('err: ', err);
        return false;
    }
}

export const fetchSubmissionDocumentlist = async (item) => {
    var fetchDocList = await getDocList(item);
    return fetchDocList;
}

export async function getDocList(document) {
    try {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
            "access_token": user.signInUserSession.idToken.jwtToken,
            "target": "fetchDocList",
            "document": document
        };
        const init = {
            body: body
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        return data?.response;
    } catch (err) {
        console.error('err: ', err);
        return false;
    }
}

export async function fetchDeclineCodeList(mergedFileObj) {
    try {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
            "access_token": user.signInUserSession.idToken.jwtToken,
            "target": "listTriageDeclinedReasons",
            "document": mergedFileObj
        };
        const init = {
            body: body
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        return data?.response;
    } catch (err) {
        console.error(err);
        return false;
    }
}

export async function fetchJsonData(details) {
    const jsonS3DataRes = await Storage.get(details.docPath, { download: true });
    return jsonS3DataRes.Body.text().then(json_data => {
        return json_data;
    })
}

export async function fetchExcelData(instanceExcelDetails) {
    const excelS3DataRes = await Storage.get(instanceExcelDetails.docPath, { download: true });
    const buffer = await excelS3DataRes.Body.arrayBuffer();
    const workbook = XLSX.read(buffer, { type: 'array' });
    const sheets = workbook.Workbook.Sheets.filter(i => !i.Hidden).map(i => i.name);
    return sheets;
}

export function fetchSheets(buffer) {
    const workbook = XLSX.read(buffer);
    const sheets = workbook.Workbook.Sheets.filter(i => !i.Hidden).map(i => i.name);
    return sheets;
}

export async function fetchExcelFromS3(filePath) {
    try {
        let response = await fetchFromS3(filePath);
        let byteArray = await response.Body.arrayBuffer();
        return byteArray;
    } catch {
        return false;
    }
}

export async function fetchJSONFromS3(filePath) {
    try {
        let response = await fetchFromS3(filePath);
        const jsonData = await response.Body.text();
        return JSON.parse(jsonData);
    } catch {
        return false;
    }
}

export async function fetchFromS3(filePath) {
    let resp = await Storage.get(filePath, {
        level: 'public',
        bucket: config.aws_user_files_s3_bucket,
        region: config.aws_project_region,
        download: true,
        cacheControl: "no-cache"
    });
    return resp;
}

export async function updateToS3(filePath, body) {
    return await Storage.put(filePath, body, {
        level: 'public',
        bucket: config.aws_user_files_s3_bucket,
        region: config.aws_project_region
    });
}

export async function getStorageData(instanceDetails) {
    try {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
            access_token: user.signInUserSession.idToken.jwtToken,
            target: "fetchStorageDetails",
            document: instanceDetails,
        };
        const init = {
            body: body,
        };
        // if (data !== undefined) {
        //     return JSON.parse(uint8Decode(data.Payload)).response;
        // }
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        return data?.response;
    } catch (err) {
        console.log("err: ", err);
        return false;
    }
}

export async function getPdfPageNumber(pdfObj) {
    try {
        const s3Out = await Storage.get(pdfObj.documentFullPath.replace(/(\.[^.]+)$/i, (match, extension) => extension.toLowerCase() === '.pdf' ? extension : '.pdf'), { download: true });
        const pdfContent = await s3Out.Body.arrayBuffer(); // Convert the Blob to ArrayBuffer
        const pdfDoc = await PDFDocument.load(pdfContent, { ignoreEncryption: true });
        const numberOfPages = pdfDoc.getPages().length;
        return { numberOfPages };
    } catch (error) {
        console.error("Pdf images only we have page number");
    }
}

export async function getPdfImageOfPageNumber(pdfObj) {
    let numberOfPages;
    if (pdfObj.totalPdfPageCount <= process.env.REACT_APP_NUMBER_OF_PAGES) {
        numberOfPages = pdfObj.totalPdfPageCount;
    } else if (pdfObj.totalPdfPageCount > process.env.REACT_APP_NUMBER_OF_PAGES && pdfObj.pageIndex >= process.env.REACT_APP_NUMBER_OF_PAGES) {
        numberOfPages = Math.min(parseInt(pdfObj.totalPdfPageCount) - parseInt(pdfObj.pageIndex) + 1, parseInt(process.env.REACT_APP_NUMBER_OF_PAGES));
    } else {
        numberOfPages = process.env.REACT_APP_NUMBER_OF_PAGES;
    }
    var exists = await doesFileExist(`${pdfObj.documentFullPath.replace(/\.(pdf?|docx?|ppt?|pptx?|doc?)$/i, '_images')}/page${pdfObj.pageIndex}.jpeg`);
    if (exists === false) {
        await generateImageForPdf(pdfObj.documentFullPath, pdfObj.pageIndex, numberOfPages);
    }
    let URLs = [];
    let lastPage = Math.min(parseInt(pdfObj.pageIndex) + parseInt(process.env.REACT_APP_NUMBER_OF_PAGES), (parseInt(pdfObj.totalPdfPageCount) + 1));
    for (let i = pdfObj.pageIndex; i < lastPage; i++) {
        const url = await getSignedUrl(`${pdfObj.documentFullPath.replace(/\.(pdf?|docx?|ppt?|pptx?|doc?)$/i, '_images')}/page${i}.jpeg`, "getObject", `page${i}.jpeg`);
        URLs.push({ [i]: url });
    }
    let resp = URLs;
    return resp;
}

export async function doesFileExist(s3Path) {
    try {
        const params = {
            Bucket: config.aws_user_files_s3_bucket,
            Key: `public/${s3Path}`
        };
        const credentials = await Auth.currentCredentials();
        const s3 = new S3Client({ 
            region: config.aws_project_region,
            credentials: credentials 
        });
        const resp = await s3.send(new HeadObjectCommand(params));
        console.log(`${s3Path} found ${JSON.stringify(resp)}`);
        return true;
    } catch (error) {
        console.log(`${s3Path} NotFound ${JSON.stringify(error)}`);
        return false;
    }
}

export async function generateImageForPdf(documentFullPath, pageIndex) {
    try {
        const backend = config.aws_user_files_s3_bucket.split('-');
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
            access_token: user.signInUserSession.idToken.jwtToken,
            target: "generateImageforPdf",
            documentFullPath: documentFullPath,
            pageIndex: pageIndex,
        };
        const init = {
            body: body,
        };
        const credentials = await Auth.currentCredentials();
        const params = {
            FunctionName: `instanceHandler-${backend[backend.length - 1]}`,
            Payload: JSON.stringify(init),
        };
        const lambda = new LambdaClient({
            credentials: Auth.essentialCredentials(credentials),
            region: config.aws_project_region
        });
        const data = await lambda.send(new InvokeCommand(params));
        if (data !== undefined) {
            return JSON.parse(uint8Decode(data.Payload)).response;
        }
    } catch (err) {
        console.log("err: ", err);
        return false;
    }
}

export const getSignedUrl = async (filePath) => {
    const url = await Storage.get(filePath);
    return url;
}

export async function getFilePath(submissionIdentifier, fileName) {
    try {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
            access_token: user.signInUserSession.idToken.jwtToken,
            target: "getFilePath",
            submissionIdentifier: submissionIdentifier,
            fileName: fileName,
        };
        const init = {
            body: body,
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        return data?.response
    } catch (err) {
        console.error(err);
        return false;
    }
}

export async function uploadToS3AndUpdateDocumentTable(payload) {
    try {
        const init = {
            body: payload,
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        return data?.response
    } catch (err) {
        console.log("err: ", err);
        return false;
    }
}

export const invokeInstanceDispatcher = async (document, status, rejectionReasons, uploadDocument, triageLane, declineCode, runValidation) => {
    try {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
            "access_token": user.signInUserSession.idToken.jwtToken,
            "target": "finish",
            "document": document,
            "status": status,
            "rejectionReasons": rejectionReasons,
            "uploadDocument": uploadDocument,
            "triageLane": triageLane,
            "declineCode": declineCode,
            "runValidation": runValidation
        };
        const init = {
            body: body
        };
        const resp = await invokeLambdaWithRetry("instanceHandler", init);
        return resp;
    } catch (err) {
        console.error('err: ', err);
        return false;
    }
}

export async function invokeImageWithBoundingBox(imageDetails, documentObj) {
    try {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
            access_token: user.signInUserSession.idToken.jwtToken,
            target: "readPdfText",
            ...imageDetails,
            document: documentObj,
        };
        const init = {
            body: body,
        };

        const data = await invokeLambdaWithRetry("instanceHandler", init);
        console.log("invokeImageWithBoundingBox data:", data);
        // if (data && data.Payload === false) {
        //     return false;
        // }

        return data === false ? false : data?.response?.body;
    } catch (err) {
        console.log("err: ", err);
        return false;
    } finally {
    }
}

export async function extractFromTesseract(dataUrl, boundingId, submissionId) {
    try {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const uniqueId = user.username + boundingId;
        const documentPath = submissionId + "/" + uniqueId + ".jpeg";
        const file = Buffer.from(dataUrl.split(',')[1], 'base64');
        await Storage.put(documentPath, file, {
            contentType: 'image/jpeg', // Set the content type based on your image format
        });
        const body = {
            access_token: user.signInUserSession.idToken.jwtToken,
            target: "extractFromTesseract",
            documentPath
        };
        const init = {
            body: body,
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        console.log("extractFromTesseract resp:", data);
        // if (data && data.Payload === false) {
        //     return false;
        // }
        return data === false ? false : data?.response;
    } catch (err) {
        console.log("err: ", err);
        return false;
    } finally {
    }
}

export async function getDataFromS3Folder(path) {
    try {
        const credentials = await Auth.currentCredentials();
        const s3 = new S3Client({ 
            region: config.aws_project_region,
            credentials: credentials
        });
        const params = { Bucket: config.aws_user_files_s3_bucket, Prefix: path };
        const resp = await s3.send(new ListObjectsV2Command(params));
        return resp;
    } catch (err) {
        console.log("err: ", err);
        return false;
    }
}

export async function getParameterData(paramsName) {
    return await getInstanceParameterFromManager(paramsName);
}

//Send QA Data starts
export async function loadScratchPad(jsonPath) {
    try {
        const jsonS3DataRes = await Storage.get(jsonPath, { download: true });
        return jsonS3DataRes.Body.text().then(json_data => {
            var data = JSON.parse(json_data);
            return data;
        });
    } catch {
        return false;
    }
}

export async function saveQAFile(fullPath, xlData, sheetNames) {
    try {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
            "access_token": user.signInUserSession.idToken.jwtToken,
            "fullPath": fullPath,
            "xlData": xlData,
            "option": "qa_process",
            "sheetNames": sheetNames
        };
        const init = {
            body: JSON.stringify(body)
        };
        const sResp = await invokeLambdaWithRetry("extractDocument", init);
        console.log(`extractDocument sync response : ${typeof (sResp) === 'object' ? JSON.stringify(sResp) : sResp}`);
    } catch (err) {
        console.error('err: ', err);
        return false;
    }
    return true;
}

export async function getExtractPath(documentFullPath) {
    var docFPs = documentFullPath.split('/');
    docFPs.pop();
    return docFPs.join('/');
}

export async function replaceLastOfFile(fname, last, extension) {
    var fparts = fname.split(".");
    fparts[fparts.length - 2] += last;
    fparts[fparts.length - 1] = extension;
    return fparts.join(".");
}

function doFillers(pageData) {
    for (var page = 0; page < pageData.length; page++) {
        for (var row = 0; row < pageData[page].length; row++) {
            for (var col = 0; col < pageData[page][row].length; col++) {
                if (pageData[page][row][col] === null || pageData[page][row][col] === undefined) {
                    pageData[page][row][col] = { "value": "" };
                }
            }
        }
    }
    return pageData;
}

export async function compareQAFeedback(ext, qa) {
    var result = { success: true, added: 0, deleted: 0, modified: 0, diffpack: JSON.parse(JSON.stringify(ext)) };
    if (ext.length === qa.length) {
        for (var page = 0; page < ext.length; page++) {
            var colOffset = 0;
            for (var m = 0; m < ext[page][0].length; m++) {
                if (ext[page][0][m].value.toLowerCase().trim().startsWith("output ") === true || ext[page][0][m].value.trim().toLowerCase() === "entity name") {
                    result.diffpack[page][0].splice(m + colOffset + 1, 0, { "value": ext[page][0][m].value.trim().toLowerCase() === "entity name" ? "QA Verify Status" : `Modified ${ext[page][0][m].value}` });
                    for (var ir = 1; ir < ext[page].length; ir++) {
                        result.diffpack[page][ir].splice(m + colOffset + 1, 0, { "value": "" });
                    }
                    colOffset++;
                }
            }

            if (JSON.stringify(ext[page][0]) !== JSON.stringify(qa[page][0]) || JSON.stringify(ext[page][0]) === JSON.stringify(qa[page][0])) {
                for (var row = 1; row < ext[page].length; row++) {
                    let getEntityNameIndex = ext[page][0].findIndex(item => item.value.toLowerCase() === "entity name");
                    if (ext[page][row][getEntityNameIndex]?.value.trim().length !== 0) {
                        const entityNameToFind = ext[page][row][getEntityNameIndex]?.value;
                        let getQAEntityNameIndex = qa[page][0].findIndex(item => item.value.toLowerCase() === "entity name");
                        var colRhs = qa[page].filter(obj => {
                            return obj[getQAEntityNameIndex]?.value === entityNameToFind;
                        });
                        if (colRhs.length === 0) {
                            // if in rhs key not found: removed(deleted)
                            console.log(`row for entity ${entityNameToFind} in target not found, so deleted`);
                            result.deleted++;
                        } else if (colRhs.length === 1) {
                            if (JSON.stringify(ext[page][row]) !== JSON.stringify(colRhs[0])) {
                                // if in rhs key found new value : change or value emptied(deleted) or value filled(added)
                                colOffset = 2;
                                for (var col = 1; col < ext[page][row].length; col++) {
                                    if (ext[page][0][col].value.toLowerCase().startsWith("output ") === true) {
                                        let getColumnName = ext[page][0][col].value.toLowerCase().startsWith("output ") && ext[page][0][col].value;
                                        let getColumnNameIndex = qa[page][0].findIndex(item => item.value.toLowerCase() === getColumnName.toLowerCase())
                                        if (ext[page][row][col].value !== qa[page][row][getColumnNameIndex].value) {
                                            console.log(`${ext[page][row][col].value} : ${qa[page][row][getColumnNameIndex].value}`);
                                            if (ext[page][row][col].value.length === 0) {
                                                console.log(`field added ${col}`);
                                                result.diffpack[page][row][col + colOffset].value = qa[page][row][getColumnNameIndex].value;
                                                result.added++;
                                            } else if (qa[page][row][getColumnNameIndex].value.length === 0) {
                                                result.deleted++;
                                            } else {
                                                console.log(`field changed ${col}`);
                                                result.diffpack[page][row][col + colOffset].value = qa[page][row][getColumnNameIndex].value;
                                                result.modified++;
                                            }
                                        }
                                        colOffset++;
                                    }
                                }
                            } else {
                                // if in rhs key found same value : no change
                            }
                        }
                        // else {//more rows matched. which one
                        //     console.log(`duplicate matching rows for entity ${entityNameToFind} in target`);
                        //     return { success: false };
                        // }
                    }
                    for (var rhrow = 1; rhrow < qa[page].length; rhrow++) {
                        let getQAEntityNameValueIndex = qa[page][0].findIndex(item => item.value.toLowerCase() === "entity name");
                        if (qa[page][rhrow][getQAEntityNameValueIndex].value.trim().length !== 0) {
                            const entityNameToFind = qa[page][rhrow][getQAEntityNameValueIndex].value;
                            var colLhs = ext[page].filter(obj => {
                                return obj[getQAEntityNameValueIndex].value === entityNameToFind;
                            });
                            if (colLhs.length === 0) {
                                // if in lhs key not found: added
                                console.log(`row for entity ${entityNameToFind} in target not found, so added`);
                                result.added++;
                            }
                        }
                    }
                }
            } else {
                console.log("file target is not matching");
                return { success: false };
            }
        }
    } else {
        console.log("file target is not matching");
        return { success: false };
    }
    return result;
}

export async function savedQA(document, xlData, scratchPad, clientSubNumber, sheetNames) {
    var pathForExtracts = await getExtractPath(document.documentFullPath);
    var fileName = `${await replaceLastOfFile(document.uploadDocumentName, "_qa", "xlsx")}`;
    var uuidName = uuid();
    var fullPath = `${pathForExtracts}/${uuidName}${fileName}`;
    var jsonFile = `${pathForExtracts}/${uuidName}${await replaceLastOfFile(document.uploadDocumentName, "", "json")}`;
    var jsonScratchPadFile = `${pathForExtracts}/${uuidName}${await replaceLastOfFile(document.uploadDocumentName, "_scratchpad", "json")}`;
    var jsonDiffFile = `${pathForExtracts}/${uuidName}${await replaceLastOfFile(document.uploadDocumentName, "_diff", "json")}`;

    var resp = await saveQAFile(fullPath, xlData, sheetNames);
    console.log(`saveQAFile : ${JSON.stringify(resp)}`);

    //Update QA Columns in DB 
    var today = new Date();
    var nDate = today.toJSON().slice(0, 10);//YYYY-MM-DD
    //Put in S3
    var jsonD = JSON.stringify(xlData);
    var data = JSON.parse(jsonD);
    data = doFillers(data);
    await Storage.put(jsonFile, Buffer.from(JSON.stringify(data), 'utf8'));
    //save scratchPad
    await Storage.put(jsonScratchPadFile, Buffer.from(JSON.stringify(scratchPad), 'utf8'));
    //and then update db
    const updDocument = {
        document_id: document.document_id,
        processingStatus: "QA Uploaded",
        qaFileName: fileName.replace(/'/g, "''").replace(/"/g, "\\\""),
        qaFileFullPath: fullPath.replace(/'/g, "''").replace(/"/g, "\\\""),
        jsonExtractpath: jsonFile.replace(/'/g, "''").replace(/"/g, "\\\""),
        qaFileProcessedDate: nDate,
        clientQASubmissionNum: clientSubNumber,
        NumFieldsQAModified: scratchPad.editedRows?.length
    };
    const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    var body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "updateDocument",
        "updateDocumentsInput": updDocument
    };
    var init = {
        body: body
    };
    let res = await invokeLambdaWithRetry("instanceHandler", init);
    console.log("savedQA updateDocument resp:", res);
    var updSubmissionReport = {
        submissionIdentifier: document.submissionIdentifier,
        document_id: document.document_id,
        QASaveDateTime: "NOW",
    };

    if (document.jsonMarkCompletePath !== null) {
        var origData = await loadS3File(document.jsonMarkCompletePath);
        var compRes = await compareQAFeedback(origData, data);
        console.log(compRes);
        if (compRes.success !== false && compRes.diffpack !== undefined) {
            await Storage.put(jsonDiffFile, Buffer.from(JSON.stringify(compRes.diffpack), 'utf8'));
            updSubmissionReport.NumFieldsQAModified = compRes.modified;
            updSubmissionReport.NumFieldsQAAdded = compRes.added;
            updSubmissionReport.NumFieldsQADeleted = compRes.deleted;
            updSubmissionReport.ExtractionQADifferencesJsonPath = jsonDiffFile;
        }
    }
    body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "updateSubmissionsReport",
        "submissionsReportInput": updSubmissionReport
    };
    init = {
        body: body
    };
    
    res = await invokeLambdaWithRetry("instanceHandler", init);
    console.log("savedQA updateSubmissionsReport resp:", res);
    notifyUser({ "username": document.submittedby, "processorname": user.attributes.email, "fileName": document.documentName, "option": "qaUploaded", "submissionIdentifier": document.submissionIdentifier });
    logAudit(document.document_id, document.submissionIdentifier, "QA", "QA Uploaded", "UI", undefined, undefined).then((data) => { });
    logAudit(document.document_id, document.submissionIdentifier, "QA", "Save", "UI", undefined, undefined).then((data) => { });
}

export async function notifyClient(subid, docname, clientSubNumber, document_id, xlType, templateName) {
    try {
        let resMsg = "";
        const res = await fetchMsg(subid);
        const body = {
            data: {
                "submissionid": subid,
                "documentname": docname,
                "submission_number": clientSubNumber.trim(),
                "templateName": templateName,// templateName associated with this merged file
                "originalMsgFileName": xlType?.toLowerCase()?.trim() === "generic" ? (res.data.listDocumentsAnyWithMsg.length === 1 ? res.data.listDocumentsAnyWithMsg[0].documentName : undefined) : docname,// - Include the original .msg or .eml file associated with this submission.
                "originalInputFileName": xlType?.toLowerCase()?.trim() === "generic" ? (res.data.listDocumentsAnyWithMsg.length === 1 ? res.data.listDocumentsAnyWithMsg[0].documentName : undefined) : docname,
                "document_id": document_id
            },
            "option": "notifyclient"
        };
        const init = {
            body: JSON.stringify(body)
        };
        let result = await invokeLambdaWithRetry("notifyUserDocStage", init);
        if (result.error) {
            resMsg = "Send QA Data Config not set correctly. Contact mea support team for more details";
        } else {
            resMsg = "Successfully sent the notification for the QA data";
        }
        return resMsg;
    } catch (err) {
        console.error('err: ', err);
    }
}

export async function handleUpdateSubmissionReport(updSubmissionReport) {
    const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "updateSubmissionsReport",
        "submissionsReportInput": updSubmissionReport
    };
    const init = {
        body: body
    };
    const data = await invokeLambdaWithRetry("instanceHandler", init);
    console.log("handleUpdateSubmissionReport resp:", data);
}
//Send QA Data ends

export async function fetchTemplateList(item, maxTemplateCount) {
    try {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
            "access_token": user.signInUserSession.idToken.jwtToken,
            "count": -1,
            "rowStart": 0,
            "pageSize": maxTemplateCount,
            "searchTemplate": "",
            "mode": "like",
            "target": "fetchTemplateDetails",
            "document": item
        };
        const init = {
            body: body,
        };
        // if (data !== undefined) {
        //     return JSON.parse(uint8Decode(data.Payload)).response;
        // }
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        console.log("fetchTemplateList resp:", data);
        return data?.response;
    } catch (err) {
        console.log("err: ", err);
        return false;
    }
}

export async function updateTransformationJSONData(documentDetails, extractValue, updatedJson, updatedSheetData = {}, removeSheetData, reset) {
    let documentsMeta = {};
    let jsonFilePath = `${documentDetails?.documentFullPath.split('/')[0]}/DocumentsMeta/${documentDetails.document_id}.json`;
    let jsonData = await fetchJSONFromS3(jsonFilePath);
    documentsMeta = jsonData || {};

    let sheetData = {};
    if (reset?.resetSheet === true) {
        if (documentsMeta?.sheetData?.length > 0) {
            let currentSheetData = documentsMeta.sheetData.filter(i => i.inputSheetName !== reset?.inputSheetName);
            sheetData = { sheetData: currentSheetData};
        }
    } else if (Object.keys(updatedSheetData).length > 0) {
        if (documentsMeta?.sheetData?.length > 0) {
            let currentSheetData = documentsMeta.sheetData.filter(i => i.inputSheetName === updatedSheetData?.inputSheetName);
            if (currentSheetData.length > 0) {
                sheetData = { sheetData: documentsMeta.sheetData.map(i => (((i.inputSheetName === updatedSheetData?.inputSheetName) ? (removeSheetData ? { inputSheetName: i.inputSheetName, inputHeaderRowNumber: updatedSheetData.inputHeaderRowNumber, childInputHeaderRowNumber: updatedSheetData.childInputHeaderRowNumber } : { ...i, ...updatedSheetData }) : i))) };
            } else {
                sheetData = { sheetData: [...documentsMeta.sheetData, updatedSheetData] };
            }
        } else {
            sheetData = { sheetData: [updatedSheetData] };
        }
    }

    documentsMeta = {
        ...documentsMeta,
        ...updatedJson,
        ...sheetData
    };
    // to update/create json file
    let resp = await updateToS3(jsonFilePath, documentsMeta);
    return resp;
}

export async function fetchTransformationJSONData(documentDetails, extractValue) {
    let jsonFilePath = `${documentDetails?.documentFullPath.split('/')[0]}/DocumentsMeta/${documentDetails?.document_id}.json`;
    let jsonData = await fetchJSONFromS3(jsonFilePath);
    return jsonData;
}

export async function fetchExcelSheetData() {
    return false;
}

export async function downloadExtractedFile() {
    return false;
}

const removeWordAFterLastHypen = (sheetName) => {
    let lastHyphenIndex = sheetName.lastIndexOf('-');
    let result;
    if (lastHyphenIndex !== -1) {
        result = sheetName.trim().substring(0, lastHyphenIndex).trim();
    } else {
        result = sheetName.trim();
    }
    return result;
}

export async function downloadOutputFile(allSheetDetails, documentFileName) {
    const newWorkbook = XLSX.utils.book_new();
    for (let i = 0; i < allSheetDetails.length; i++) {
        let sheetDetail = allSheetDetails[i];
        const arrayBuffer = await fetchExcelFromS3(sheetDetail.docPath);
        if (arrayBuffer) {
            const workbook = XLSX.read(arrayBuffer, { type: 'array', cellNF: true });
            let matchedSheetIndex = workbook.SheetNames.findIndex(j => removeWordAFterLastHypen(j).toLowerCase() === sheetDetail.inputSheetName.toLowerCase());
            let sheetName = matchedSheetIndex !== -1 ? workbook.SheetNames[matchedSheetIndex] : workbook.SheetNames[0];
            let originalSheet = workbook.Sheets[sheetName];

            if (sheetName === "Combined Sheet") {
                XLSX.utils.book_append_sheet(newWorkbook, originalSheet, "Combined Sheet");
            } else {
                XLSX.utils.book_append_sheet(newWorkbook, originalSheet, sheetName);
            }
        }
    }
    if (documentFileName.endsWith('.xls')) {
        let docname = documentFileName.replace(/(\.[^.]+)$/, '-Transformed$1.xlsx');
        documentFileName = docname.replace(".xls", "");
    } else {
        documentFileName = documentFileName.replace(/(\.[^.]+)$/, '-Transformed$1');
    }
    XLSX.writeFile(newWorkbook, documentFileName);
}

export async function loadMapper(inputHeaderRowNum, childInputHeaderRowNum, storeSheetName, document, documentObj) {
    try {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
            "target": "fetch-mapper-metadata",
            "document": document,
            "control": {
                headerRowIndex: inputHeaderRowNum,
                inputSheetName: storeSheetName,
                childHeaderRowIndex : childInputHeaderRowNum && childInputHeaderRowNum !== inputHeaderRowNum ? parseInt(childInputHeaderRowNum) : undefined
            },
            "access_token": user.signInUserSession.idToken.jwtToken,
        };
        const init = {
            body: body
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        console.log("loadMapper resp:", data);
        return data?.response;
    } catch (err) {
        console.error('err: ', err);
        return false;
    }
}

export async function fetchMappedData(updatedKeyValues, childInputHeaderRowNum, inputHeaderRowNum, storeSheetName, document) {
    try {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        let body = {
            "target": "fetch-mapped-data",
            "document": document,
            "control": {
                headerRowIndex: parseInt(inputHeaderRowNum),
                inputSheetName: storeSheetName,
                totalConfidenceColumns: 0,
                childHeaderRowIndex : childInputHeaderRowNum && childInputHeaderRowNum !== inputHeaderRowNum ? parseInt(childInputHeaderRowNum) : undefined
            },
            "access_token": user.signInUserSession.idToken.jwtToken,
        };
        if (updatedKeyValues !== undefined) {
            body.control.keyValues = updatedKeyValues;
        }
        const init = {
            body: body
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        const response = data?.response;
        if(response) {
            let jsonData = await fetchJSONFromS3(response.jsonFilePath);
            return jsonData;
        } else {
            return false;
        }
    } catch (err) {
        console.error('err: ', err);
        return false;
    }
}

export async function fetchTransformedJSON(document, storeSheetName) {
    try {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
            "target": "fetch-transformed-data",
            "document": document,
            "access_token": user.signInUserSession.idToken.jwtToken,
            "sheetName": storeSheetName
        };
        const init = {
            body: body
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        const response = data?.response;
        if (response) {
            let jsonData = await fetchJSONFromS3(response?.transformedJsonFilePath);
            return jsonData;
        } else {
            return false;
        }
    } catch (err) {
        console.error('err: ', err);
        return false;
    }
}

export async function uploadFileToSharePoint() {
    return false;
}

export async function generateOutputArrayBuffer() {
    return false;
}

export async function uploadAllTransformedSheetsToS3() {
    return false;
}

export async function fetchMergedHeaders(parentHeaderRowIdx,childHeaderRowIdx,sheetName, document, documentObj) {
    try {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
            "target": "fetch-merged-headers",
            "document": document,
            "filePath": document.documentFullPath,
            "control" : {
                filePath : document.documentFullPath,
                sheetName,
                parentHeaderRowIdx,
                childHeaderRowIdx
            },
            "access_token": user.signInUserSession.idToken.jwtToken,
        };
        const init = {
            body: body
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        let response = data?.response;
        if (response) {
            const jsonData = await fetchJSONFromS3(response.inputSheetJSONFilePath);
            return jsonData;
        } else {
            return false;
        }
    } catch (err) {
        console.error('err: ', err);
        return false;
    }
}
export async function invokeOcrService() {
    return false;
}

export async function fetchAllSheetsTransformedJSON(document) {
    try {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
            "target": "fetch-allsheets-transformed-json",
            "document": document,
            "access_token": user.signInUserSession.idToken.jwtToken,
        };
        const init = {
            body: body
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        const response = data?.response;
        if (response) {
            let jsonData = await fetchJSONFromS3(response.transformedJsonFilePath);
            return jsonData;
        } else {
            return false;
        }
    } catch (err) {
        console.error('err: ', err);
        return false;
    }
}

export async function fetchCurrencyData(instanceDetails) {
    return false;
}

export function combineSheetsData(instanceDetails) {
    return false;
}

export function getTextFromImageUsingTrcOcr(instanceDetails) {
    return false;
}

export async function generateArrayBuffer() {
    return false;
}

export async function generateMarkCompleteOutputArrayBuffer() {
    return false;
}
