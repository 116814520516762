import React from "react";
import { useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import {
  Modal,
  Button
} from "react-bootstrap";

import { isEmailChar, validateEmail } from '../services/Helper';
import logo from '../assets/images/logo.png';
import "../assets/css/style.css";
import "../assets/vendors/ti-icons/css/themify-icons.css";
import "../assets/vendors/base/vendor.bundle.base.css";

function PasswordReset() {
  const [showModal, setShowModal] = React.useState(false);
  const [showModalMsg, setShowModalMsg] = React.useState("");
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [authenticationCode, setAuthenticationCode] = useState("");
  let [step, setStep] = useState(0);
  const history = useHistory();

  function convertToUserName(email) {
    return email;
  }

  
  const allowEmailChars = e => {
    if (isEmailChar(e.key) === false) {
      e.stopPropagation();
      e.preventDefault();
    }
  }
  
  let forgotPassword = async (event) => {
    event.preventDefault();
    var username = convertToUserName(email);
    if (username.trim().length === 0)
      return;
    if(validateEmail(email) !== true)
      return;
    try {
      await Auth.forgotPassword(username)
        .then((data) => {
          setStep(1);
        })
        .catch((err) => {
          setShowModalMsg(err.message);
          setShowModal(true);
          return;
        });
    } catch (err) {
      console.log('error reset :', err);
    }
  }

  let forgotPasswordSubmit = async (event) => {
    event.preventDefault();
    var username = convertToUserName(email);
    if (authenticationCode.trim().length === 0)
      return;
    try {
      await Auth.forgotPasswordSubmit(username, authenticationCode, password)
        .then(async (data) => {
            let path = `/login`;
            history.push(path);
          }
        )
        .catch((err) => {
          setShowModalMsg(err.message);
          setShowModal(true);
          return;
        });
    } catch (err) {
      setShowModalMsg(err.message);
      setShowModal(true);
      console.error(err);
    }
  }

  const redirectToLogin = () => {
    let path = `/login`;
    history.push(path);
  }

  const handleAuthCode = (event) => {
    if (event.target.checkValidity()) {
      setAuthenticationCode(event.target.value);
    }
  };

  return (
    <>
    <form autoComplete="blahblah">
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper auth-pages d-flex align-items-stretch auth">
            <div className="row flex-grow">

              <div className="col-lg-6 d-flex align-items-center justify-content-center">
                <div className="auth-form-transparent text-left p-3 login-form">
                  <h3>forgot password</h3>
                  {step === 0 && (<form className="pt-3">
                    <div className="form-group">
                      <div className="input-group">
                        <input type="email" className="form-control border-left-0" placeholder="enter valid email id" value={email} onKeyDown={allowEmailChars} onChange={(event) => { setEmail(event.target.value); }} />
                      </div>
                    </div>
                    <div className="my-3 row mx-0 justify-content-center">
                      <button type="button" className="btn btn-primary mr-2 auth-form-btn" disabled={!/^[^\s@]+@[^\s@]+$/.test(email)} onClick={forgotPassword}>submit</button>
                      <button type="button" className="btn btn-primary" onClick={redirectToLogin}>cancel</button>
                    </div>
                  </form>)}

                  {step === 1 && (<form className="pt-3">
                    <div className="form-group">
                      <div className="input-group">
                        <input type="email" className="form-control border-left-0" placeholder="enter valid email id" readOnly={true} value={email} onChange={(event) => { setEmail(event.target.value); }} />
                      </div>
                    </div>
                    
                    <div className="form-group">
                      <div className="input-group">
                          <input id="authenticationCode" name="authenticationCode" type="text" className="form-control border-left-0" value={authenticationCode} placeholder="authentication code" pattern="[0-9]*" onChange={handleAuthCode} />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <input id="resetpassword" name="resetpassword" autoComplete="new-password" type="password" className="form-control border-left-0" value={password} placeholder="password" onChange={(event) => { setPassword(event.target.value); }} />
                      </div>
                    </div>
                    <div className="my-3 row mx-0 justify-content-center">
                      <button type="button" className="btn btn-primary mr-2 auth-form-btn" disabled={authenticationCode.length < 6 || password.length === 0} onClick={forgotPasswordSubmit}>change password</button>
                      <button type="button" className="btn btn-primary" onClick={redirectToLogin}>cancel</button>
                    </div>
                  </form>)}
                </div>
              </div>
              <div className="col-lg-6 d-flex align-items-center justify-content-center">
                <div className="brand-logo">
                  <img src={logo} alt="logo" />
                </div>
              </div>                   
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 login-half-bg d-flex flex-row">
              <p className="font-weight-medium text-center flex-grow align-self-end">&copy; mea platform limited {new Date().getFullYear()}.</p>
            </div>
          </div>
        </div>
      </div>
      </form>

      {/* Mini Modal */}
      <Modal
        className="modal-mini modal-primary"
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={false}
      >
        <Modal.Header className="justify-content-center">
          <div className="modal-profile">
            <i className="nc-icon nc-bulb-63"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>{showModalMsg}</p>
        </Modal.Body>
        <div className="modal-footer justify-content-center">
          <Button
            className="btn-simple"
            type="button"
            variant="link"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
      {/* End Modal */}
    </>
  );
}

export default PasswordReset;
