import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import PopUpModal from "components/PopUpModal";
import { Storage } from 'aws-amplify';
import { getParameterFromManager } from "services/Utils";
import { logAudit } from 'services/Helper';
import CirclesLoader from "../../layouts/CirclesLoader";

function UploadFileManagement() {
  const [modalContent, setModalContent] = useState({
    show: false,
    title: "",
    body: "",
    primaryAction: {},
    secondaryAction: null,
  });
  const [loading, setLoading] = useState(false);
  const [copyFileS3Features, setCopyFileS3Features] = useState([]);
  const [featureName, setFeatureName] = useState("");
  const [filePath, setFilePath] = useState(null);
  const [featureFile, setFeatureFile] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    async function getCopyFileS3Features() {
      const featureList = await getParameterFromManager("COPY_FILE_S3");
      setCopyFileS3Features(featureList || []);
    }
    if (copyFileS3Features.length === 0) {
      getCopyFileS3Features();
    }
  }, [copyFileS3Features]);

  const resetStates = () => {
    setModalContent({
      show: false,
      title: "",
      body: "",
      primaryAction: {},
      secondaryAction: null,
    });
    setFeatureFile(null);
    setFeatureName("");
    setFilePath(null);
    setLoading(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset file input value
    }
  }

  const handleFileChange = (event) => {
    if (!event.target.files[0]) {
      setFeatureFile(null);
      return
    };

    const file = event.target.files[0];
    if (file && file.name === `${featureName}.json`) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          JSON.parse(e.target.result);
          setFeatureFile(file);
        } catch (error) {
          setModalContent({
            show: true,
            title: "Error",
            body: "Invalid JSON format. Please correct the file and upload it again.",
            primaryAction: { 
              text: "OK",
              onClick: () => {
                setFeatureFile(null)
              },
            },
          });
          event.target.value = null; // Clear the input
        }
      };
      reader.readAsText(file);
    } else {
      setModalContent({
        show: true,
        title: "Error",
        body: `Invalid file selected. Please select the file named ${featureName}.json.`,
        primaryAction: {
          text: "OK",
          onClick: () => {
            setFeatureFile(null)
          },
        },
      });
      event.target.value = null; // Clear the input
    }
  };

  const handleUpload = async () => {
    try {
      setLoading(true);
      const files = await Storage.list(`${filePath}${featureName}`);
      const exists = files?.results.filter((file) => file.key === `${filePath}${featureName}.json` ? true : false);

      if (exists) {
        console.log(`file is already exists, creating a backup file with ${filePath}${featureName}_v1.json and uploading new file.`);
        await Storage.copy({key: `${filePath}${featureName}.json`}, {key: `${filePath}${featureName}_v1.json`});
        await logAudit(undefined, undefined, "File Upload Management", "Create backup file", "UI", featureName, filePath);
        await Storage.put(`${filePath}${featureName}.json`, featureFile);
        await logAudit(undefined, undefined, "File Upload Management", "Updated", "UI", featureName, filePath);
        setLoading(false);
        setModalContent({
          show: true,
          title: "Success",
          body: `File ${featureName}.json is successfully uploaded on S3.`,
          primaryAction: { 
            text: "OK", 
            onClick: resetStates,
          },
        });
      } else {
        await Storage.put(`${filePath}${featureName}.json`, featureFile);
        await logAudit(undefined, undefined, "File Upload Management", "Created", "UI", featureName, filePath);
        setLoading(false);
        setModalContent({
          show: true,
          title: "Success",
          body: `File ${featureName}.json is successfully uploaded on S3.`,
          primaryAction: { 
            text: "OK", 
            onClick: resetStates,
          },
        });
      }
      setLoading(false);
    } catch (error) {
      setModalContent({
        show: true,
        title: "Error",
        body: `${error}`,
        primaryAction: { 
          text: "OK", 
          onClick: resetStates,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFeatureSelect = (event) => {
    const selectedFeatureName = event.target.value;
    setFeatureName(selectedFeatureName);
    const selectedFeature = copyFileS3Features.find((feature) => feature.name === selectedFeatureName);
    setFilePath(selectedFeature?.s3Path);
    setFeatureFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset file input value
    }
  }

  return (
    <>
      {/* Loader Modal */}
      <Modal
        id="loaderModal"
        className="modal-centre"
        backdrop="static"
        keyboard={false}
        show={loading}
        animation={false}
        centered
      >
        <Modal.Body className="text-center">
          <CirclesLoader isLoading={loading} />
        </Modal.Body>
      </Modal>
      {/* End Loader Modal */}
      <div className="row">
        <div className="col-lg-9 col-md-9 col-sm-9 col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">File Upload Management</h4>
              <div className="row mb-3">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                  <select className="form-control form-control-md" id="featureNameDropDown" value={featureName} onChange={handleFeatureSelect}>
                    <option value="">Select a feature</option>
                    {copyFileS3Features.map((feature) => (
                      <option key={feature.name} value={feature.name}>{feature.name}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                  <Form.Control
                    placeholder="Browse feature file"
                    type="file"
                    id="featureFileInput"
                    accept=".json"
                    multiple={false}
                    disabled={!featureName}
                    size="md"
                    className="feature-file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="mt-2 pl-3 pr-3">
                  <Button
                    type="button"
                    id="featureUploadBtn"
                    className="btn btn-primary btn-minwidth float-right"
                    disabled={!featureName || !featureFile}
                    onClick={handleUpload}
                  >
                    Upload
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopUpModal
        show={modalContent.show}
        handleClose={resetStates}
        title={modalContent.title}
        body={modalContent.body}
        primaryAction={modalContent.primaryAction}
        secondaryAction={modalContent.secondaryAction}
      />
    </>
  );
}

export default UploadFileManagement;
