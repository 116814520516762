import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
// react-bootstrap components
import {
  ListGroup,
  Tabs,
  Tab,
  Form,
  Modal,
  Card,
  Container,
  Row,
  Col
} from "react-bootstrap";
import { Auth, Storage } from 'aws-amplify';
import { v4 as uuid } from "uuid";
import { groupBy as rowGrouper } from 'lodash';
import DataGrid from 'react-data-grid';
import { useLocation, useHistory } from "react-router-dom";
import MultiSelect from "react-multiple-select-dropdown-lite";
import { useTour } from '@reactour/tour';
import moment from "moment/moment";
import 'moment-timezone';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import config from '../aws-exports';
import steps from "./../steps";
import { logAudit, signOutUser, downloadBlob, fetchMsg, fetchDocument, renderReactFragment, processUpload, isTemplateAllowed, cptood, extractDocument, notifyUser, checkUploadStatus, downloadExcel } from '../services/Helper';
import { doesFileExist, fetchJsonData } from '../components/Utils/ExtractUtils';
import { getParameterFromManager } from "../services/Utils";
import { getMergedMeaValidationCheckRules, invokeLambdaWithRetry } from "../services/Helper";
import CirclesLoader from "../layouts/CirclesLoader";

//EXTRACTTOOL MARKER IMPORT START

//any imports exclusively used by extraction tool placed here
// import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { getSpFolderPath, getDocDetails, fetchSubmissionDocumentlist, fetchTransformationJSONData } from "../components/Utils/ExtractUtils";
import { BiSearch } from "react-icons/bi";
import { TiTick } from "react-icons/ti";

//EXTRACTTOOL MARKER IMPORT END

const QATool = lazy(() => import('components/Utils/QATool'));
const ReactPayPal = lazy(() => import('components/PayPal/ReactPayPal'));
const DiffViewer = lazy(() => import('components/Utils/DiffViewer'));
const ExtractTool = lazy(() => import('components/Utils/ExtractTool'));

var allowedExtensions = ".pdf,.odt,.doc,.docx,.rtf,.ppt,.pptx,.xls,.xlsx,.msg,.eml";
var allowedSimpleExtensions = ".pdf,.odt,.doc,.docx,.rtf,.ppt,.pptx,.xls,.xlsx";

const initialFormState = { documentName: '', documentFullPath: '', uploadDate: '', processingStatus: 'Uploaded', submittedby: '', uploadDocumentName: '', uploadDocumentFullPath: '', file: null, files: null, target: null }

function SubmissionsList({ submissionModal, setSubmissionModal }) {

  const { setSteps, isOpen, setIsOpen, setCurrentStep, currentStep, onClickClose } = useTour();
  let [declineCode, setDeclineCode] = React.useState([]);
  let [declineCodeOptions, setDeclineCodeOptions] = React.useState([]);
  let [enableTriage, setEnableTriage] = React.useState(false);
  const [documents, setDocuments] = useState([]);
  const [formData, setFormData] = useState(initialFormState);
  const [showModal, setShowModal] = React.useState(false);
  const [showModalYesNo, setShowModalYesNo] = React.useState(false);
  const [showModalCompleteOrReject, setShowModalCompleteOrReject] = React.useState(false);
  const [showManualMerge, setShowManualMerge] = React.useState(false);
  const [showModalSelectTemplate, setShowModalSelectTemplate] = React.useState(false);
  const [rejectionReasons, setRejectionReasons] = React.useState("");
  const [showModalList, setShowModalList] = React.useState(false);
  const [runValidations, setRunValidations] = React.useState(true);
  const [enableUserDataValidations, setEnableUserDataValidations] = React.useState(false);
  const [showModalListMsg, setShowModalListMsg] = React.useState([]);
  const [showModalMsg, setShowModalMsg] = React.useState("");
  const [showModalYesNoMsg, setShowModalYesNoMsg] = React.useState("");
  const [evtShowModalYesNo, setEvtShowModalYesNo] = React.useState();
  const [documentObj, setDocumentObj] = React.useState({ document: { documentName: "" } });
  const [checkout, setCheckout] = React.useState(false);
  const [submissionIdentifier, setSubmissionIdentifier] = React.useState("");
  const [disableUpload, setDisableUpload] = React.useState(false); // by default disableUpload is set to true, only when set to true users will not be able to upload file
  const [expandedGroupIds, setExpandedGroupIds] = React.useState();
  const [paid, setPaid] = React.useState(false);
  const [launchQATool, setLaunchQATool] = useState(false);
  const [launchDiffViewer, setLaunchDiffViewer] = useState(false);
  const [enableCCValue, setEnableCCValue] = useState(false);
  let [submissionCount, setSubmissionCount] = useState(-1);
  let [currentPage, setCurrentPage] = useState(0);
  const pageInputRef = useRef(null);// create a ref for the pagination text field
  let [loading, setLoading] = useState(false);
  let [userRole, setUserRole] = React.useState("MEA_USERS");
  const [searchText, setSearchText] = React.useState("");
  const [searchType, setSearchType] = React.useState("SubmissionID");
  let [procStatus, setProcStatus] = React.useState("All");
  let [lobCode, setLOBCode] = React.useState("All");
  let [lobCodeDisplay, setLOBCodeDisplay] = React.useState("All");
  let [lobCodeOptions, setLOBCodeOptions] = React.useState([]);
  let [searchMode, setSearchMode] = React.useState("Full");
  const [enableTemplate, setEnableTemplate] = React.useState(false);
  const [templateName, setTemplateName] = React.useState("");
  const [reprocTemplateName, setReprocTemplateName] = React.useState("");
  const [usedTemplates, setUsedTemplates] = React.useState([]);
  const [templateOptions, setTemplateOptions] = React.useState([]);
  const [lobCodeValidationEnabled, setLobCodeValidationEnabled] = React.useState(false);
  let [currentData, setCurrentData] = React.useState([]);
  const [showTagWindow, setShowTagWindow] = React.useState(false);
  var [paymentEnabled, setPaymentEnabled] = React.useState(false);
  var [mcUploadExt, setMCUploadExt] = React.useState(".xls,.xlsx");
  const [tagInput, setTagInput] = React.useState();
  const [tagKey, setTagKey] = React.useState("");
  const [tagValue, setTagValue] = React.useState("");
  const [triageLane, setTriageLane] = React.useState("Green");
  const [tagsUpdated, setTagsUpdated] = React.useState(false);
  const [QAStatusOptions, setQAStatusOptions] = React.useState([]);

  let [countryCode, setCountryCode] = useState("All")
  let [countryCodeDisplay, setCountryCodeDisplay] = useState("All");
  let [showCountryCodes, setShowCountryCodes] = React.useState(false);
  let [assocCtryCodeOptions, setAssocCtryCodeOptions] = React.useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const [dataTour, setDataTour] = useState(false);
  const [rejectionReasonsList, setRejectionReasonsList] = React.useState([])

  const [durationInDays, setDurationInDays] = useState(15);
  const [displaySubHistFromDate, setDisplaySubHistFromDate] = useState(moment().subtract(durationInDays, "days").startOf("day").toDate());
  const [displaySubHistToDate, setDisplaySubHistToDate] = useState(moment().endOf("day").toDate());
  const [subHistFromDate, setSubHistFromDate] = useState('');
  const [subHistToDate, setSubHistToDate] = useState('');
  const isSearchEnabled = !!displaySubHistFromDate;
  const [qaToolDocs, setQaToolDocs] = useState();
  let submissionDocumentDatalist = [];
  let [searchBased, setSearchBased] = React.useState("");
  let [offsetValue, setOffsetValue] = React.useState();
  const [timeZoneOffset, setTimeZoneOffset] = React.useState();
  const [serverTimeZone, setServerTimeZone] = React.useState("");
  const [xlType, setXlType] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [userGroups, setUserGroups] = useState([]);
  //EXTRACTTOOL MARKER STATE START

  const [launchExtractTool, setLaunchExtractTool] = useState(false);
  const [extractValue, setExtractValue] = useState();
  const [templateControllerList, setTemplateControllerList] = useState();
  const [submissionDetail, setSubmissionDetail] = useState();
  const [submissionDocList, setSubmissionDocList] = useState([]);
  const [extractToolClose, setExtractToolClose] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [tabSelected, setTabSelected] = useState("home");
  const [isInUploadedStatus, setIsInUploadedStatus] = useState(false);
  const [tourNewSubmissionVisible, setTourNewSubmissionVisible] = useState(false);

  //for the selected file name
  const [selectedFileName, setSelectedFileName] = useState("");
  //for the showClaimsUI
  const [showClaims, setshowClaims] = useState(false);
  const [tagsList, setTagsList] = React.useState([]);
  const [selectedTagData, setSelectedTagData] = React.useState({});

  //EXTRACTTOOL MARKER STATE END

  const location = useLocation();
  const history = useHistory();
  const pageSize = 25;
  const procStatusOptions = [
    "Uploaded",
    "In Progress",
    "Complete",
    "Rejected",
    "QA Uploaded",
    "QA In Progress",
    "QA Complete"
  ];

  const QAProcStatusOptions = [
    "All",
    "Uploaded",
    "In Progress",
    "Complete",
    "Rejected"
  ];

  useEffect(() => {
    async function getLobAndCcode() {
      const lobCode = await getParameterFromManager("LOB_CODES_VALIDATION");
      const countryCode = await getParameterFromManager("ENABLE_COUNTRY_CODE_VALIDATION");
      setLobCodeValidationEnabled(lobCode);
      setShowCountryCodes(countryCode);
    }
    getLobAndCcode();
  }, [templateOptions]);

  let useNewQAToolOrLegacy = {
    enableQA:false,
    showNewQaTool:false
  };

  async function getShowQAValue() {
    let enableQa = await getParameterFromManager("ENABLE_QA");
    let showNewQaTool = await getParameterFromManager("SHOW_NEW_QA_TOOL");
    useNewQAToolOrLegacy['enableQA'] = enableQa;
    useNewQAToolOrLegacy['showNewQaTool'] = showNewQaTool;
  }

  useEffect(() => {
    const match = isDirectQATool();
    if (!match) {
      getShowQAValue();
    }
  })

  useEffect(() => {
    const match = isDirectQATool();
    if (match) {
      (async function navToQATool() {
        const submissionIdentifier = match[1]; // The first captured group is the identifier
        console.log(`Loading submissionIdentifier: ${submissionIdentifier}`);
        await setupUserRole();
        var newQAToolOrLegacy = await getParameterFromManager("SHOW_NEW_QA_TOOL");
        var msg = await fetchMergeRecord(submissionIdentifier);
        if (msg && msg?.length === 1 && msg[0].jsonMarkCompletePath !== null) {
          var row = msg[0];
          if (newQAToolOrLegacy === true) {
            if (((userRole === 'MEA_CORPORATE') || (userRole === 'MEA_ADMIN')) && (row.processingStatus === "Complete" || row.processingStatus === "QA In Progress" || row.processingStatus === "QA Uploaded" || row.processingStatus === "QA Complete")) {
              invokeNewQATool(row, isArchived);
            } else {
              console.log(`invalid action on submissionIdentifier: ${submissionIdentifier}`);
            }
          } else {
            if (((userRole === 'MEA_CORPORATE') || (userRole === 'MEA_ADMIN')) && (row.processingStatus === "Complete" || row.processingStatus === "QA In Progress" || row.processingStatus === "QA Uploaded" || row.processingStatus === "QA Complete")) {
              invokeQATool(row);
            } else {
              console.log(`invalid action on submissionIdentifier: ${submissionIdentifier}`);
            }
          }
        } else {
          console.log(`no merge record for submissionIdentifier: ${submissionIdentifier}`);
        }
        history.push("/user/submissions/");
      })();
    } else {
      var procStatusExplicit = "";
      if (location.state && location.state.procStatus !== "") {
        setProcStatus(location.state.procStatus);
        setSearchMode("Filtered");
        procStatusExplicit = location.state.procStatus;
        location.state.procStatus = "";
      }
      (async function initF() {
        setLoading(true);
        const timeZoneList = await getParameterFromManager("MEA_TIMEZONE");
        setServerTimeZone(timeZoneList.serverTimeZone);
        setSearchBased(`${timeZoneList.serverTimeZone} - Default`);
        setTimeZoneOffset(timeZoneList);
        checkDayLightMode();

        const pymtEnabled = await getParameterFromManager("ENABLE_PAYMENT");
        setPaymentEnabled(pymtEnabled);
        
        const enableCCVal = await getParameterFromManager("ENABLE_COUNTRY_CODE_VALIDATION");
        setShowCountryCodes(enableCCVal);

        //for the SHOW_CLAIMS_UI 
        const showClaimsPS = await getParameterFromManager("SHOW_CLAIMS_UI");
        setshowClaims(showClaimsPS);

        const enableQA = await getParameterFromManager("ENABLE_QA");
        const showNewQaTool = await getParameterFromManager("SHOW_NEW_QA_TOOL");
        if ((enableQA === true && showNewQaTool === true) || (enableQA === true && showNewQaTool === false) || (enableQA === false && showNewQaTool === true)) {
          setQAStatusOptions(procStatusOptions)
          let updateColumns = getColumns(true, isArchived)
          setColumns([...updateColumns])
        } else {
          let newArr = [];
          columns.forEach(i => {
            if (i.key !== "qaFileName") {
              newArr.push(i)
            }
            setColumns(newArr)
            setQAStatusOptions(QAProcStatusOptions)
          })
        }

        const enableTour = await getParameterFromManager("ENABLE_TOUR");
        setDataTour(enableTour);

        const enableUserDataValidation = await getParameterFromManager("ENABLE_USER_DATAVALIDATIONS");
        setEnableUserDataValidations(enableUserDataValidation);

        const enableTriage = await getParameterFromManager("ENABLE_TRIAGE");
        setEnableTriage(enableTriage);

        await fetchTagsValues();

        const uploadStatus = await checkUploadStatus();
        setDisableUpload(uploadStatus);

        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        var domain = user.attributes.email.split("@")[1].toLowerCase();

        var meaValidationsCheck = await getParameterFromManager(`MEA_VALIDATIONS_CHECK`);
        if (meaValidationsCheck === null) return;
        meaValidationsCheck = getMergedMeaValidationCheckRules(meaValidationsCheck, meaValidationsCheck[domain] === undefined ? "default" : domain);
        if (meaValidationsCheck?.filetypes_supported !== undefined) {
          allowedExtensions = meaValidationsCheck?.filetypes_supported;
        }
        if (meaValidationsCheck?.supported_filetype_to_markcomplete !== undefined) {
          setMCUploadExt(meaValidationsCheck?.supported_filetype_to_markcomplete);
        }
        const newDurationInDays = parseInt(meaValidationsCheck?.submission_from_to_date_duration ?? "15", 10);
        setDurationInDays(newDurationInDays);
        let initialFromDate = moment().subtract(newDurationInDays, "days").startOf("day").toDate()
        setDisplaySubHistFromDate(initialFromDate);

        await fetchDocuments(true, procStatusExplicit, "", initialFromDate);
        setLoading(false);

        getBuildInfo();

        fetchTemplateList().then((templates) => {
          if (templates !== null && templates !== undefined) {
            for (var i = 0; i < templates.length; i++) {
              if (templates[i].TemplateName.toLowerCase() === 'default template') {
                setTemplateName(templates[i].TemplateName);
                break;
              }
            }
          } else {
            templates = [];
          }
          setTemplateOptions(templates);
        }).catch((error) => {
          console.log("Error in fetchTemplateList", error);
        });
        let userGrps = await fetchUserGroups();
        fetchLOBCodeList().then((lobCodeOptions) => {
          const hasDefaultLOBCode = userGrps?.some(item => item?.LOBCode?.toLowerCase() === "default");
          // Filter the array based on the presence of LOBCode from the user group array
          let filteredLobCodeOptions = lobCodeOptions;
          if (!hasDefaultLOBCode) {
            const lobCodesToFilter = new Set(userGrps?.map(item => item?.LOBCode));
            filteredLobCodeOptions = filteredLobCodeOptions.filter(item => lobCodesToFilter.has(item?.LOBCode));
          }
          setLOBCodeOptions(filteredLobCodeOptions);
        }).catch((error) => {
          console.log("Error in fetchLOBCodeList", error);
        });

        fetchCountryCodes().then((countryCodes) => {
          countryCodes = countryCodes.map(obj => ({ label: `${obj.countryCode} - ${obj.country}`, value: obj.countryCode }));
          const hasDefaultCCCode = userGrps?.some(item => item?.CountryCode.toLowerCase() === "default");
          // Filter the array based on the presence of CountryCode from the user group array
          let filteredCountryCodeOptions = countryCodes;
          if (!hasDefaultCCCode) {
            const CountryCodesToFilter = new Set(userGrps?.map(item => item?.CountryCode));
            filteredCountryCodeOptions = filteredCountryCodeOptions.filter(item => CountryCodesToFilter.has(item?.value));
          }
          setAssocCtryCodeOptions(filteredCountryCodeOptions);
        }).catch((error) => {
          console.log("Error in fetchCountryCodes", error);
        });

        fetchDeclineCodeList().then((declCodeOptions) => {
          declCodeOptions.forEach(function (data) {
            data['label'] = data['DeclinedReason'];
            delete data['DeclinedReason'];
            data['value'] = data['DeclineCode'];
            delete data['DeclineCode'];
          });
          setDeclineCodeOptions(declCodeOptions);
        }).catch((error) => {
          console.log("Error in fetchDeclineCodeList", error);
        });
      })();
    }
  }, []);

  useEffect(() => {
    (async function reloadPage() {
      if (extractToolClose) {
        setSubmissionDetail(null);
        setSubmissionDocList([]);
        setExtractValue();
        setExtractToolClose(false);
        await fetchDocuments(true, undefined, undefined, undefined, isArchived);
      }
    })();
  }, [extractToolClose]);

  useEffect(() => {
    checkDayLightMode();
  }, [subHistFromDate, subHistToDate]);

  //for the SHOW_CLAIMS_UI 
  useEffect(() => {
    if (showClaims) {
      steps.submissions.forEach(submission => {
        submission.content = submission.content.replace(/submission/g, "claim");
      });
    } else {
      steps.submissions.forEach(submission => {
        submission.content = submission.content.replace(/claim/g, "submission");
      });
    }
  }, [showClaims]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (showSuggestions && !e.target.classList.contains('suggestions') && e.target.tagName !== 'LI') {
        setShowSuggestions(false);
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [showSuggestions]);

  const getBuildInfo = async () => {
    const envDetails = await getParameterFromManager("ENVIRONMENT_DETAILS");
    let backendName = config.aws_user_files_s3_bucket.split('-');
    return {
      instanceRegion: config.aws_project_region,
      instanceBucket: config.aws_user_files_s3_bucket,
      instanceBackend: backendName[1],
      instanceName: envDetails?.instance_name
    }; 
  }

  const handleRefresh = async () => {
    let getMergedDoc = await getDocDetailsList(extractValue);
    setSubmissionDetail(getMergedDoc);
    return getMergedDoc;
  }

  async function invokeQATool(row) {
    setFormData(initialFormState);
    setDocumentObj({ document: row });
    let getDocumentListResult = await submissionDocumentDatalist.filter(item => item?.submissionIdentifier === row?.submissionIdentifier);
    setQaToolDocs(getDocumentListResult);
    setLaunchQATool(true);
    genQAAudit(row);
    let templateController = await getParameterFromManager("TEMPLATE_CONTROLLER");
    setTemplateControllerList(templateController?.qaTool);
  }

  async function invokeNewQATool(row, isArchived) {
    setLoading(true);
    let instanceData = await getBuildInfo();
    const getTransformData = await fetchTransformationJSONData(row,instanceData);
    setXlType(getTransformData?.inputFileType || "Generic");
    setFormData(initialFormState);
    setDocumentObj({ document: row });
    var docDetails = {
      spFolderPath: await getSpFolderPath({
        submissionIdentifier: row?.submissionIdentifier,
      }),
      instanceName: instanceData?.instanceName,
      instanceBackend: instanceData?.instanceBackend,
      instanceRegion: instanceData?.instanceRegion,
      instanceBucket: instanceData?.instanceBucket,
      submissionIdentifier: row?.submissionIdentifier,
      document_id: row?.document_id
    };
    setExtractValue(docDetails);
    var getMergedDoc = await getDocDetailsList(docDetails, isArchived);
    setSubmissionDetail(getMergedDoc);
    await fetchSubmissionDocumentlistData({
      submissionIdentifier: row?.submissionIdentifier,
      document_id: row?.document_id,
      documentName: row?.documentName
    }, isArchived);
    setLoading(false);
  }

  const fetchSubmissionDocumentlistData = async (item, isArchived) => {
    setLoading(true);
    var fetchDocList = await fetchSubmissionDocumentlist(item);
    if (fetchDocList && fetchDocList?.response === false) {
      setShowModalMsg(`The instance does not support operations from Globalops`);
      setShowModal(true);
      // resumeFromFinish();
      return false;
    } else {
      let checkEmlMsg = ['eml', 'msg'];
      if(fetchDocList.length > 0) {
        let checkDocSub = fetchDocList?.filter(list => {
          if(item?.documentName.endsWith("merged.pdf")) {
            var docExtParts = list?.documentName.split('.');
            if (checkEmlMsg.includes(docExtParts[docExtParts.length - 1].toString().toLowerCase()) !== true
            ) {
              return true;
            }
          } else if(item?.documentName !== null && list?.documentName.toString().toLowerCase() === item?.documentName.toString().toLowerCase()){
            return true;
          }
          return null;
        }).filter((document, index, self) => 
          index === self.findIndex((t) => (t.documentName === document.documentName))
        ).sort((a, b) => b.documentName?.endsWith("merged.pdf") - a.documentName?.endsWith("merged.pdf"));
        setSubmissionDocList(checkDocSub);
      } else {
        var docDetails = {
          submissionIdentifier: item.submissionIdentifier,
          document_id: item.document_id
        };
        var fetchDocDetails = await getDocDetailsList(docDetails, isArchived);
        setSubmissionDocList([fetchDocDetails]);
      }
      setLaunchExtractTool(true);
    }
    setLoading(false);
  }

  async function getDocDetailsList(document, isArchived) {
    setLoading(true);
    let dataRes = await getDocDetails(document, isArchived);
    if (dataRes && dataRes?.response === false) {
      setShowModalMsg(`The instance does not support operations from Globalops`);
      setShowModal(true);
      setLoading(false);
      // resumeFromFinish();
      return false;
    } else {
      setLoading(false);
      return dataRes;
    }
  }
  
  //EXTRACTTOOL MARKER CODE END

  function getSearchOptions() {
    const options = [{ label: showClaims ? "Claim" : "Submission", value: showClaims ? "ClaimID" : "SubmissionID" }, { label: "Document Name", value: "Document Name" }]
    return options;
  }

  function getColumns(QAToolAction = false, isArchived = false) {
    return [
      {
        key: 'submissionIdentifier', name: showClaims ? 'Claim Identifier' : 'Submission Identifier', minWidth: 250, headerRenderer({ column }) {
          var fragMent = <React.Fragment>
            <div className="first-title" data-tour="step-8">
              {column.name}
            </div>
          </React.Fragment>;
          return fragMent;
        }
      },
      {
        key: 'documentName', name: 'Source File Name', formatter({ row }) {

          let docValues = submissionDocumentDatalist.find(item =>
            row?.documentName === item?.documentName && row.submissionIdentifier === item?.submissionIdentifier
          )
          if (docValues === undefined) {
            submissionDocumentDatalist.push(row)
          }
          function renderDownload() {
            return (
              <a href="/#" onClick={(e) => {
                downloadDocuments(e, row, "source", (row.isMerged >= 1) ? `${row.submissionIdentifier}-${row.templateName === undefined ? "" : row.templateName}.pdf` : row.documentName);
              }}>
                {(row.isMerged >= 1) ? `${row.submissionIdentifier}-${row.templateName === undefined ? "" : row.templateName}` : row.documentName}
              </a>
            );
          }
          
          function renderDocument() {
            return (
              <React.Fragment>
                {(row.isMerged >= 1) ? `${row.submissionIdentifier}-${row.templateName === undefined ? "" : row.templateName}` : row.documentName}
              </React.Fragment>
            );
          }
          var fragMent = <React.Fragment>
            {
              row.processingStatus !== "Pending Upload" && userRole !== 'MEA_USERS' && userRole !== 'MEA_APIUSERS' && row.documentFullPath !== null
                ?
                (row.isMerged >= 1 && userRole === 'MEA_CORPORATE') ?
                  renderDocument()
                  :
                  renderDownload()
                :
                renderDocument()
            }
          </React.Fragment>
            ;
          return fragMent;
        },
        cellClass: `source-file-name-cell`,
      },
      { key: 'uploadDate', name: 'Uploaded Date' },
      { key: 'processingStatus', name: 'Processing Status', cellClass: `processing-status-cell`, },
      {
        key: 'uploadDocumentName', name: 'Extracted File Name', cellClass: `extracted-file-cell`, formatter({ row }) {
          var fragMent = <React.Fragment>
            {
              (
                (
                  (userRole === 'MEA_USERS' && row.paymentStatus === "Paid") ||
                  (userRole === 'MEA_PROCESSORS' || userRole === 'MEA_ADMIN')
                ) && (row.processingStatus === "Complete" || row.processingStatus === "In Progress" ||
                  row.processingStatus === "QA Uploaded" || row.processingStatus === "QA Complete" ||
                  row.processingStatus === "QA In Progress")
              ) ||
                (
                  (userRole === 'MEA_CORPORATE')
                  && (row.processingStatus === "Complete" ||
                    row.processingStatus === "QA Uploaded" || row.processingStatus === "QA Complete" ||
                    row.processingStatus === "QA In Progress")
                )
                ?
                <React.Fragment>
                  <a href="/#" onClick={(e) => {
                    downloadDocuments(e, row, "extracted", (row.isMerged >= 1) ? `${row.submissionIdentifier}-${row.templateName === undefined ? "" : row.templateName}.xlsx` : row.uploadDocumentName);
                  }}>
                    {(row.isMerged >= 1) ? `${row.submissionIdentifier}-${row.templateName === undefined ? "" : row.templateName}` : row.uploadDocumentName}
                  </a>
                </React.Fragment>
                :
                <React.Fragment>
                  {(row.isMerged >= 1) ? `${row.submissionIdentifier}-${row.templateName}.xlsx` : row.uploadDocumentName}
                </React.Fragment>
            }
          </React.Fragment>
            ;
          return fragMent;
        }
      },
      // Conditionally include the QA File Name column
      ...((useNewQAToolOrLegacy.enableQA === false && useNewQAToolOrLegacy.showNewQaTool === false) ? [] : [{
        key: 'qaFileName',
        name: 'QA File Name',
        cellClass: 'qa-filename-cell',
        formatter({ row }) {
          return (
            <a href="/#" onClick={(e) => {
              downloadDocuments(e, row, "qa");
            }}>
              {row.qaFileName}
            </a>
          );
        }
      }]),
      {
        key: 'Tags', name: 'Tags', formatter({ row }) {
          var fragMent = <React.Fragment>
            {row.Tags && row.Tags.map(item => (
              <React.Fragment>
                {item.key + " : " + item.value + ","}
              </React.Fragment>
            ))}
          </React.Fragment>;
          return fragMent;
        }
      },
      {
        key: 'Actions', name: 'Actions', minWidth: 200, formatter({ row }) {
          var fragMent = <React.Fragment>
            {
              (userRole === 'MEA_USERS' || userRole === 'MEA_ADMIN') && paymentEnabled === true && row.paymentStatus !== "Paid" && row.processingStatus === "Complete" && checkout === false &&
              !isArchived && <button type="button" className="btn-action font-weight-medium" onClick={() => {
                setCheckout(true);
                setFormData(initialFormState);
                setDocumentObj({ document: row });
              }}><i className="fa fa-credit-card" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Pay"></i></button>
            }
            {!isArchived &&
              (
                (!isArchived &&
                  ((!(row.processingStatus === "Uploaded"
                    || row.processingStatus === "Rejected"
                    || row.processingStatus === "In Progress"
                    || row.processingStatus === "QA Uploaded"
                    || row.processingStatus === "QA In Progress"
                    || row.processingStatus === "QA Complete"))
                    || (row.processingStatus === "QA Uploaded" && userRole === 'MEA_CORPORATE'))
                )
              ) &&
              <button type="button" className="btn-action font-weight-medium new-extract-btn" onClick={async () => {
                setReprocTemplateName("");
                setFormData(initialFormState);
                setDocumentObj({ document: row });
                //for the document see what all templates are used and disable them
                var usedTemp = await getUsedTemplates(row.submissionIdentifier);
                const usedTempList = usedTemp.filter(item => item !== null && item === row.templateName);
                setUsedTemplates(usedTempList);
                var templates = await fetchTemplateList();
                var lobCode = row.submissionIdentifier.split("-")[0].toLowerCase();
                var newTemplateOptions = templates.map(a => a.TemplateName);
                newTemplateOptions = newTemplateOptions.filter((el) => (!usedTemp.includes(el) && (el.toLowerCase().startsWith(lobCode) || el.toLowerCase() === 'default template')));
                if (newTemplateOptions.length > 0) {
                  setReprocTemplateName(newTemplateOptions[0]);
                }
                setEvtShowModalYesNo({ "params": { "row": documentObj.document }, "option": "reproc-template" });
                setShowModalSelectTemplate(true);
              }}><i className="fa fa-mars-double" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="New Extract"></i></button>
            }

            {
              (useNewQAToolOrLegacy.enableQA === true && useNewQAToolOrLegacy.showNewQaTool === false)  && (((userRole === 'MEA_CORPORATE') || (userRole === 'MEA_ADMIN')) && (row.processingStatus === "Complete" || row.processingStatus === "QA In Progress" || row.processingStatus === "QA Uploaded" || row.processingStatus === "QA Complete") && QAToolAction) &&
              <button type="button" className="btn-action font-weight-medium" onClick={async () => {
                invokeQATool(row);
              }}><i className="fa fa-user-shield" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="QA Tool"></i></button>
            }
            {
              ((useNewQAToolOrLegacy.enableQA === true && useNewQAToolOrLegacy.showNewQaTool === true) || (useNewQAToolOrLegacy.enableQA === false && useNewQAToolOrLegacy.showNewQaTool === true)) && (((userRole === 'MEA_CORPORATE') || (userRole === 'MEA_ADMIN')) && (row.processingStatus === "Complete" || row.processingStatus === "QA In Progress" || row.processingStatus === "QA Uploaded" || row.processingStatus === "QA Complete") && QAToolAction) &&
              <button type="button" id="newQATool" className="btn-action font-weight-medium" onClick={async () => {
                invokeNewQATool(row, isArchived);
              }}><i className="fa fa-shield-dog" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="New QA Tool"></i></button>
            }

            {!isArchived && row.processingStatus === "Rejected" &&
              <button type="button" className="btn-action font-weight-medium" onClick={(e) => {
                e.preventDefault();
                setShowModalMsg(row.rejectionReasons || 'No rejection reason found');
                setShowModal(true);
              }}><i className="fa fa-edit" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Review"></i></button>
            }

            {!isArchived && (userRole === 'MEA_PROCESSORS' || userRole === 'MEA_ADMIN') && (row.processingStatus === "QA Uploaded") &&
              <button type="button" className="btn-action font-weight-medium" onClick={(e) => {
                setEvtShowModalYesNo({ "params": { "evt": e, "row": row }, "option": "start-qa" });
                setShowModalYesNoMsg(`Do you want to mark the selected ${row.documentName} as QA In Progress?`);
                setShowModalYesNo(true);
              }}><i className="fa fa-bullhorn" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Start QA"></i></button>
            }

            {!isArchived && (userRole === 'MEA_PROCESSORS' || userRole === 'MEA_ADMIN') && (row.processingStatus === "QA Uploaded" || row.processingStatus === "QA In Progress" || row.processingStatus === "QA Complete") &&
              <button type="button" className="btn-action font-weight-medium view-qa-correction" onClick={async (e) => {
                setDocumentObj({ document: row });
                setLaunchDiffViewer(true);
              }}><i className="fa fa-check-square" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="View QA Correction"></i></button>
            }

            {!isArchived && (userRole === 'MEA_PROCESSORS' || userRole === 'MEA_ADMIN') && (row.processingStatus === "QA In Progress") &&
              <button type="button" className="btn-action font-weight-medium" onClick={(e) => {
                setEvtShowModalYesNo({ "params": { "evt": e, "row": row }, "option": "complete-qa" });
                setShowModalYesNoMsg(`You are about to mark the selected ${row.documentName} as QA Completed. Do you want to continue?`);
                setShowModalYesNo(true);
              }}><i className="fa fa-stop-circle" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Complete QA"></i></button>
            }
            {
              (row?.processingStatus === "Uploaded" || row?.processingStatus === "Pending Upload") && userRole === 'MEA_ADMIN' && <button type="button" id={`${row.submissionIdentifier}-finishBtn`} className="btn-action font-weight-medium" onClick={async (e) => {
                e.preventDefault();
                setTabSelected("profile");
                setIsInUploadedStatus(true);
                setDeclineCode([]);
                setTriageLane("Green");
                setFormData(initialFormState);
                setRunValidations(true);
                setShowModalCompleteOrReject(true);
                setDocumentObj({
                  document: row,
                });
                let submissionRejectionReasons = await getParameterFromManager(`SUBMISSION_REJECTION_REASONS`);
                setRejectionReasonsList(submissionRejectionReasons.sort(function (a, b) {
                  return a.toLowerCase().localeCompare(b.toLowerCase());
                }))
              }}><i className="fa fa-flag-checkered" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Finish"></i></button>
            }

            {!isArchived && (userRole === 'MEA_PROCESSORS' || userRole === 'MEA_ADMIN') && row.processingStatus === "In Progress" &&
              <button type="button" id={`${row.submissionIdentifier}-finishBtn`} className="btn-action font-weight-medium" title={`${row.submissionIdentifier}-finishBtn`} onClick={async (e) => {
                e.preventDefault();
                setDeclineCode([]);
                setTriageLane("Green");
                setFormData(initialFormState);
                setRunValidations(true);
                setShowModalCompleteOrReject(true);
                setDocumentObj({
                  document: row,
                });
                let submissionRejectionReasons = await getParameterFromManager(`SUBMISSION_REJECTION_REASONS`);
                setRejectionReasonsList(submissionRejectionReasons.sort(function (a, b) {
                  return a.toLowerCase().localeCompare(b.toLowerCase());
                }))
              }}><i className="fa fa-flag-checkered" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Finish"></i></button>
            }

            {!isArchived && (userRole === 'MEA_PROCESSORS' || userRole === 'MEA_ADMIN') && (row.processingStatus === "Complete" || row.processingStatus === "Rejected") &&
              <button type="button" className="btn-action font-weight-medium" onClick={(e) => {
                setEvtShowModalYesNo({ "params": { "evt": e, "row": row }, "option": "reprocess" });
                setShowModalYesNoMsg(`You are about to reprocess the selected document ${row.uploadDocumentName}. This will overwrite the previous extracted file contents. Do you want to continue?`);
                setShowModalYesNo(true);
              }}><i className="fa fa-retweet" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Reprocess"></i></button>
            }

            {!isArchived && (userRole === 'MEA_PROCESSORS' || userRole === 'MEA_ADMIN') && ((row.processingStatus === "Uploaded" && row.allowRetry === 1) || (row.processingStatus === "In Progress" && row.allowRetry === 0)) &&
              <button type="button" className={`btn-action font-weight-medium retryBtn ${row.submissionIdentifier}-retryBtn`} onClick={async (e) => {
                setReprocTemplateName("");
                setFormData(initialFormState);
                setDocumentObj({ document: row });
                //for the document see what all templates are used and disable them
                var usedTemp = await getUsedTemplates(row.submissionIdentifier);
                const usedTempList = usedTemp.filter(item => item !== null && item === row.templateName);
                setUsedTemplates(usedTempList);
                var templates = await fetchTemplateList();
                var lobCode = row.submissionIdentifier.split("-")[0].toLowerCase();
                var newTemplateOptions = templates.map(a => a.TemplateName);
                newTemplateOptions = newTemplateOptions.filter((el) => (!usedTemp.includes(el) && (el.toLowerCase().startsWith(lobCode) || el.toLowerCase() === 'default template')));
                if (newTemplateOptions.length > 0) {
                  setReprocTemplateName(newTemplateOptions[0]);
                }
                setEvtShowModalYesNo({ "params": { "evt": e, "row": documentObj.document }, "option": "retry" });
                setShowModalSelectTemplate(true);
              }}

              ><i className="fa fa-redo" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Retry"></i></button>
            }

            {!isArchived && (userRole === 'MEA_ADMIN') &&
              <button type="button" className="btn-action font-weight-medium" onClick={(e) => {
                setEvtShowModalYesNo({ "params": { "evt": e, "row": row }, "option": "delete" });
                setShowModalYesNoMsg(`You are about to delete the document ${row.documentName}. Note that you CANNOT retrieve the original document and extracted file once deleted. Do you want to continue?`);
                setShowModalYesNo(true);
              }}><i className="fa fa-trash" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Delete"></i></button>
            }

            {!isArchived && (userRole === 'MEA_PROCESSORS' || userRole === 'MEA_ADMIN' || userRole === 'MEA_CORPORATE') &&
              <button type="button" className="btn-action font-weight-medium" onClick={(e) => {
                setTagInput({ "params": { "row": row, "Tags": row.Tags } });
                setTagsUpdated(false);
                setShowTagWindow(true);
              }}><i className="fa fa-tags" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Tags"></i></button>
            }

            {
              ((!(row.processingStatus === "Pending Upload" || row.processingStatus === "Uploaded" || row.processingStatus === "In Progress" || row.processingStatus === "Rejected"))) &&
              <button type="button" className="btn-action font-weight-medium download-json" onClick={async () => {
                setLoading(true);
                var s3Path = await getTrfFile(row.jsonExtractpath, row.jsonExtractOriginalpath, row.submissionIdentifier, row.document_id);
                s3Path = s3Path.path;
                const result = await Storage.get(s3Path, { download: true, expires: 60 });
                setLoading(false);
                downloadBlob(result.Body, row.uploadDocumentName.replace(/[.]xlsx/ig, ".json"));
              }}><i className="fa fa-code" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Download JSON"></i></button>
            }

          </React.Fragment>;
          return fragMent;
        },
        groupFormatter({ childRows }) {
          var fragMent = <React.Fragment>
            {
              !isArchived && (userRole === 'MEA_PROCESSORS' || userRole === 'MEA_ADMIN') && (childRows.length > 1) && requireManualMerge(childRows) &&
              <button type="button" className="btn-action font-weight-medium" onClick={() => {
                var mergeFileRowCreated = false;
                var docRow = undefined;
                for (var i = 0; i < childRows.length; i++) {
                  if (childRows[i].documentName.toLowerCase().endsWith("_merged.pdf") === true) {
                    mergeFileRowCreated = true;
                    if (childRows[i].processingStatus === "In Progress") {
                      docRow = childRows[i];
                    }
                  }
                }
                if (mergeFileRowCreated === true) {
                  setFormData(initialFormState);
                  setShowManualMerge(true);
                  setDocumentObj({
                    document: docRow,
                  });
                } else {
                  mergeDocument(childRows[0].submissionIdentifier);
                }
              }}><i className="fa fa-compass" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Create Merge File"></i></button>
            }
          </React.Fragment>;
          return fragMent;
        },
        headerRenderer({ column }) {
          var fragMent = <React.Fragment>
            <div data-tour="step-9" className="actions-heading">
              {column.name}
            </div>
          </React.Fragment>;
          return fragMent;
        }
      }
    ]
  }

  let [columns, setColumns] = React.useState(getColumns());

  let countryCodesVal = undefined;
  let declineCodesVal = undefined;
  let userGroupsVal = undefined;
  let countryCodesValTs = undefined;
  let declineCodesValTs = undefined;
  let userGroupsValTs = undefined;

  function isCacheValid(cacheValTs) {
    if (cacheValTs === undefined) {
      return false;
    }
    const now = new Date();
    const msBetweenDates = Math.abs(cacheValTs.getTime() - now.getTime());
    const secBetweenDates = msBetweenDates / 1000;
    if (secBetweenDates > 300) {
      return false;
    }
    return true;
  }

  async function fetchDeclineCodeList() {
    try {
      if (declineCodesVal === undefined || (isCacheValid(declineCodesValTs) === false)) {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
          "access_token": user.signInUserSession.idToken.jwtToken,
          "target": "listTriageDeclinedReasons"
        };
        const init = {
          body: body
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        declineCodesValTs = new Date();
        declineCodesVal = data.response;
        return declineCodesVal;
      } else {
        return declineCodesVal;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function fetchUserGroups() {
    try {
      if (userGroupsVal === undefined || (isCacheValid(userGroupsValTs) === false)) {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
          "access_token": user.signInUserSession.idToken.jwtToken,
          "target": "fetchUserGroups"
        };
        const init = {
          body: body
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        userGroupsVal = data.response.data.listUserGroupDetails;
        userGroupsValTs = new Date();
        return userGroupsVal;
      } else {
        return userGroupsVal;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function genQAAudit(row) {
    var document = row;
    if (document.document_id !== undefined) {
      if (document.qaToolOpenCount === undefined || document.qaToolOpenCount === 0 || document.qaToolOpenCount === null) {
        logAudit(document.document_id, document.submissionIdentifier, "QA", "Open", "UI", "First Time", undefined).then((data) => { });
        const updDocument = {
          document_id: document.document_id,
          qaToolOpenCount: 1
        };
        try {
          await updateDocument(updDocument);
        } catch (err) {
          console.error(err);
        }
      }
    }
  }

  function requireManualMerge(childRows) {
    var showManualMergeBtn = true;
    for (var i = 0; i < childRows.length; i++) {
      if (childRows[i].documentName.toLowerCase().endsWith("_merged.pdf") === true) {
        if (childRows[i].processingStatus !== "In Progress") {
          showManualMergeBtn = false;
        }
      }
    }
    return showManualMergeBtn;
  }

  async function checkDayLightMode(event) {
    const result = timeZoneOffset?.timeZones.find((k) => {
      if (event === undefined) {
        return k === (searchBased === `${serverTimeZone} - Default` ? serverTimeZone : searchBased);
      } else {
        return k === (event === `${serverTimeZone} - Default` ? serverTimeZone : event);
      }
    })
    if (result) {
      const keyValue = result;
      const zoneResult = Object.entries(timeZoneOffset.tz_difference).find(([k, v]) => {
        return k === keyValue;
      });
      const [zoneKey, zoneName] = zoneResult;

      let formatDate = moment(displaySubHistFromDate).format("YYYY-MM-DD");
      var date = moment.tz(formatDate, zoneName);
      let isDST = date.isDST();

      let offset1Value = moment.tz(zoneName);
      let offset2Value = moment.tz(timeZoneOffset.tz_difference[serverTimeZone]);

      const offsetDifference = offset2Value.utcOffset() - offset1Value.utcOffset();
      let dstOffset = null;
      timeZoneOffset.daylight_saving.find((dstValue) => {
        if (!isDST && zoneKey === dstValue) {
          dstOffset = 60;
        } else {
          dstOffset = 0;
        }
        return "";
      })
      const timeDiffInMinutes = offsetDifference + dstOffset;
      setOffsetValue(timeDiffInMinutes);
    }
  }

  function isDirectQATool() {
    // Define a regular expression pattern to match the desired format
    const pattern = /^\/user\/submissions\/qatool\/(.+)$/;
    // Use the pattern to extract the identifier
    var pathname = location.pathname;
    if (localStorage.getItem("navigator") !== null) {
      pathname = localStorage.getItem("navigator");
      localStorage.removeItem("navigator");
    }
    const match = pathname.match(pattern);
    return match;
  }

  async function fetchMergeRecord(submissionIdentifier) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "fetchMergeRecord",
        "submissionIdentifier": submissionIdentifier
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      return data.response;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function fetchCountryCodes() {
    try {
      if (countryCodesVal === undefined || (isCacheValid(countryCodesValTs) === false)) {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
          "access_token": user.signInUserSession.idToken.jwtToken,
          "target": "fetchCountryCodes"
        };
        const init = {
          body: body
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        countryCodesValTs = new Date();
        countryCodesVal = data.response;
        return countryCodesVal;
      } else {
        return countryCodesVal;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function fetchLOBCodeList() {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "count": -1,
        "rowStart": 0,
        "pageSize": 100,
        "mode": "like"
      };
      const init = {
        body: JSON.stringify(body)
      };
      const data = await invokeLambdaWithRetry("fetchLOBCodeList", init);
      return data.lobCodes;
    } catch (err) {
      console.error('err: ', err);
      return false;
    }
  }

  async function fetchTemplateList() {
    try {
      const templateController = await getParameterFromManager('TEMPLATE_CONTROLLER');
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "count": -1,
        "rowStart": 0,
        "pageSize": templateController.maxTemplatesAllowed,
        "searchTemplate": "",
        "mode": "like"
      };
      const init = {
        body: JSON.stringify(body)
      };
      const data = await invokeLambdaWithRetry("fetchTemplateList", init);
      let usergroups = await fetchUserGroups();
      setUserGroups(usergroups);
      setSelectedGroup(usergroups[0].group_id);
      return data.templates;
      //console.log('dataPayload :>> ', dataPayload);
    } catch (err) {
      console.error('err: ', err);
      return false;
    }
  }

  async function getUsedTemplates(submissionIdentifier) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "getUsedTemplates",
        "submissionIdentifier": submissionIdentifier
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      return data.response;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function onDocumentFinish(document, status) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      var msgresult = await fetchMsg(document.submissionIdentifier);
      var msgDoc = msgresult.data.listDocumentsAnyWithMsg;
      if (msgDoc.length === 1) {
        document.originalMsgFileName = msgDoc[0].documentName;
      }
      const body = {
        "document": document,
        "status": status,
        "access_token": user.signInUserSession.idToken.jwtToken,
      };
      const init = {
        body: JSON.stringify(body)
      };
      const data = await invokeLambdaWithRetry("onDocumentFinish", init);
      console.log("onDocumentFinish resp:", data);
    } catch (err) {
      console.error('err: ', err);
    }
  }

  async function getTrfFile(jsonExtractpath, jsonExtractOriginalpath, submissionIdentifier, document_id) {
    try {
      await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "option": "get-trf",
        "jsonExtractpath": jsonExtractpath,
        "jsonExtractOriginalpath": jsonExtractOriginalpath,
        "submissionIdentifier": submissionIdentifier,
        "document_id": document_id
      };
      const init = {
        body: JSON.stringify(body)
      };
      const resp = await invokeLambdaWithRetry("processDocument", init);
      return resp;
    } catch (err) {
      console.error('err: ', err);
      return false;
    }
  }

  async function getSearchSuggestions(srchText) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      var body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "searchText": srchText,
        "searchType": searchType,
        "procStatus": procStatus,
        "lobCode": lobCode,
        "fromDate": displaySubHistFromDate,
        "toDate": displaySubHistToDate
      };
      if (showArchived) {
        body["showArchived"] = showArchived;
      }
      const init = {
        body: JSON.stringify(body)
      };
      const data = await invokeLambdaWithRetry("getSearchSuggestions", init);
      return data.options ? data?.options : [];
    } catch (err) {
      console.error('err: ', err);
      return [];
    }
  }

  async function setupUserRole() {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    const groups = user.signInUserSession.idToken.payload["cognito:groups"];
    if (groups !== undefined) {
      if (groups.includes('MEA_PROCESSORS')) {
        userRole = 'MEA_PROCESSORS';
        setUserRole('MEA_PROCESSORS');
        if (columns[4].key === "paymentStatus") {//Remove Payment Status Column
          columns.splice(4, 1);
          setColumns(columns);
        }
      } else if (groups.includes('MEA_ADMIN')) {
        userRole = 'MEA_ADMIN';
        setUserRole('MEA_ADMIN');
        if (columns[columns.length - 2].key === "qaFileName") {
          columns.splice(columns.length - 1, 0, { key: 'qaFileProcessedBy', name: 'QA File Processed By' });
          columns.splice(columns.length - 1, 0, { key: 'qaFileProcessedDate', name: 'QA File Processed Date' });
          setColumns(columns);
        }
      } else if (groups.includes('MEA_CORPORATE')) {
        userRole = 'MEA_CORPORATE';
        setUserRole('MEA_CORPORATE');
        if (columns[4].key === "paymentStatus") {//Remove Payment Status Column
          columns.splice(4, 1);
          setColumns(columns);
        }
      }
    }
  }

  function dbToJson(dbString) {
    if (!dbString) return [];
    const result = [];
    const regex = /{key=([^,]+), value=([^}]+)}/g;
    let match;
    while ((match = regex.exec(dbString)) !== null) {
        const key = match[1].trim();
        const value = match[2].trim();
        result.push({ key, value });
    }
    return result;
  }
    
  function jsonToDb(jsonArray) {
      if (!jsonArray || !Array.isArray(jsonArray) || jsonArray.length === 0) return '';

      const dbPairs = jsonArray.map(item => `{key=${item.key}, value=${item.value}}`);
      const result = dbPairs.join(', ');

      return result;
  }

  async function fetchDocuments(reloadPage, procStatusExplicit, noFilter, fromDate, showArchived) {
    try {
      if (reloadPage === true) {
        submissionCount = -1;
        setSubmissionCount(-1);
      }
      await setupUserRole();
      var documents = await fetchDocumentList(procStatusExplicit, noFilter, fromDate, showArchived);
      if (reloadPage === true) {
        setCurrentPage(0);
      }
      if (typeof documents !== "undefined" && documents !== null) {
        for (var i = 0; i < documents.length; i++) {
          if (documents[i].Tags !== null) {
            documents[i].Tags = dbToJson(documents[i].Tags);
          }
        }
        setDocuments(documents);
      }
    } catch (err) {
      if (err === "The user is not authenticated") {
        signOutUser();
      }
      console.error(err);
    }
  }

  async function fetchDocumentList(procStatusExplicit, noFilter, fromDate, showArchived) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });

      let timezoneOffset = offsetValue !== undefined ? offsetValue : 0;
      const inputSubHistFromDate = new Date(fromDate || displaySubHistFromDate);
      const inputSubHistToDate = new Date(displaySubHistToDate);
      const localTimeZoneOffset = inputSubHistFromDate.getTimezoneOffset();
      const newSubHistFromDate = new Date(inputSubHistFromDate.getTime() + (timezoneOffset - localTimeZoneOffset) * 60 * 1000);
      const newSubHistToDate = new Date(inputSubHistToDate.getTime() + (timezoneOffset - localTimeZoneOffset) * 60 * 1000);
      var body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "count": submissionCount,
        "rowStart": currentPage * pageSize,
        "pageSize": pageSize,
        "searchText": ((noFilter === "nofilter") || (searchMode === "Full")) ? "" : searchText,
        "searchType": searchType,
        "procStatus": ((noFilter === "nofilter") || (searchMode === "Full")) ? "All" : (procStatusExplicit === undefined || procStatusExplicit === "") ? procStatus : procStatusExplicit,
        "lobCodeFilter": ((noFilter === "nofilter") || (searchMode === "Full")) ? "All" : lobCode,
        "countryCodeFilter": ((noFilter === "nofilter") || (searchMode === "Full")) ? "All" : countryCode,
        "subHistFromDate": newSubHistFromDate,
        "subHistToDate": newSubHistToDate
      };
      if (showArchived) {
        body["showArchived"] = showArchived;
      }
      const init = {
        body: JSON.stringify(body)
      };
      const data = await invokeLambdaWithRetry("fetchDocumentList", init);
      if (submissionCount === -1 && data.count) {
        setSubmissionCount(data.count.rowcount);
      }
      if (showArchived) {
        setIsArchived(true);
        setColumns(getColumns(true, true));
      } else {
        setIsArchived(false);
        setColumns(getColumns(true, false));
      }
      return data.documents;
    } catch (err) {
      console.error('err: ', err);
      return false;
    }
  }

  async function fetchDocStatus(document) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "fetchMergeDocDetails",
        "document": document.document,
        "triageLane": triageLane,
        "declineCode": declineCode
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      return data.response;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function getFileSizeLimit(domain) {
    if (domain === 'meaplatform.com') {
      return -1;
    }
    var meaValidationsCheck = await getParameterFromManager("MEA_VALIDATIONS_CHECK");
    meaValidationsCheck = getMergedMeaValidationCheckRules(meaValidationsCheck, meaValidationsCheck[domain] === undefined ? "default" : domain);
    if (meaValidationsCheck.maxfilesize_mb !== undefined) {
      return parseInt(meaValidationsCheck.maxfilesize_mb) * 1024 * 1024;
    }
    return -1;
  }

  async function createDocuments(e) {
    e.preventDefault();
    console.log(`templateName: ${templateName}`);
    try {
      setLoading(true);
      if ((!formData.documentName || !formData.documentFullPath || (formData.file === null)) && (formData.files === null))
        return;
      try {
        var inputFiles = [];
        var documentFullPath;
        if (formData.files === null) {
          documentFullPath = `${submissionIdentifier}/${uuid()}/${uuid()}${formData.documentName}`;
          await Storage.put(documentFullPath, formData.file);
          inputFiles.push({ documentName: formData.documentName, documentFullPath: documentFullPath });
        } else {
          for (var i = 0; i < formData.files.length; i++) {
            documentFullPath = `${submissionIdentifier}/${uuid()}/${uuid()}${formData.files[i].name}`;
            await Storage.put(documentFullPath, formData.files[i]);
            inputFiles.push({ documentName: formData.files[i].name, documentFullPath: documentFullPath });
          }
        }
        var result = await processUpload(submissionIdentifier, templateName, inputFiles, selectedGroup);
        if (result.enableCCVal === true) {
          setEnableCCValue(result.enableCCVal);
        }
        if (result.disableUpload !== undefined) {
          setDisableUpload(result.disableUpload);
        }
        if (result.allow === false) {
          setShowModalMsg(result.message);
          setShowModal(true);
        }
      } catch (err) {
        if (err === "The user is not authenticated") {
          signOutUser();
        }
        console.error('err: ', err);
      }
      formData.target.value = null;
      setFormData(initialFormState);
      setDocuments([...documents, formData]);
      fetchDocuments(true);
      setEnableTemplate(false);
      setSubmissionModal(false);
      setSubmissionIdentifier('');
      setSelectedFileName('');
    } finally {
      setLoading(false);
    }
  }

  async function retryDocument(document, reprocTemplateName) {
    try {
      setLoading(true);
      console.log("reprocTemplateName", reprocTemplateName);
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      var documentName = document.documentName;
      extractDocument(document, "newupload", undefined, undefined, true, document.compoundDocNumber !== undefined, reprocTemplateName);
      notifyUser({ "toAddress": user.attributes.email, "name": user.attributes.given_name, "fileName": documentName, "option": "newDocumentCreated", "submissionIdentifier": document.submissionIdentifier });
      logAudit(document.document_id, document.submissionIdentifier, "Submission", "Retry", "UI", undefined, undefined, reprocTemplateName, { isGlobalRecord: 1, documentName: document.documentName }).then((data) => { });
      setShowModalSelectTemplate(false);
      await fetchDocuments(true);
    } finally {
      setLoading(false);
    }
  }

  async function receiveStrippedExtract(inpDocument, fPath, uploadDocumentFullPath) {
    try {
      setLoading(true);
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "stripped-extract",
        "submissionIdentifier": inpDocument.submissionIdentifier,
        "document_id": inpDocument.document_id,
        "fPath": fPath,
        "uploadDocumentFullPath": uploadDocumentFullPath
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      console.log(data);
    } finally {
      setLoading(false);
    }
  }

  async function downloadDocuments(e, document, downloadType, fileName) {
    e.preventDefault();
    const templateController = await getParameterFromManager('TEMPLATE_CONTROLLER');
    var fPath = (downloadType === "extracted" ? document.uploadDocumentFullPath : (downloadType === "qa" ? document.qaFileFullPath : document.documentFullPath));
    let SOVJsonPath = `${document.documentFullPath.split('/')[0]}/DocumentsMeta/${document.document_id}.json`;
    let jsonS3DataRes,data;
    try {
      jsonS3DataRes = await Storage.get(SOVJsonPath, { download: true });
      let json_data = await jsonS3DataRes.Body.text();
      data = JSON.parse(json_data);
    } catch (err) {
      //console.log("SOV Json not found");
    }
    if (templateController.jsonDownloadColumns !== undefined && templateController.jsonDownloadColumns[0]?.toLowerCase()?.trim() !== "all" && downloadType === "extracted" && (data ? ((data.inputFileType.toLowerCase() === "generic") || (templateController.jsonDownloadColumnsSOV && templateController.jsonDownloadColumnsSOV[0]?.toLowerCase()?.trim() !== "all")) : true)) {
      fPath = replaceLastOfFile(fPath, "_stripped", "xlsx");
      var exists = await doesFileExist(fPath);
      if(exists !== true) {
        await receiveStrippedExtract(document, fPath, document.uploadDocumentFullPath);
      }
    }
    if (downloadType === "extracted" && document.downloadCount === 0) {
      const updDocument = {
        document_id: document.document_id,
        downloadCount: document.downloadCount + 1
      };
      try {
        await updateDocument(updDocument);
      } catch (err) {
        if (err === "The user is not authenticated") {
          signOutUser();
        }
        console.error(err);
      }
    }
    if (data && data.inputFileType?.toLowerCase() !== "generic" && downloadType !== "source") {
      const result = await Storage.get(fPath);
      const response = await fetch(result);
      const arrayBuffer = await response.arrayBuffer();
      await downloadExcel(arrayBuffer, downloadType === "extracted" ? fileName : (downloadType === "qa" ? document.qaFileName : fileName));
    } else {
      const result = await Storage.get(fPath, { download: true, expires: 60 });
      downloadBlob(result.Body, downloadType === "extracted" ? fileName : (downloadType === "qa" ? document.qaFileName : fileName));
    }
  }

  async function updateDocumentTags(document_id, Tags) {
    console.log(`updateDocumentTags : `, Tags);
    try {
      setLoading(true);
      try {
        if (Tags !== null) {
          const updDocument = {
            document_id: document_id,
            Tags: Tags
          };
          await updateDocument(updDocument);
        } else {
          await clearDocumentTags(document_id);
        }
        await fetchDocuments(false);
      } catch (err) {
        console.error(err);
        if (err === "The user is not authenticated") {
          signOutUser();
        }
      }
    } finally {
      setLoading(false);
    }
  }

  async function reprocessDocument(inpDocument, templateName) {
    try {
      setLoading(true);
      var today = new Date();
      var nDate = today.toJSON().slice(0, 10);//YYYY-MM-DD
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "getLastMergedSeq",
        "submissionIdentifier": inpDocument.submissionIdentifier
      };
      const init = {
        body: body
      };
      let res = await invokeLambdaWithRetry("instanceHandler", init);
      var origInfo = await fetchDocument(inpDocument.origin_document_id);
      var document_id = uuid();
      let document = {
        document_id: document_id,
        submissionIdentifier: inpDocument.submissionIdentifier.toUpperCase(),
        documentName: inpDocument.documentName,
        documentFullPath: inpDocument.documentFullPath,
        uploadDate: nDate,
        processingStatus: 'Uploaded',
        submittedby: user.username,
        domainForDoc: origInfo.domainForDoc,
        downloadCount: 0,
        templateName: templateName,
        origin_document_id: inpDocument.origin_document_id,
        origin: origInfo.origin === "user-plain" ? "user-plain" : "merge",
        group_id: origInfo.group_id
      };
      var filePathParts = inpDocument.documentFullPath.toLowerCase().split('.');
      if (allowedSimpleExtensions.split(',').map((i) => i.trim()).includes(`.${filePathParts[filePathParts.length - 1]}`) === false) {
        document.isMerged = res + 1;
      }
      var documentEscaped = { ...document };
      documentEscaped.submissionIdentifier = documentEscaped.submissionIdentifier.replace(/'/g, "''").replace(/"/g, "\\\"");
      documentEscaped.documentName = documentEscaped.documentName.replace(/'/g, "''").replace(/"/g, "\\\"");
      documentEscaped.documentFullPath = documentEscaped.documentFullPath.replace(/'/g, "''").replace(/"/g, "\\\"");
      res = await createDocument(documentEscaped);
      console.log(`createDoc : ${JSON.stringify(res)}`);
      if (allowedSimpleExtensions.split(',').map((i) => i.trim()).includes(`.${filePathParts[filePathParts.length - 1]}`) === false) {
        extractDocument(document, "reproc-template", undefined, undefined, undefined, undefined, reprocTemplateName, origInfo.group_id);
      } else {
        extractDocument(document, "reproc-template-simple", undefined, undefined, undefined, undefined, reprocTemplateName, origInfo.group_id);
      }
      logAudit(document_id, documentEscaped.submissionIdentifier, "Submission", "Reprocess Template", "UI", undefined, undefined, undefined, { isGlobalRecord: 1, documentName: documentEscaped.documentName }).then((data) => { });
      await fetchDocuments(true);
      setShowModalSelectTemplate(false);
    } finally {
      setLoading(false);
    }
  }

  async function uploadManualMergeDoc(e, document) {
    e.preventDefault();
    if (formData.file === null)
      return;
    try {
      setLoading(true);
      var newMergePath = getExtractPath(document.documentFullPath !== null ? document.documentFullPath : document.uploadDocumentFullPath);
      newMergePath = `${newMergePath}/${uuid()}${documentObj.document.documentName}`;
      //Upload this file into the S3 bucket for that Submission
      await Storage.put(newMergePath, formData.file);
      //Copy the merged file to the sharepoint under the submission id folder
      cptood(document.submissionIdentifier, newMergePath, documentObj.document.documentName);
      //Update the row for the submission id for the following columns
      const updDocument = {
        document_id: document.document_id,
        documentFullPath: newMergePath
      };
      await updateDocument(updDocument);
      setFormData(initialFormState);
      setShowManualMerge(false);
      await fetchDocuments(true);
    } finally {
      setLoading(false);
    }
  }

  async function mergeDocument(submissionIdentifier) {
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });

      console.log(`Invoking mergeDocument for ${submissionIdentifier}`);
      var body = {
        "submissionIdentifier": submissionIdentifier,
        "access_token": user.signInUserSession.idToken.jwtToken,
        "userName": user.username,
        "option": "merge-ready-files",
        "trigger": "user"
      };
      var msgresult = await fetchMsg(document.submissionIdentifier);
      var msgDoc = msgresult.data.listDocumentsAnyWithMsg;
      if (msgDoc.length === 1) {
        document.origin_document_id = msgDoc[0].document_id;
      }
      const init = {
        body: JSON.stringify(body)
      };
      const data = await invokeLambdaWithRetry("mergeDocuments", init);
      console.log(`Invoked mergeDocument for ${submissionIdentifier} response: ${JSON.stringify(data)}`);// successful response
    } catch (err) {
      console.error('err: ', err);
      setLoading(false);
      setShowModalMsg("Error encountered while creating merge file, contact the support team");
      setShowModal(true);
      return false;
    }
    await fetchDocuments(true);
    setLoading(false);
    setShowModalMsg("Merge file was successfully created, check the submission");
    setShowModal(true);
    return true;
  }

  async function runMacros(username, email, document, s3Path, access_token, post_process) {
    try {
      //Store file to S3
      var macResponse = {};
      const body = {
        "access_token": access_token,
        "option": "macro_process",
        "post_process": post_process,
        "document_id": document.document_id,
        "inputfile": s3Path,
        "submissionIdentifier": document.submissionIdentifier,
        "templateName":document?.templateName
      };
      const init = {
        body: JSON.stringify(body)
      };
      let resp = await invokeLambdaWithRetry("extractDocument", init);
      var bReturn = true;
      var msg = [];
      if (resp.status !== undefined && resp.status.length !== 0) {
        for (var m = 0; m < resp.status.length; m++) {
          msg.push(resp.status[m]);
          if (resp.status[m].code !== "altered") {
            bReturn = false;
          }
        }
        macResponse = { status: bReturn, outputfile: resp.outputfile, info: resp.info, msg: msg };
      } else if (resp.statusCode === 200) {
        macResponse = { status: true, outputfile: s3Path, info: resp.info };
      }
      //Show list of errors and warnings
      if (macResponse.status === true) {
        await doDocumentProcessing(username, email, document, "Complete", undefined, macResponse.outputfile, resp.info.producerName, resp.info.numFieldsExtracted, resp.info.insuredName, resp.info.brokerName, true);
      }
      return macResponse;
    } catch (err) {
      console.error('err: ', err);
      return { status: false };
    }
  }

  function replaceLastOfFile(fname, last, extension) {
    var fparts = fname.split(".");
    fparts[fparts.length - 2] += last;
    fparts[fparts.length - 1] = extension;
    return fparts.join(".");
  }

  async function updateSubmissionsReport(submissionsReportInput) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "updateSubmissionsReport",
        "submissionsReportInput": submissionsReportInput
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      return data.response;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function updateDocument(updateDocumentsInput) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "updateDocument",
        "updateDocumentsInput": updateDocumentsInput
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      return data.response;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function createDocument(createDocumentsInput) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "createDocument",
        "createDocumentsInput": createDocumentsInput
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      return data.response;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function clearDocumentTags(document_id) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "clearDocumentTags",
        "document_id": document_id
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      return data.response;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function deleteDocument(document_id) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "deleteDocument",
        "document_id": document_id
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      return data.response;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function doDocumentProcessing(username, email, document, status, rejectionReasons, outputfile, producerName, numFieldsExtracted, insuredName, brokerName, macrosOn) {
    var ext, outputFileName;
    if (status === "Complete") {
      if (macrosOn === true) {
        ext = formData.uploadDocumentName.split('.');
        ext = ext[ext.length - 1].toLowerCase();
        outputFileName = replaceLastOfFile(formData.uploadDocumentName, "_macproc", ext);
      } else {
        outputFileName = formData.uploadDocumentName;
      }
    }

    try {
      var today = new Date();
      var nDate = today.toJSON().slice(0, 10);//YYYY-MM-DD
      const updDocument = {
        document_id: document.document_id,
        submissionIdentifier: document.submissionIdentifier,
        documentName: document.documentName
      };
      if (document.templateName !== undefined && document.templateName !== null) {
        updDocument.templateName = document.templateName;
      }
      if (status === "In Progress") {
        updDocument.processedby = username;
        updDocument.processingStatus = status;
      } else if (status === "Complete") {
        updDocument.processedby = username;
        updDocument.processedDate = nDate;
        updDocument.processingStatus = status;
        updDocument.uploadDocumentName = outputFileName;
        updDocument.uploadDocumentFullPath = outputfile;
      } else if (status === "Rejected") {
        updDocument.rejectionReasons = rejectionReasons;
        updDocument.processedby = username;
        updDocument.processedDate = nDate;
        updDocument.processingStatus = status;
      } else if (status === "Paid") {
        updDocument.processedby = username;
        updDocument.paymentDate = nDate;
        updDocument.paymentStatus = status;
      } else if (status === "QA In Progress") {
        updDocument.qaFileProcessedBy = username;
        updDocument.qaFileProcessedDate = nDate;
        updDocument.processingStatus = status;
      } else if (status === "QA Complete") {
        updDocument.qaFileProcessedBy = username;
        updDocument.qaFileProcessedDate = nDate;
        updDocument.processingStatus = status;
      }

      if (status === "Complete") {
        await cptood(document.submissionIdentifier, outputfile, outputFileName, true);
        if (formData.uploadDocumentName.toLowerCase().endsWith(".csv") === false) {
          var sResp = await extractDocument(document, "edited", outputfile, outputFileName, undefined, undefined);
          console.log(`doDocumentProcessing sync response : ${sResp}`);
        }

      } else if (status === "Rejected") {
        await cptood(document.submissionIdentifier, undefined, undefined, true);
      }

      var updDocumentEscaped = { ...updDocument };
      if (updDocumentEscaped.uploadDocumentName !== undefined) {
        updDocumentEscaped.uploadDocumentName = updDocumentEscaped.uploadDocumentName.replace(/'/g, "''").replace(/"/g, "\\\"");
        updDocumentEscaped.uploadDocumentFullPath = updDocumentEscaped.uploadDocumentFullPath.replace(/'/g, "''").replace(/"/g, "\\\"");
      }
      await updateDocument(updDocumentEscaped);
      updDocumentEscaped.documentFullPath = document.documentFullPath;
      if (status === "Complete") {
        const updSubmissionReport = {
          submissionIdentifier: document.submissionIdentifier,
          document_id: document.document_id,
          SubProductName: producerName,
          NumFieldsExtracted: numFieldsExtracted,
          DeclineCode: declineCode.join(','),
          TriageLane: triageLane,
          InsuredName: insuredName,
          BrokerName: brokerName
        };
        await updateSubmissionsReport(updSubmissionReport);
        notifyUser({ "username": document.submittedby, "processorname": email, "fileName": outputFileName, "option": "documentComplete", "submissionIdentifier": document.submissionIdentifier });
        onDocumentFinish(updDocumentEscaped, status);
        logAudit(document.document_id, document.submissionIdentifier, "Submission", "Complete", "UI", undefined, undefined).then((data) => { });
      } else if (status === "Rejected") {
        notifyUser({ "username": document.submittedby, "processorname": email, "fileName": document.documentName, "option": "documentRejected", "submissionIdentifier": document.submissionIdentifier });
        onDocumentFinish(updDocumentEscaped, status);
        logAudit(document.document_id, document.submissionIdentifier, "Submission", "Rejected", "UI", undefined, undefined).then((data) => { });
      } else if (status === "In Progress") {
        notifyUser({ "username": document.submittedby, "processorname": email, "fileName": document.documentName, "option": "documentInProgress", "submissionIdentifier": document.submissionIdentifier });
        logAudit(document.document_id, document.submissionIdentifier, "Submission", "In Progress", "UI", undefined, undefined, (document.templateName !== undefined && document.templateName !== null) ? document.templateName : undefined).then((data) => { });
      } else if (status === "QA Complete") {
        notifyUser({ "username": document.submittedby, "processorname": email, "fileName": document.documentName, "option": "qaComplete", "submissionIdentifier": document.submissionIdentifier });
        logAudit(document.document_id, document.submissionIdentifier, "QA", "QA Complete", "UI", undefined, undefined).then((data) => { });
      } else if (status === "QA In Progress") {
        notifyUser({ "username": document.submittedby, "processorname": email, "fileName": document.documentName, "option": "qaInProgress", "submissionIdentifier": document.submissionIdentifier });
        logAudit(document.document_id, document.submissionIdentifier, "QA", "QA In Progress", "UI", undefined, undefined).then((data) => { });
      }
    } catch (err) {
      console.error(err);
    }
  }

  const updateTransformedPathForFinish = async (document) => {
    try {
      let jsonFilePath = `${document?.documentFullPath.split('/')[0]}/DocumentsMeta/${document?.document_id}.json`;
      let jsonData = await fetchJsonData({ docPath: jsonFilePath });
      let transformedData = JSON.parse(jsonData);
      if (transformedData !== false) {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
          "access_token": user.signInUserSession.idToken.jwtToken,
          "target": "update-transformed-path-for-finish",
          "inputFileType": transformedData.inputFileType,
          "document_id": document.document_id,
        };
        const init = {
          body: body
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        return data.response;
      }
      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function doUxDocumentProcessing(document, status, msg, rejectionReasons, outputfile, producerName, numFieldsExtracted, insuredName, brokerName) {
    try {
      setLoading(true);
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      await doDocumentProcessing(user.username, user.attributes.email, document, status, rejectionReasons, outputfile, producerName, numFieldsExtracted, insuredName, brokerName);
      setRejectionReasons('');
      setShowModalCompleteOrReject(false);
      setShowModalMsg(msg);
      setShowModal(true);
      await fetchDocuments(true);
    } catch (err) {
      if (err === "The user is not authenticated") {
        signOutUser();
      }
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  async function removeDocument(e, document) {
    e.preventDefault();
    try {
      setLoading(true);
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        await deleteDocument(document.document_id);
        notifyUser({ "username": document.submittedby, "processorname": user.attributes.email, "fileName": document.documentName, "option": "documentDeleted", "submissionIdentifier": document.submissionIdentifier });
        logAudit(document.document_id, document.submissionIdentifier, "Submission", "Deleted", "UI", undefined, undefined).then((data) => { });
      } catch (err) {
        if (err === "The user is not authenticated") {
          signOutUser();
        }
        console.error(err);
      }
      await fetchDocuments(true);
    } finally {
      setLoading(false);
    }
  }

  function rowKeyGetter(row) {
    return row.document_id;
  }

  function getExtractPath(documentFullPath) {
    var docFPs = documentFullPath.split('/');
    docFPs.pop();
    return docFPs.join('/');
  }

  async function setNewDocumentForUpload(e, processed) {
    try {
      if (processed === true) {
        const file = e.target.files[0];
        const uuidForPath = uuid();
        var pathForExtracts = undefined;
        if (documentObj.document.documentFullPath !== null && documentObj.document.documentFullPath !== undefined) {
          pathForExtracts = getExtractPath(documentObj.document.documentFullPath);
        } else {
          var res = await fetchMsg(documentObj.document.submissionIdentifier);
          pathForExtracts = getExtractPath(res.data.listDocumentsAnyWithMsg[0].documentFullPath);
        }
        setFormData({ ...formData, uploadDocumentName: file.name, uploadDocumentFullPath: `${pathForExtracts}/${uuidForPath}${file.name}`, file: file, target: e.target });
      } else {
        setFormData({ ...formData, files: e.target.files, target: e.target });
      }
    } catch (err) {
      console.error(err);
      if (err === "The user is not authenticated") {
        signOutUser();
      }
    }
  }

  async function onMergeFileChange(e) {
    if (!e.target.files[0])
      return;
    if (documentObj.document.documentName.toLowerCase() !== e.target.files[0].name.toLowerCase()) {
      setShowModalMsg(`Merge file does not match ${documentObj.document.documentName}`);
      setShowModal(true);
      e.target.value = null;
      return;
    }
    var file = e.target.files[0];
    setFormData({ ...formData, file: file, target: e.target });
  }

  function isAllowedExtension(filePath, processed) {
    var filePathParts = filePath.toLowerCase().split('.');
    if (processed === true) {
      if (mcUploadExt.split(',').map((i) => i.trim()).includes(`.${filePathParts[filePathParts.length - 1]}`) === true) {
        return true;
      }
    }
    else if (allowedExtensions.split(',').map((i) => i.trim()).includes(`.${filePathParts[filePathParts.length - 1]}`) === true) {
      return true;
    }
    return false;
  }

  async function onChange(e, processed) {
    if (!e.target.files[0]) {
      if (processed) {
        setFormData({ ...formData, uploadDocumentName: '', uploadDocumentFullPath: '', file: null, target: null });
      }
      return;
    }
    if (e.target.files.length > 0) {
      setSelectedFileName(e.target.files);
    } else {
      setSelectedFileName('');
    }

    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });

    for (var m = 0; m < e.target.files.length; m++) {
      var fileAllowed = isAllowedExtension(e.target.files[m].name, processed);
      if (fileAllowed === false) {
        if (processed === true) {
          setShowModalMsg(`Only ${mcUploadExt} are allowed to be used to mark the submission as complete`);
        } else {
          setShowModalMsg(`The file type you are trying to upload is not supported. Supported file type extension are ${allowedExtensions}`);
        }
        setShowModal(true);
        e.target.value = null;
        setFormData({ ...formData, files: null });
        return;
      }
    }

    var fileSizeLimit = await getFileSizeLimit(user.attributes.email.split("@")[1].toLowerCase());
    if (fileSizeLimit !== -1) {
      for (var i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i].size > fileSizeLimit) {
          e.target.value = null;
          setShowModalMsg(`The file size being uploaded is more than the defined file size threshold of ${fileSizeLimit / 1024 / 1024} MB. Contact your system admin or reach out to mea support for more clarificaiton`);// ${e.target.files[i].name}
          setShowModal(true);
          setFormData({ ...formData, files: null });
          return;
        }
      }
    }

    for (i = 0; i < e.target.files.length; i++) {
      let meaValidationsCheck = await getParameterFromManager(`MEA_VALIDATIONS_CHECK`);
      if (e.target.files[i].name.length >  meaValidationsCheck["default"].max_filename_length) {
        e.target.value = null;
        setShowModalMsg(`The file name length is more than ${meaValidationsCheck["default"].max_filename_length} chars, shorten the file name and upload it again`);
        setShowModal(true);
        setFormData({ ...formData, files: null });
        return;
      }
    }

    if (!processed) {
      setEnableTemplate(false);
      var tplEnabled = await getParameterFromManager("USE_TEMPLATE");
      if (tplEnabled !== false) {
        for (i = 0; i < e.target.files.length; i++) {
          if (isTemplateAllowed(e.target.files[i].name) === true) {
            setEnableTemplate(true);
          }
        }
      }
    }
    if (processed === true && (documentObj.document.uploadDocumentName !== undefined) && (documentObj.document.uploadDocumentName !== null) && (documentObj.document.uploadDocumentName !== "") && (e.target.files[0].name.toLowerCase() !== documentObj.document.uploadDocumentName.toLowerCase())) {
      setEvtShowModalYesNo({ "params": e, "option": "upload-docs" });
      setShowModalYesNoMsg(`The newly uploaded document <b> ${e.target.files[0].name} </b> differs from the original extracted file <b> ${documentObj.document.uploadDocumentName} </b> . Do you want to continue ?`);
      setShowModalYesNo(true);
      return;
    }
    await setNewDocumentForUpload(e, processed);
  }

  function isInvalidSubmissionId(submissionIdentifier) {
    if (submissionIdentifier.trim().length === 0)
      return true;
    if (showCountryCodes === true) {
      //The submission Identifier would be of the format lobcode-countrycode-subid value if the "Enable Country Code Validation" is enabled.
      var subidparts = submissionIdentifier.split('-');
      if (subidparts.length < 3) {
        return true;
      }
      var ctryCode = assocCtryCodeOptions.filter(obj => {
        return obj.value.toLowerCase() === subidparts[1].toLowerCase();
      });
      if (ctryCode === undefined || ctryCode.length !== 1) {
        return true;
      }
      var lobCode = lobCodeOptions.filter(obj => {
        return obj.LOBCode.toLowerCase() === subidparts[0].toLowerCase();
      });
      if (lobCode === undefined || lobCode.length !== 1 || lobCode[0].AssocCountryCodes === null || lobCode[0].AssocCountryCodes === undefined || (lobCode[0].AssocCountryCodes.toLowerCase().split(',').includes(ctryCode[0].value.toLowerCase()) === false)) {
        return true;
      }
    }
    return false;
  }

  const onDeclineCodesChange = val => {
    if (val.trim().length === 0) {
      setDeclineCode([]);
    } else {
      setDeclineCode(val.trim().split(','));
    }
  }

  function disallowSubmission(file, files) {
    if (file === null && files === null) {
      return true
    }
    if (file !== null) {
      if (isAllowedExtension(file.name) === false) {
        return true;
      }
    }
    if (files !== null) {
      for (var i = 0; i < files.length; i++) {
        if (isAllowedExtension(files[i].name) === false)
          return true;
      }
    }
    return false;
  }

  async function onSearchTextChange(event) {
    const value = event.target.value;
    setSearchText(event.target.value);
    var procStatusExplicit = "";
    if (value.length < 1) {
      setLoading(true);
      await fetchDocuments(true, procStatusExplicit, 'nofilter', undefined, isArchived);
      setLoading(false);
    }
    if (value.length > 3) {
      setShowSuggestions(true);
      let filterData = await getSearchSuggestions(value);
      setCurrentData(filterData);
    } else {
      setShowSuggestions(false);
      setCurrentData([]);
    }
  };

  const showFileNames = () => {
    let fileNames = [];
    for (var i = 1; i < selectedFileName.length; i++) {
      fileNames.push(selectedFileName[i].name)
    }
    return <><br /><p className="btntool show-more-files" data-toggle="tooltip" data-placement="bottom" title={fileNames.join("\n")} >
      More files...
    </p></>
  }

  useEffect(() => {
    const closeIcon = document.querySelector(".reactour__close-button");
    if (closeIcon) {
      closeIcon.addEventListener("click", () => {
        setTourNewSubmissionVisible(false);
        onClickClose();
      });
    }
  }, [currentStep])

  useEffect(() => {
    setSelectedFileName('');
    setTemplateName('');
    setFormData(initialFormState);
  }, [submissionModal])


  const mapTemplateOptions = () => {
    let filteredTemplateOptions = [];
    var subidparts = submissionIdentifier.split('-').map(part => part.toLowerCase());
    if(lobCodeValidationEnabled === true && showCountryCodes === true ) {
      filteredTemplateOptions = templateOptions.filter(template => {
        return userGroups.some(group => {
            const templateNameLowerCase = template.TemplateName.toLowerCase();
            if(templateNameLowerCase.startsWith(`default`)) {
                return true;
            }
            if(group.group_id === process.env.REACT_APP_DEFAULT_USER_GROUP && (templateNameLowerCase.startsWith(`${subidparts[0]}-${subidparts[1]}-`) || templateNameLowerCase.startsWith(`${subidparts[0]}-default`))) {
              return true;
            }
            const lobCodeLowerCase = group.LOBCode.toLowerCase();
            const countryCodeLowerCase = group.CountryCode.toLowerCase();
            if(subidparts.length >= 1) {
                if(templateNameLowerCase.startsWith(`${subidparts[0]}-default`) && (lobCodeLowerCase === subidparts[0] || lobCodeLowerCase === "default")) {
                    return true;
                }
            }
            if(subidparts.length >= 2) {
                if(templateNameLowerCase.startsWith(`${subidparts[0]}-${subidparts[1]}-`) && (lobCodeLowerCase === subidparts[0] || lobCodeLowerCase === "default") && (countryCodeLowerCase === subidparts[1] || countryCodeLowerCase === "default")) {
                    return true;
                }
            }
            return false;
        });
      });
    } else if(lobCodeValidationEnabled === true && showCountryCodes === false ) {
      filteredTemplateOptions = templateOptions.filter(template => {
        return userGroups.some(group => {
            const templateNameLowerCase = template.TemplateName.toLowerCase();
            if(templateNameLowerCase.startsWith(`default`)) {
                return true;
            }
            if(group.group_id === process.env.REACT_APP_DEFAULT_USER_GROUP && templateNameLowerCase.startsWith(`${subidparts[0]}-`)) {
              return true;
            }
            const lobCodeLowerCase = group.LOBCode.toLowerCase();
            if(subidparts.length >= 1) {
                if(templateNameLowerCase.startsWith(`${subidparts[0]}-`) && (lobCodeLowerCase === subidparts[0] || lobCodeLowerCase === "default")) {
                    return true;
                }
            }
            return false;
        });
      });
    } else if(lobCodeValidationEnabled === false && showCountryCodes === true ) {
      filteredTemplateOptions = templateOptions.filter(template => {
        return userGroups.some(group => {
            const templateNameLowerCase = template.TemplateName.toLowerCase();
            if(templateNameLowerCase.startsWith(`default`)) {
                return true;
            }
            if(group.group_id === process.env.REACT_APP_DEFAULT_USER_GROUP && templateNameLowerCase.startsWith(`${subidparts[0]}-`)) {
              return true;
            }
            const countryCodeLowerCase = group.CountryCode.toLowerCase();
            if(subidparts.length >= 1) {
                if(templateNameLowerCase.startsWith(`${subidparts[0]}-`) && (countryCodeLowerCase === subidparts[0] || countryCodeLowerCase === "default")) {
                    return true;
                }
            }
            return false;
        });
      });
    } else if(lobCodeValidationEnabled === false && showCountryCodes === false ) {
      filteredTemplateOptions = templateOptions;
    }
    if(filteredTemplateOptions.length > 0 && templateName === '') {
      setTemplateName(filteredTemplateOptions[0].TemplateName);
    }
    return filteredTemplateOptions;
  };

  const filteredUserGroups = () => {
    let filteredUg = [];
    const templateNameLowerCase = templateName.toLowerCase();
    var subidparts = submissionIdentifier.split('-').map(part => part.toLowerCase());
    if(lobCodeValidationEnabled === true && showCountryCodes === true ) {
        filteredUg = userGroups.filter(group => {
            if(group.group_id === process.env.REACT_APP_DEFAULT_USER_GROUP)
                return true;
            const countryCodeLowerCase = group.CountryCode.toLowerCase();
            const lobCodeLowerCase = group.LOBCode.toLowerCase();
            if (countryCodeLowerCase !== "default" && lobCodeLowerCase !== "default") {
                return templateNameLowerCase.startsWith(`${lobCodeLowerCase}-${countryCodeLowerCase}-`) || templateNameLowerCase.startsWith(`${lobCodeLowerCase}-default`) || (lobCodeLowerCase === subidparts[0] && countryCodeLowerCase === subidparts[1]);
            } else if (countryCodeLowerCase === "default" && lobCodeLowerCase === "default") {
                return true;
            } else if (countryCodeLowerCase === "default") {
                return templateNameLowerCase.startsWith(`${lobCodeLowerCase}-`) || lobCodeLowerCase === subidparts[0];
            } else  {//if (lobCodeLowerCase === "default")
                return templateNameLowerCase.startsWith(`${countryCodeLowerCase}-`) || countryCodeLowerCase === subidparts[1];
            }
        });
    } else if(lobCodeValidationEnabled === true && showCountryCodes === false ) {
        filteredUg = userGroups.filter(group => {
            const lobCodeLowerCase = group.LOBCode.toLowerCase();
            if (lobCodeLowerCase === "default") {
              return true;              
            } else {
              return group.group_id === process.env.REACT_APP_DEFAULT_USER_GROUP || templateName.startsWith(`${group.LOBCode}-`) || lobCodeLowerCase === subidparts[0] ;
            }
        });
    } else if(lobCodeValidationEnabled === false && showCountryCodes === true ) {
        filteredUg = userGroups.filter(group => {
            const countryCodeLowerCase = group.CountryCode.toLowerCase();
            if (countryCodeLowerCase === "default") {
              return true;              
            } else {
              return group.group_id === process.env.REACT_APP_DEFAULT_USER_GROUP || templateName.startsWith(`${group.CountryCode}-`) || countryCodeLowerCase === subidparts[0] ;
            }
        });
    } else if(lobCodeValidationEnabled === false && showCountryCodes === false ) {
      filteredUg = userGroups;
    }
    if(filteredUg.length > 0 && selectedGroup !== filteredUg[0].group_id) {
      setSelectedGroup(filteredUg[0].group_id);
    }
    return filteredUg;
  };

  const fetchTagsValues = async () => {
    const tagsList = await getParameterFromManager('TAGS_META_INFO');
    setTagsList(tagsList.default_tags);
  }

  return (
    <>
      <Container fluid className="p-0">
        {dataTour && <div className="ml-3 p-1">
          <button className="button-start" onClick={(event) => {
            event.preventDefault();
            setTourNewSubmissionVisible(true);
            setSteps(steps["submissions"]);
            setCurrentStep(0);
            setIsOpen(true);
          }}>Turn On Tour
          </button>
        </div>
        }
        <Form>
          <Row>
            <Col md="12">
              <Card className={(userRole === 'MEA_USERS' || userRole === 'MEA_CORPORATE' || userRole === 'MEA_ADMIN') ? "strpied-tabled-with-hover submissions-listing-uploadusers" : "strpied-tabled-with-hover submissions-listing-full"}>
                <Card.Header>
                  <Card.Title className="row mx-0 h5 mb-0 filter-submission">
                    {/* <NotificationManager /> */}
                    <div className="filter-submission-left col-md-1 col-sm-6 col-6 pl-0 pr-1 pt-2 icon-search">
                      <span className="mr-1 icon-static d-inline"><BiSearch /></span>
                    </div>
                    <div className="filter-submission-right">
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 mb-1 pl-1 pr-1">
                          <label><span className="mr-1 d-inline"></span>Find a</label>
                          <select className="form-control filter-inputdropdown" id="typeOfInput" value={searchType} onChange={(event) => {
                            setCurrentData([]);
                            setSearchType(event.target.value);
                            setSearchText('');
                          }}>
                            {
                              getSearchOptions().map(option => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                              ))
                            }
                          </select>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 mb-1 pl-1 pr-1">
                          <label>in</label>
                          <select className="form-control dropdown-listoflobs" id="listOfLobs" value={lobCodeDisplay} onChange={(event) => {
                            if (event.target.selectedIndex === 0) {
                              setLOBCode("All");
                              setLOBCodeDisplay(event.target.value);
                            } else {
                              setLOBCode(lobCodeOptions[event.target.selectedIndex - 1].LOBCode);
                              setLOBCodeDisplay(event.target.value);
                            }
                          }}>
                            <option key="All LOBs">{"All LOBs"}</option>
                            {
                              lobCodeOptions && lobCodeOptions.map(lobCodeOption => (
                                <option key={lobCodeOption.LOBCode}>{`${lobCodeOption.LOBCode} - ${lobCodeOption.LineOfBusiness}`}</option>
                              ))
                            }
                          </select>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-1 pl-1 pr-1">
                          <label>in</label>
                          <select className="form-control dropdown-listofccodes" id="listOfCcodes" value={countryCodeDisplay} onChange={(event) => {
                            if (event.target.selectedIndex === 0) {
                              setCountryCode("All");
                              setCountryCodeDisplay(event.target.value);
                            } else {
                              setCountryCode(assocCtryCodeOptions[event.target.selectedIndex - 1].value);
                              setCountryCodeDisplay(event.target.value);
                            }
                          }}>
                            <option key="All Countries">{"All Countries"}</option>
                            {
                              assocCtryCodeOptions && assocCtryCodeOptions.map(assocCtryCodeOptions => (
                                <option key={assocCtryCodeOptions.value}>{`${assocCtryCodeOptions.label}`}</option>
                              ))
                            }
                          </select>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-1 pl-1 enter-submissionid">
                          <label>named</label>
                          <input
                            id="search"
                            type="text"
                            autoComplete="off"
                            className="form-control"
                            placeholder={`Enter ${showClaims ?"claim ID" : "submission ID"}`}
                            value={searchText}
                            onChange={async (e) => {
                              onSearchTextChange(e);
                            }}
                            disabled={!displaySubHistFromDate}
                            onKeyDown={async (e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                searchMode = "Filtered";
                                setSearchMode("Filtered");
                                setLoading(true);
                                let isChecked = document.getElementById('Show-archived-checkbox').checked;
                                await fetchDocuments(true,undefined,undefined,undefined,isChecked);
                                setLoading(false);
                              }
                            }}
                          />
                          {showSuggestions && currentData.length > 0 && (
                            <ul className="suggestions">
                              {currentData.map((item, index) => (
                                <li
                                  key={index}
                                  onClick={() => {
                                    setSearchText(item);
                                    setCurrentData([]);
                                    searchMode = "Filtered";
                                    setSearchMode("Filtered");
                                    setLoading(true);
                                    fetchDocuments(true, undefined, undefined, undefined, isArchived);
                                    setLoading(false);
                                  }}
                                >
                                  {item}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 filter-todate mt-1 pl-1 pr-1">
                          <label>Date</label>
                          <DatePicker
                            placeholderText="From"
                            value={displaySubHistFromDate}
                            className="form-control date-fld"
                            selected={displaySubHistFromDate}
                            dateFormat="dd/MM/yyyy hh:mm:ss a"
                            showTimeSelect
                            maxDate={displaySubHistToDate}
                            onChange={(event) => {
                              const fromDateTimeFormat =
                                moment(event).format("YYYY-MM-DD HH:mm:ss");
                              if (subHistToDate !== "") {
                                if (fromDateTimeFormat <= subHistToDate) {
                                  setDisplaySubHistFromDate(event);
                                  setSubHistFromDate(fromDateTimeFormat);
                                } else {
                                  setDisplaySubHistFromDate(event);
                                  setSubHistFromDate("");
                                }
                              } else {
                                setDisplaySubHistFromDate(event);
                                setSubHistFromDate(fromDateTimeFormat);
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 filter-fromdate mt-1 pl-1 pr-1">
                          <label>and</label>
                          <DatePicker
                            placeholderText="To"
                            className="form-control date-fld"
                            selected={displaySubHistToDate}
                            dateFormat="dd/MM/yyyy hh:mm:ss a"
                            showTimeSelect
                            minDate={displaySubHistFromDate}
                            onChange={(event) => {
                              const toDateTimeFormat =
                                moment(event).format("YYYY-MM-DD HH:mm:ss");
                              if (subHistFromDate !== "") {
                                if (toDateTimeFormat >= subHistFromDate) {
                                  setDisplaySubHistToDate(event);
                                  setSubHistToDate(toDateTimeFormat);
                                } else {
                                  setDisplaySubHistToDate("");
                                  setSubHistToDate("");
                                }
                              } else {
                                setDisplaySubHistToDate(event);
                                setSubHistToDate(toDateTimeFormat);
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-3 col-6 mt-1 pl-1 pr-1">
                          <select className="form-control listofstatus" id="submissionHistoryStatus" value={procStatus} onChange={(event) => {
                            if (event.target.selectedIndex === 0) {
                              setProcStatus("All");
                            } else {
                              setProcStatus(event.target.value)
                            }
                          }}>
                            <option key="All Status">{"All Status"}</option>
                            {
                              (userRole !== 'MEA_PROCESSORS') && QAStatusOptions.map(procStatusOption => (
                                <option key={procStatusOption}>{procStatusOption}</option>
                              ))
                            }
                            {
                              (userRole === 'MEA_PROCESSORS') && QAStatusOptions.map(procStatusOption => (
                                <option key={procStatusOption}>{procStatusOption}</option>
                              ))
                            }
                          </select>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 filter-actions d-flex justify-content-end mt-1 pl-1">
                          <div className="align-items-center">
                            <div className="showarchive">
                              <input type="checkbox" id="Show-archived-checkbox" className="border-left-0" onChange={() => { setShowArchived(!showArchived); }} checked={showArchived} />
                              <label>Show Archived</label>
                            </div>
                          </div>
                          <div className="btn-search-refresh">
                            <ul className="list-inline mb-0 d-flex align-items-center justify-content-end ">
                              <li key="search" className="list-inline-item">
                                <button type="button" id="submissionHistorySearch" className="btn btn-primary btn-sm font-weight-medium ml-2" disabled={!isSearchEnabled} onClick={async (e) => {
                                  e.preventDefault();
                                  searchMode = "Filtered";
                                  setSearchMode("Filtered");
                                  setCurrentPage(0);
                                  pageInputRef.current.value = '';
                                  setLoading(true);
                                  await fetchDocuments(true, undefined, undefined, undefined, showArchived);
                                  setLoading(false);
                                }} >Search</button>
                              </li>
                              <li key="clearandrefresh" className="list-inline-item" data-tour="step-7">
                                <button type="button" id="refreshSubmissionBtn" className="btn btn-primary btn-md btn-reduce refresh-icon" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Clear & Refresh" onClick={async (e) => {
                                  if (!isOpen) {
                                    e.preventDefault();
                                    setShowArchived(false);
                                    setIsArchived(false);
                                    setSearchMode("Full");
                                    setLoading(true);
                                    const newFromDate = moment().subtract(durationInDays, "days").startOf("day").toDate();
                                    if (!displaySubHistFromDate) {
                                      setDisplaySubHistFromDate(newFromDate);
                                      setSubHistFromDate(moment(newFromDate).format("YYYY-MM-DD HH:mm:ss"));
                                    } else if (newFromDate.getTime() !== displaySubHistFromDate.getTime()) {
                                      setDisplaySubHistFromDate(newFromDate);
                                    }
                                    setDisplaySubHistToDate(moment().endOf("day").toDate());
                                    setProcStatus("All");
                                    setLOBCodeDisplay("All");
                                    setCountryCodeDisplay("All");
                                    setSearchBased(`${serverTimeZone} - Default`);
                                    setOffsetValue(undefined);
                                    await fetchDocuments(true, undefined, "nofilter");
                                    pageInputRef.current.value = '';
                                    setSearchText('');
                                    setLoading(false);
                                  }
                                }} ><i className="fas fa-sync"></i></button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 text-right mt-4 p-0">
                      <button type="button" disabled={currentPage === 0} className="btn btn-primary btn-sm font-weight-medium mr-2" onClick={async (e) => {
                        e.preventDefault();
                        setLoading(true);
                        currentPage = currentPage - 1;
                        setCurrentPage(currentPage);
                        await fetchDocuments(false, undefined, undefined, undefined, isArchived);
                        pageInputRef.current.value = ''; // clear the text field
                        setLoading(false);
                      }}>Prev</button>
                      <p className="d-inline-block gotopage mr-1">
                        <input type="number" autoComplete="off" maxLength={4} className="form-control" placeholder="Go to Page" ref={pageInputRef} onKeyDown={async (e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            setLoading(true);
                            let totalSubmissionCount = (Math.ceil(submissionCount / pageSize));
                            if (e.target.value <= 0 || totalSubmissionCount <= 0) {
                              e.target.value = 1;
                            } else if (e.target.value > totalSubmissionCount) {
                              e.target.value = totalSubmissionCount;
                            }
                            let pageNumber = parseInt(e.target.value);
                            currentPage = pageNumber - 1;
                            setCurrentPage(currentPage);
                            await fetchDocuments(false, undefined, undefined, undefined, isArchived);
                            setLoading(false);
                          }
                        }} />
                      </p>
                      <p className="d-inline">{currentPage + 1} of {submissionCount === -1 ? 1 : Math.ceil(submissionCount / pageSize) === 0 ? 1 : Math.ceil(submissionCount / pageSize)}</p>
                      <button type="button" disabled={((currentPage + 1) === (Math.ceil(submissionCount / pageSize))) || (submissionCount <= 0)} className="btn btn-primary btn-sm font-weight-medium ml-2" onClick={async (e) => {
                        e.preventDefault();
                        setLoading(true);
                        currentPage = currentPage + 1;
                        setCurrentPage(currentPage);
                        await fetchDocuments(false, undefined, undefined, undefined, isArchived);
                        pageInputRef.current.value = ''; // clear the text field
                        setLoading(false);
                      }}>Next</button>
                    </div>
                  </Card.Title>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive submission-list">
                  <DataGrid columns={columns} rows={documents} getRowId={(rowData) => rowData.submissionIdentifier} rowId={rowData => `${rowData.submissionIdentifier}`} groupBy={["submissionIdentifier"]} rowGrouper={rowGrouper} defaultColumnOptions={{ resizable: true }}
                    expandedGroupIds={expandedGroupIds}
                    onExpandedGroupIdsChange={setExpandedGroupIds}
                    rowKeyGetter={rowKeyGetter}
                    rowHeight={45}
                    className="fill-grid submissions-history-table"
                    rowClass={rowData => `${rowData.submissionIdentifier}`}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
        {/* Mini Modal */}
        <Modal
            className="modal-centre"
            backdrop="static"
            keyboard={false}
            show={loading}
            animation={false}
            centered
          >
            <Modal.Body className="text-center">
              <CirclesLoader isLoading={loading} />
            </Modal.Body>
          </Modal>
          {/* End Modal */}
        {/* Mini Modal */}
        <Modal
          className="modal-mini modal-primary modal-occur"
          show={showModal}
          onHide={() => setShowModal(false)}
          animation={false}
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile">
              <i className="nc-icon nc-bulb-63"></i>
            </div>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p className="multi-line">{renderReactFragment(showModalMsg)}</p>
          </Modal.Body>
          <div className="modal-footer justify-content-center">
            <button type="button" className="btn btn-primary btn-md font-weight-medium btn-occur-close" onClick={() => setShowModal(false)}>Close</button>
          </div>
        </Modal>
        {/* End Modal */}

        {/* Mini Modal */}
        <Modal
          className="modal-lg modal-primary modal-msg-list"
          show={showModalList}
          onHide={() => setShowModalList(false)}
          animation={false}
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile">
              <i className="nc-icon nc-bulb-63"></i>
            </div>
          </Modal.Header>
          <Modal.Body className="text-center">
            <ol>
              {
                showModalListMsg.map(msgInfo => (
                  <li><i className={msgInfo.code === "error" ? "fa fa-exclamation-triangle" : msgInfo.code === "altered" ? "fa fa-exclamation-circle" : "fa fa-check"} /> {msgInfo.code} : {msgInfo.msg}</li>
                ))
              }
            </ol>
          </Modal.Body>
          <div className="modal-footer justify-content-center">
            <button type="button" className="btn btn-primary btn-md font-weight-medium btn-occur-close" onClick={() => {
              setShowModalList(false);
              var errors = showModalListMsg.filter(msgInfo => {
                return msgInfo.code === "error"
              });
              if (errors.length > 0) {
                return;
              }
              setShowModalMsg("Document marked complete");
              setShowModal(true);
            }}>Close</button>
          </div>
        </Modal>
        {/* End Modal */}

        {/* Mini Modal */}
        <Modal
          className="modal-mini modal-primary"
          show={showModalSelectTemplate}
          onHide={() => setShowModalSelectTemplate(false)}
          animation={false}
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile">
              <i className="nc-icon nc-bulb-63"></i>
            </div>
          </Modal.Header>
          <Modal.Body className="text-center">
            <div>
              <p>Select Template to { evtShowModalYesNo?.option === "retry" ? "Retry" : "Reprocess"}</p>
              {(templateOptions.length > 0) &&
                <select className="form-control form-control-md" id="selectTemplateDropDown" value={reprocTemplateName} onChange={(event) => {
                  setReprocTemplateName(event.target.value);
                }}>
                  {
                    !lobCodeValidationEnabled && !showCountryCodes ? templateOptions.map(templateOption => {
                      return <option key={templateOption.TemplatePath}>{templateOption.TemplateName}</option>;
                    }) :
                    templateOptions.map(templateOption => (
                      (templateOption.TemplateName.toUpperCase().startsWith(`${(documentObj && documentObj.document && documentObj.document.submissionIdentifier ? documentObj.document.submissionIdentifier.split('-')[0] : "RESERVED")}-`) || templateOption.TemplateName.toLowerCase() === 'default template') ?
                        (
                          showCountryCodes ? (
                            ((templateOption.TemplateName.toUpperCase().split("-")[1] === documentObj?.document?.submissionIdentifier?.toUpperCase().split('-')[1]) || (templateOption.TemplateName.toLowerCase() === 'default template')) ?
                              <option disabled={usedTemplates.findIndex(element => {
                                return element.toLowerCase() === templateOption.TemplateName.toLowerCase();
                              }) !== -1} key={templateOption.TemplatePath} className={usedTemplates.findIndex(element => {
                                  return element.toLowerCase() === templateOption.TemplateName.toLowerCase();
                                }) !== -1 ? 'option_disabled' : ''}>{templateOption.TemplateName}</option>
                              : null
                          )
                            : <option disabled={usedTemplates.findIndex(element => {
                              return element.toLowerCase() === templateOption.TemplateName.toLowerCase();
                            }) !== -1} key={templateOption.TemplatePath} className={usedTemplates.findIndex(element => {
                              return element.toLowerCase() === templateOption.TemplateName.toLowerCase();
                            }) !== -1 ? 'option_disabled' : ''}>{templateOption.TemplateName}</option>)
                        : <React.Fragment key="none"></React.Fragment>
                    ))
                  }
                </select>}
            </div>
          </Modal.Body>
          <div className="modal-footer justify-content-center">
            <button type="button" className="btn btn-secondary btn-md font-weight-medium" onClick={async () => {
              setEvtShowModalYesNo({ "params": { "row": documentObj.document }, "option": evtShowModalYesNo?.option });
              let msg = evtShowModalYesNo?.option === "retry" ? `You are about to resubmit the input document ${documentObj.document.documentName}. This will overwrite any previous contents created. Do you want to continue?` : `You are about to reprocess the submission[${documentObj.document.submissionIdentifier}] using the template [${reprocTemplateName}]. <b> Do you want to continue ? </b>`
              setShowModalYesNoMsg(msg);
              setShowModalYesNo(true);
            }} disabled={reprocTemplateName.trim().length === 0}>Submit</button>
            <button type="button" className="btn btn-primary btn-md font-weight-medium" onClick={() => setShowModalSelectTemplate(false)}>Close</button>
          </div>
        </Modal>
        {/* End Modal */}

        {/* Mini Modal */}
        <Modal
          className="modal-md modal-primary"
          show={showModalYesNo}
          onHide={() => setShowModalYesNo(false)}
          animation={false}
          backdropClassName="question-modal"
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile">
              <i className="nc-icon nc-bulb-63"></i>
            </div>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p className="multi-line">{renderReactFragment(showModalYesNoMsg)}</p>
          </Modal.Body>
          <div className="modal-footer justify-content-center">
            <button type="button" className="btn btn-secondary btn-md font-weight-medium" id="mark-complete-yes-btn" onClick={async () => {
              if (evtShowModalYesNo.option === "upload-docs") {
                setNewDocumentForUpload(evtShowModalYesNo.params, true);
              } else if (evtShowModalYesNo.option === "start-qa") {
                evtShowModalYesNo.params.evt.preventDefault();
                doUxDocumentProcessing(evtShowModalYesNo.params.row, "QA In Progress", "Document marked as QA In Progress");
              } else if (evtShowModalYesNo.option === "complete-qa") {
                evtShowModalYesNo.params.evt.preventDefault();
                doUxDocumentProcessing(evtShowModalYesNo.params.row, "QA Complete", "Document marked as QA Complete");
              } else if (evtShowModalYesNo.option === "reprocess") {
                evtShowModalYesNo.params.evt.preventDefault();
                var document = evtShowModalYesNo.params.row;
                logAudit(document.document_id, document.submissionIdentifier, "Submission", "Reprocess", "UI", undefined, undefined).then((data) => { });
                doUxDocumentProcessing(evtShowModalYesNo.params.row, "In Progress", "Document marked as under processing");
              } else if (evtShowModalYesNo.option === "retry") {
                retryDocument(evtShowModalYesNo.params.row, reprocTemplateName);
              } else if (evtShowModalYesNo.option === "delete") {
                removeDocument(evtShowModalYesNo.params.evt, evtShowModalYesNo.params.row);
              } else if (evtShowModalYesNo.option === "reproc-template") {
                reprocessDocument(evtShowModalYesNo.params.row, reprocTemplateName);
              }
              setShowModalYesNo(false);
            }}>Yes</button>
            <button type="button" className="btn btn-primary btn-md font-weight-medium" onClick={() => {
              setShowModalYesNo(false);
              if (evtShowModalYesNo.option === "upload-docs") {
                evtShowModalYesNo.params.target.value = null;
              }
              setEvtShowModalYesNo(null);
            }}>No</button>
          </div>
        </Modal>
        {/* End Modal */}

        {/* Mini Modal */}
        <Modal
          className="modal-centre submissions-finish"
          show={showModalCompleteOrReject}
          onHide={() => { setShowModalCompleteOrReject(false); setTabSelected("home"); setIsInUploadedStatus(false); }}
          animation={false}
        >
          <Modal.Body className="text-center">
            <Tabs defaultActiveKey={tabSelected} id="finish-processing" className="mb-3">
              <Tab eventKey="home" title="Finish Processing" disabled={isInUploadedStatus}>
                <Form.Group className="mb-0">
                  <Card className="strpied-tabled-with-hover mb-0">
                    <Card.Body className="table-responsive">
                      <Row>
                        <Col className="d-flex justify-content-center" md="12">
                          <Form.Group className="mb-2">
                            <h5 className="mb-3">File to upload</h5>
                            <Form.Control
                              placeholder="Select File"
                              type="file"
                              id="mark-complete-file-input"
                              accept={mcUploadExt}
                              onChange={(e) => {
                                onChange(e, true);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      {enableUserDataValidations && <Row>
                        <Col className="text-center modal-checkbox" md="12">
                          <div className="form-group mb-2">
                            <div className="input-group">
                              <input type="checkbox" id="run-validation-checkbox" className="form-control border-left-0" onChange={() => { setRunValidations(!runValidations); }} checked={runValidations} />
                            </div>
                            <label>Run Validations</label>
                          </div>
                        </Col>
                      </Row>}
                      <Row>
                        <div className="col-12 radio-btns">
                          <input type="radio" value="Green" name="green" onChange={() => {
                            if (triageLane !== "Green") {
                              setTriageLane("Green");
                              setDeclineCode([]);
                            }
                          }} checked={triageLane === "Green"} /> <label>Green</label>
                          <input type="radio" value="Amber" name="amber" onChange={() => {
                            if (triageLane !== "Amber") {
                              setTriageLane("Amber");
                              setDeclineCode([]);
                            }
                          }} checked={triageLane === "Amber"} /> <label>Amber</label>
                          <input type="radio" value="Red" name="red"
                            onChange={() => {
                              if (triageLane !== "Red") {
                                setTriageLane("Red");
                                setDeclineCode([]);
                              }
                            }} checked={triageLane === "Red"} /> <label>Red</label>
                        </div>
                      </Row>
                      {enableTriage && (triageLane !== "Green") && <Row className="d-flex justify-content-center mb-3">
                        <Col className="text-center modal-checkbox" lg="12">
                          <MultiSelect
                            onChange={onDeclineCodesChange}
                            options={declineCodeOptions}
                          />
                        </Col>
                      </Row>}
                      <Row className="justify-content-center pb-2">
                        <Col className="col-lg-5 col-md-6 col-sm-6 col-6">
                          <button type="button" className="btn btn-primary btn-md font-weight-medium" id="mark-complete-btn" disabled={(!formData.uploadDocumentName || !formData.uploadDocumentFullPath || ((triageLane === "Red" || triageLane === "Amber") && declineCode.length === 0))} onClick={async (e) => {
                            var s3Path;
                            e.preventDefault();
                            setLoading(true);
                            setFormData(initialFormState);
                            setShowModalCompleteOrReject(false);
                            setRejectionReasons('');
                            setShowModalMsg("");
                            setShowModal(false);
                            var resp = await fetchDocStatus(documentObj);
                            if (resp && resp?.processingStatus !== "In Progress") {
                              setShowModalMsg("This submission is already processed, refresh the list to see the latest status");
                              setShowModal(true);
                              setLoading(false);
                            } else {
                              resp = {};
                              const user = await Auth.currentAuthenticatedUser({
                                bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
                              });
                              if ((enableUserDataValidations === true && runValidations === false) || (formData.uploadDocumentName.toLowerCase().endsWith(".csv"))) {
                                s3Path = `${getExtractPath(documentObj.document.documentFullPath !== null ? documentObj.document.documentFullPath : documentObj.document.uploadDocumentFullPath)}/${uuid()}-${formData.uploadDocumentName}`;
                                await Storage.put(s3Path, formData.file, {
                                  metadata: { transmitToClient: "true" }
                                });
                                resp = await runMacros(user.username, user.attributes.email, documentObj.document, s3Path, user.signInUserSession.idToken.jwtToken, false);
                                await doUxDocumentProcessing(documentObj.document, "Complete", "Document marked complete", undefined, s3Path);
                                await updateTransformedPathForFinish(documentObj.document);
                              } else {
                                try {
                                  s3Path = `${getExtractPath(documentObj.document.documentFullPath !== null ? documentObj.document.documentFullPath : documentObj.document.uploadDocumentFullPath)}/${uuid()}-${formData.uploadDocumentName}`;
                                  await Storage.put(s3Path, formData.file, {
                                    metadata: { transmitToClient: "true" }
                                  });
                                  resp = await runMacros(user.username, user.attributes.email, documentObj.document, s3Path, user.signInUserSession.idToken.jwtToken);
                                } catch (err) {
                                  console.error('err: ', err);
                                } finally {
                                  setLoading(false);
                                }
                                e.target.value = null;
                                if (resp.msg !== undefined && resp.msg.length > 0) {
                                  setShowModalListMsg(resp.msg);
                                  setShowModalList(true);
                                } else {
                                  setShowModalMsg("Document marked complete");
                                  setShowModal(true);
                                }
                              }
                            }
                            await fetchDocuments(true);
                          }}>Mark complete</button>
                        </Col>
                        <Col className="col-lg-3 col-md-3 col-sm-4 col-4">
                          <button type="button" className="btn btn-primary btn-md font-weight-medium" onClick={() => { setShowModalCompleteOrReject(false); setTabSelected("home"); setIsInUploadedStatus(false); }}>Cancel</button>
                        </Col>
                      </Row>
                      <hr></hr>
                      <div className="stats mt-2">
                        Upload processed document to complete submission
                      </div>
                    </Card.Body>
                  </Card>
                </Form.Group>
              </Tab>
              <Tab eventKey="profile" title="Reject" id="rejectTab">
                <Form.Group className="mb-0">
                  <Card className="strpied-tabled-with-hover mb-0">
                    <Card.Body className="table-responsive">
                      <Form.Group>
                        <label>Rejection Reasons</label>
                        <div className="px-0">
                          <select defaultValue="" className="form-control form-control-md" id="rejectionReasonDropdown" value={rejectionReasons} onChange={(event) => {
                            setCurrentData([]);
                            setRejectionReasons(event.target.value);
                          }}>
                            {
                              ["Select", ...rejectionReasonsList].map((reasons, idx) => idx === 0 ? (<option key="none" value="">{reasons}</option>) : (
                                <option key={reasons} value={reasons}>{reasons}</option>
                              ))
                            }
                          </select>
                        </div>
                      </Form.Group>
                      <Row className="justify-content-center mt-3">
                        <Col sm="12">
                          <button type="button" id="rejectFileBtn" className="btn btn-primary btn-md font-weight-medium mr-1"
                            disabled={!rejectionReasons ? true : false}
                            onClick={async (e) => {
                              e.preventDefault();
                              setLoading(true);
                              var resp = await fetchDocStatus(documentObj);
                              if (resp && resp?.processingStatus !== "In Progress" && !isInUploadedStatus) {
                                setShowModalMsg("This submission is already processed, refresh the list to see the latest status");
                                setShowModal(true);
                                setLoading(false);
                              } else {
                                if (rejectionReasons.trim().length === 0) {
                                  setShowModalMsg("You need to select rejection reasons");
                                  setShowModal(true);
                                  return;
                                }
                                doUxDocumentProcessing(documentObj.document, "Rejected", "Document marked rejected", rejectionReasons);
                                setRejectionReasons("");
                                setTabSelected("home");
                                setIsInUploadedStatus(false);
                              }
                            }}>Reject file</button>
                          <button type="button" className="btn btn-primary btn-md font-weight-medium ml-1" onClick={() => {
                            setShowModalCompleteOrReject(false);
                            setRejectionReasons("");
                            setTabSelected("home");
                            setIsInUploadedStatus(false);
                          }}>Cancel</button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Form.Group>
              </Tab>
            </Tabs>
          </Modal.Body>
          <div className="modal-footer">
          </div>
        </Modal>
        {/* End Modal */}

        {/* Mini Modal */}
        <Modal
          className="modal-centre"
          show={showManualMerge}
          onHide={() => setShowManualMerge(false)}
          animation={false}
        >
          <Modal.Body className="text-center manual-merge">
            <Tabs defaultActiveKey="home" id="manual-processing" className="mb-3">
              <Tab eventKey="home" title="Manual Merge">
                <Form.Group className="mb-0">
                  <Card className="strpied-tabled-with-hover mb-0">
                    <Card.Body className="table-responsive">
                      <Row>
                        <Col className="justify-content-center mb-3" lg="12">
                          <Form.Group>
                            <h5 className="mb-3">File to upload</h5>
                            <Form.Control
                              placeholder="Select File"
                              type="file"
                              accept=".pdf"
                              onChange={(e) => {
                                onMergeFileChange(e);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="justify-content-center mb-3">
                        <Col className="col-12">
                          <button type="button" className="btn btn-primary btn-md font-weight-medium mr-1" disabled={formData.file === null} onClick={(e) => {
                            uploadManualMergeDoc(e, documentObj.document);
                          }}>Manual Merge</button>
                          <button type="button" className="btn btn-primary btn-md font-weight-medium ml-1" onClick={() => setShowManualMerge(false)}>Cancel</button>
                        </Col>
                      </Row>
                      <hr></hr>
                      <div className="stats">
                        Upload manual merged document
                      </div>
                    </Card.Body>
                  </Card>
                </Form.Group>
              </Tab>
            </Tabs>
          </Modal.Body>
          <div className="modal-footer">
          </div>
        </Modal>
        {/* End Modal */}

        {/* Mini Modal */}
        <Modal
          className="modal-centre"
          show={checkout}
          onHide={() => setCheckout(false)}
          animation={false}
        >
          <Modal.Body className="text-center">
            <Form.Group>
              <Card className="strpied-tabled-with-hover">
                <Card.Body className="table-responsive">
                  <Form.Group>
                    <label>Finish Payment</label>
                    <div className="payment-div">
                      <Suspense fallback={<CirclesLoader isLoading={loading} />}>
                        <ReactPayPal document={documentObj.document} fetchDocuments={fetchDocuments} setPaid={setPaid} />
                      </Suspense>
                      <button type="button" className="btn btn-primary btn-md font-weight-medium" onClick={() => { setCheckout(false); }}>{paid
                        ? "Ok" : "Cancel"}</button>
                    </div>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Form.Group>
          </Modal.Body>
          <div className="modal-footer">
          </div>
        </Modal>
        {/* End Modal */}

        {/* Mini Modal */}
        <Modal
          className="modal-centre tags-modal"
          show={showTagWindow}
          onHide={() => setShowTagWindow(false)}
          animation={false}
        >
          <Modal.Body className="text-center">
            <Form.Group className="mb-0">
              <Card className="strpied-tabled-with-hover mb-0">
                <Card.Body className="table-responsive">
                  <label>Tags : {tagInput && tagInput.params && tagInput.params.row ? tagInput.params.row.submissionIdentifier : ""}[{tagInput && tagInput.params && tagInput.params.row ? tagInput.params.row.documentName : ""}] </label>
                  <ListGroup>
                    {tagInput && tagInput.params && tagInput.params.Tags && tagInput.params.Tags.map(item => (
                      <ListGroup.Item>
                        {item.key + " : " + item.value}
                        <button type="button" className="btn-action font-weight-medium" onClick={(e) => {
                          setTagsUpdated(true);
                          var tags = tagInput.params.Tags ? [...tagInput.params.Tags] : [];
                          for (var i = 0; i < tags.length; i++) {
                            if (tags[i].key === item.key) {
                              tags.splice(i, 1);
                              break;
                            }
                          }
                          setTagInput({ "params": { "row": tagInput.params.row, "Tags": tags } });
                        }}><i className="fa fa-trash" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Delete"></i></button>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                  <div className="payment-div">
                    {/* <input type="text" className="form-control form-control-md mb-1" maxLength="10" placeholder="Key" onChange={(event) => { setTagKey(event.target.value); }} value={tagKey} />
                    <input type="text" className="form-control form-control-md mb-3" maxLength="10" placeholder="Value" onChange={(event) => { setTagValue(event.target.value); }} value={tagValue} /> */}
                    <MultiSelect
                      placeholder="Select Key"
                      className="mb-2"
                      defaultValue={tagKey}
                      customValue={true}
                      onChange={(selectedOption) => {
                        setTagValue("");
                        setSelectedTagData({});
                        setTagKey(selectedOption);
                        let selectedData = tagsList?.find(item => item.name === selectedOption);
                        if (selectedData) {
                          setSelectedTagData(selectedData);
                        } else {
                          let checkDuplicate = tagsList?.find(item => item?.name?.toString().toLowerCase() === selectedOption.toString().toLowerCase());
                          if (checkDuplicate === undefined) {
                            if (tagsList?.length === 0) {
                              setTagsList([{name: selectedOption, type: "default"}]);
                            } else {
                              setTagsList([...tagsList,{name: selectedOption, type: "default"}]); 
                            }
                            setSelectedTagData({name: selectedOption, type: "default"});
                          }                         
                        }
                      }}
                      options={
                        tagsList?.length > 0
                          ? tagsList.map(item => {
                            return { label: item?.name, value: item?.name };
                          })
                          : []
                      }
                      singleSelect={true}
                    />
                    {Object.entries(selectedTagData).length > 0 && (selectedTagData?.type?.toString().toLowerCase() !== "default" ? <MultiSelect
                      placeholder="Select Value"
                      className="mb-2"
                      defaultValue={tagValue}
                      customValue={true}
                      onChange={(event) => {
                        let selectedData = Object.entries(selectedTagData).length > 0 && selectedTagData?.default_options?.find(item => item !== event);
                        if (selectedData) {
                          setTagValue(event);
                        } else if (selectedTagData.allow_custom === true && selectedData === undefined) {
                          let checkDuplicate = selectedTagData?.default_options?.find((item) => item.toString().toLowerCase() !== event.toString().toLowerCase());
                          if (checkDuplicate === undefined) {
                            setSelectedTagData(prevState => ({
                              ...prevState,
                              default_options: [...prevState.default_options, event],
                            }));
                          }
                        }
                      }}
                      options={
                        selectedTagData.default_options !== undefined &&
                        selectedTagData?.default_options.length > 0 && 
                        selectedTagData?.default_options.map(item => {
                          return { label: item, value: item }
                        })
                      }
                      singleSelect={selectedTagData.type.toString().toLowerCase() === "multi" ? false : true}
                    /> : 
                    <input type="text" className="form-control form-control-md mb-2" maxLength="10" placeholder="Enter Here" onChange={(event) => { setTagValue(event.target.value); }} value={tagValue} />
                    )}
                    <button type="button" className="btn btn-primary btn-md font-weight-medium" disabled={tagKey.trim().length === 0 || tagValue.length === 0} onClick={() => {
                      //not an existing tag?
                      var tags = tagInput.params.row.Tags ? [...tagInput.params.row.Tags] : [];
                      var existingKey = tags.filter(obj => {
                        return obj.key === tagKey
                      });
                      if (existingKey.length !== 0) {
                        setShowModalMsg(`Existing Tag ${tagKey}, delete first`);
                        setShowModal(true);
                      } else {
                        setTagsUpdated(true);
                        tags.unshift({ "key": tagKey, "value": tagValue });
                        setTagInput({ "params": { "row": tagInput.params.row, "Tags": tags } });
                      }
                      setTagKey("");
                      setTagValue("");
                      //set in state
                    }}>Add Tag</button>
                    <button type="button" className="btn btn-primary btn-md font-weight-medium ml-2 mr-2" disabled={tagsUpdated === false} onClick={() => {
                      //save to db
                      updateDocumentTags(tagInput.params.row.document_id, jsonToDb(tagInput.params.Tags));
                      var added = [], removed = [];
                      if (tagInput.params.row.Tags !== null) {
                        tagInput.params.row.Tags.forEach(function (item) {
                          if (tagInput.params.Tags.indexOf(item) === -1) {
                            removed.push(item);
                          }
                        });
                      }
                      if (tagInput.params.row.Tags !== null) {
                        tagInput.params.Tags.forEach(function (item) {
                          if (tagInput.params.row.Tags.indexOf(item) === -1) {
                            added.push(item);
                          }
                        });
                      } else {
                        added = added.concat(tagInput.params.Tags);
                      }
                      var document = tagInput.params.row;
                      for (var i = 0; i < removed.length; i++) {
                        logAudit(document.document_id, document.submissionIdentifier, "Submission", "Deleted Tag", "UI", removed[i].key, removed[i].value).then((data) => { });
                      }
                      for (var m = 0; m < added.length; m++) {
                        logAudit(document.document_id, document.submissionIdentifier, "Submission", "Created Tag", "UI", added[m].key, added[m].value).then((data) => { });
                      }
                      tagInput.params.row.Tags = tagInput.params.Tags;
                      setTagsUpdated(false);
                    }}>Save</button>
                    <button type="button" className="btn btn-primary btn-md font-weight-medium" onClick={async() => { 
                      setShowTagWindow(false);
                      setTagKey("");
                      setTagValue("");
                      await fetchTagsValues();
                    }}>Close</button>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
          </Modal.Body>
          <div className="modal-footer">
          </div>
        </Modal>
        {/* End Modal */}
        {/* submissionModal */}
        {(userRole === 'MEA_USERS' || userRole === 'MEA_CORPORATE' || userRole === 'MEA_ADMIN') &&
          <Modal
            className="block-example border border-dark"
            size="lg"
            show={submissionModal}
            centered
            onHide={() => setSubmissionModal(false)}
            keyboard={false}
            backdrop="static"
          >
            <Modal.Header className="d-block pt-1 pl-2 pr-2">
              <div className="d-flex justify-content-end">
                <span
                  aria-hidden="true"
                  title="Close"
                  id="newSubmissionCloseBtn"
                  className="hover-cursor"
                  onClick={() => {
                    setSubmissionModal(false);
                    setSubmissionIdentifier('');
                    setSelectedFileName('');
                    setEnableTemplate(false);
                  }}
                >
                  ×
                </span>
                <span className="sr-only">Close</span>
              </div>
            </Modal.Header>
            <Modal.Body className="pt-2">
              {disableUpload
                ? <Form.Group className="mb-0">
                  <label>File upload disabled</label>
                </Form.Group>
                : <Form.Group className="mb-0">
                  <Row>
                    <Col md="9" sm="12">
                      <input type="text" className="form-control form-control-md subid-fld" id="submissionIdentifierInput" placeholder={showClaims ? "Claim Identifier" : "Submission Identifier"} onChange={(event) => {
                        setSelectedGroup('');
                        setTemplateName('');
                        setSubmissionIdentifier(event.target.value);
                      }} />
                      {submissionIdentifier !== '' && selectedFileName.length > 0 && <label htmlFor="submission-files-input" className="fs-12 mb-0"> {selectedFileName[0]?.name.length > 60
                        ? <><TiTick size={18} color={"#71c016"} /> <span data-bs-toggle="tooltip" data-bs-placement="bottom" title={selectedFileName[0]?.name}>{selectedFileName[0]?.name.split('.').slice(0, -1).join('.').slice(0, 60) + '....' + selectedFileName[0]?.name.split('.').pop()}</span></>
                        : <><TiTick size={18} color={"#71c016"} /> {selectedFileName[0]?.name}</>
                      }
                      </label>
                      }
                      {submissionIdentifier !== '' && selectedFileName.length > 1 && showFileNames()}
                    </Col>
                    <Col md="3" sm="12">
                      <Form.Control
                        className="file-input__input btn btn-primary btn-md font-weight-medium"
                        placeholder="Select Files"
                        type="file"
                        id="submission-files-input"
                        accept={allowedExtensions}
                        multiple={(userRole === 'MEA_CORPORATE' || userRole === 'MEA_ADMIN')}
                        disabled={submissionIdentifier.trim().length === 0}
                        size="md"
                        onChange={(e) => {
                          onChange(e, false);
                        }}
                      ></Form.Control>
                      <div className="d-flex justify-content-end align-items-center">
                        <span><label disabled={submissionIdentifier.trim().length === 0} className={"btn btn-primary btn-md font-weight-medium mb-0"} htmlFor="submission-files-input">Select Files</label></span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="9" sm="9">
                      {enableTemplate && (templateOptions?.length > 0) &&
                        <Row>
                          <Col lg="3" md="4" sm="4" className="mt-4 pr-0">
                            <h5>Select a Template</h5>
                          </Col>
                          <Col lg="9" md="8" sm="8" className="mt-3 pl-1">
                            <select className="form-control form-control-md selectTemplate" id="selectaTemplate" value={templateName} onChange={(event) => {
                              setTemplateName(event.target.value);
                            }}>
                              {mapTemplateOptions().map((template, index) => (
                                <option key={template.TemplatePath}>{template.TemplateName}</option>
                              ))}
                            </select>
                          </Col>
                        </Row>
                      }
                      {enableTemplate && !(filteredUserGroups()?.length === 1 && (filteredUserGroups()?.map((group, index) => group?.GroupName)[0].toLowerCase() === "default")) && (
                        <Row>
                          <Col md="3" sm="4" className="mt-4 pr-0">
                            <h5>Select a Group</h5>
                          </Col>
                          <Col md="9" sm="8" className="mt-3 pl-1">
                            <select
                              className="form-control form-control-md selectGroup"
                              id="selectaGroup"
                              value={selectedGroup}
                              onChange={(event) => setSelectedGroup(event.target.value)}
                            >
                              {filteredUserGroups().map((group, index) => (
                                <option key={index} value={group.group_id}>{group.GroupName}</option>
                              ))}
                            </select>
                          </Col>
                        </Row>
                      )}
                    </Col>
                    <Col md="3" sm="3" className="mt-3 text-right btn-search-refresh btn-upload-submission">
                      {disableUpload
                        ? <button type="button" className="btn btn-primary btn-md font-weight-medium" >Upload Disabled</button>
                        : <button type="button" id="upload-submission-btn" className="btn btn-primary btn-md btn-reduce" disabled={(isInvalidSubmissionId(submissionIdentifier)) || disallowSubmission(formData.file, formData.files) || filteredUserGroups().length === 0} onClick={createDocuments}>Upload Files</button>
                      }
                    </Col>
                  </Row>
                  {/* } */}
                </Form.Group>
              }
            </Modal.Body>
          </Modal>
        }

        {/* Static New Submission Modal for data tour */}
        {tourNewSubmissionVisible && (currentStep === 1 || currentStep === 2 || currentStep === 3 || currentStep === 4) &&
          <div className="newsubmission-static">
            <div className="block-example">
              <div className="d-block pt-1 pl-2 pr-2">
                <div className="d-flex justify-content-end">
                  <span
                    aria-hidden="true"
                    title="Close"
                    className="hover-cursor">
                    ×
                  </span>
                  <span className="sr-only">Close</span>
                </div>
              </div>
              <div className="pt-2">
                {disableUpload
                  ? <Form.Group className="mb-0">
                    <label>File upload disabled</label>
                  </Form.Group>
                  :
                  <Form.Group className="mb-0">
                    <Row>
                      <Col md="9" sm="12" data-tour="step-2">
                        <input type="text" className="form-control form-control-md subid-fld" id="submissionIdentifierInput" placeholder={`${showClaims ? "claim Identifier" : "submission Identifier"}`} disabled />
                      </Col>
                      <Col md="3" sm="12">
                        <Form.Control
                          className="file-input__input btn btn-primary btn-md font-weight-medium"
                          placeholder="Select Files"
                        ></Form.Control>
                        <div className="d-flex justify-content-end align-items-center">
                          <span><label disabled className={"btn btn-primary btn-md font-weight-medium mb-0"} data-tour="step-3">Select Files</label></span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="9" sm="9">
                        <Row data-tour="step-4">
                          <Col lg="3" md="4" sm="4" className="mt-4 pr-0">
                            <h5>Select a Template</h5>
                          </Col>
                          <Col lg="9" md="8" sm="8" className="mt-3 pl-1">
                            <select className="form-control form-control-md selectTemplate" disabled>
                              <option>Default Template</option>
                            </select>
                          </Col>
                        </Row>
                        <Row data-tour="step-5">
                        <Col md="3" sm="4" className="mt-4 pr-0">
                          <h5>Select a Group</h5>
                        </Col>
                        <Col md="9" sm="8" className="mt-3 pl-1">
                          <select className="form-control form-control-md selectTemplate" disabled>
                            <option>Default</option>
                          </select>
                        </Col>
                        </Row>
                      </Col>
                      <Col md="3" sm="3" className="mt-3 text-right btn-search-refresh btn-upload-submission">
                        <button type="button" className="btn btn-primary btn-md btn-reduce" disabled data-tour="step-6">Upload Files</button>
                      </Col>
                    </Row>
                  </Form.Group>
                }
              </div>
            </div>
          </div>
        }
        <Suspense fallback={<div>Loading...</div>}>
          <QATool document={documentObj.document} launchQATool={launchQATool} setLaunchQATool={setLaunchQATool} fetchDocuments={fetchDocuments} qaToolDocs={qaToolDocs} setLoading={setLoading} templateControllerList={templateControllerList} isArchived={isArchived} />
        </Suspense>
        
        <Suspense fallback={<div>Loading...</div>}>
          <DiffViewer document={documentObj.document} launchDiffViewer={launchDiffViewer} setLaunchDiffViewer={setLaunchDiffViewer} setShowModal={setShowModal} setShowModalMsg={setShowModalMsg} setLoading={setLoading} />
        </Suspense>

        <Suspense fallback={<div>Loading...</div>}>
          {/* EXTRACTTOOL MARKER CODE START */}
          <ExtractTool
            launchExtractTool={launchExtractTool}
            setLaunchExtractTool={setLaunchExtractTool}
            document={submissionDetail}
            submissionDocList={submissionDocList}
            extractValue={extractValue}
            handleRefresh={handleRefresh}
            setExtractToolClose={setExtractToolClose}
            fetchDocuments={fetchDocuments}
            platform={true}
            isArchived={isArchived}
            userRole={userRole}
            xlType={xlType}
            setXlType={setXlType}
          />
          {/* EXTRACTTOOL MARKER CODE END */}
        </Suspense>
        
      </Container>
    </>
  );
}

export default SubmissionsList;