import React from "react";
import { Amplify } from 'aws-amplify';
import { useState, useEffect } from "react";
import { Hub, Auth } from "aws-amplify";
import { useLocation, useHistory } from "react-router-dom";
import {
  Modal,
  Button
} from "react-bootstrap";
import Marquee from 'react-css-marquee';
import QRCodeSVG from 'qrcode.react';

import config from '../aws-exports';
import { logAudit, isEmailChar, validateEmail, setUserVault, invokeLambdaWithRetry } from '../services/Helper';
import logo from '../assets/images/logo.png';
import microsoft from '../assets/images/microsoft-logo.png';
import "../assets/css/style.css";
import "../assets/vendors/ti-icons/css/themify-icons.css";
import "../assets/vendors/base/vendor.bundle.base.css";
import CirclesLoader from "../layouts/CirclesLoader";
import { RiCloseLine, RiUserUnfollowLine } from "react-icons/ri";

var CryptoJS = require("crypto-js");

const queryString = window.location.search;
const queryParam = new URLSearchParams(queryString);
const errorMsg = queryParam.get('error_description');
var ampCfg = {
  Auth: {
    region: config.aws_project_region,
    identityPoolId: config.aws_cognito_identity_pool_id,
    userPoolId: config.aws_user_pools_id,
    userPoolWebClientId: config.aws_user_pools_web_client_id,
    oauth: {
      domain: process.env.REACT_APP_MEA_OKTA_DOMAIN === undefined ? process.env.REACT_APP_MEA_SSO_DOMAIN : process.env.REACT_APP_MEA_OKTA_DOMAIN,
      scope: ["email", "phone", "openid", "aws.cognito.signin.user.admin", "profile"],
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
      responseType: "code"
    }
  },
  Storage: {
    AWSS3: {
      bucket: config.aws_user_files_s3_bucket, // (required) -  Amazon S3 bucket name
      region: config.aws_project_region // (optional) -  Amazon service region
    }
  },
  "aws_appsync_graphqlEndpoint": config.aws_appsync_graphqlEndpoint,
  "aws_appsync_region": config.aws_project_region,
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": config.aws_appsync_apiKey
};
Amplify.configure(ampCfg);

function Start(props) {
  //console.log(props);

  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [showPassword, setShowPassword] = useState(false);
  let [authType, setAuthType] = useState("mea");
  let [showModal, setShowModal] = useState(false);
  let [showModalMsg, setShowModalMsg] = useState("");
  const [token, setToken] = useState(null);
  const [disableUpload, setDisableUpload] = React.useState(false); // by default disableUpload is set to true, only when set to true users will not be able to upload file
  const history = useHistory();
  let [showQRCode, setShowQRCode] = useState(false);
  let [qrCode, setQRCode] = useState("");
  let [username, setUsername] = useState("");
  let [showTotpCode, setShowTotpCode] = useState(false);
  let [totpCode, setTotpCode] = useState("");
  let [challengeName, setChallengeName] = useState("");
  let [userObj, setUserObj] = useState();
  const [showUsageInstruction, setShowUsageInstruction] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isFedDirect, setIsFedDirect] = useState(true);
  const [federatedButtonLabel, setFederatedButtonLabel] = useState("");
  const [showMicrosftBtn, setShowMicrosftBtn] = useState(false);
  var counter = 0;
  const location = useLocation();

  function getToken() {
    return Auth.currentSession()
      .then(session => session)
      .catch(err => console.log(err));
  }

  var pendingUser = props.pendingUser;

  useEffect(() => {
    if (errorMsg) {
      setShowModal(true);
      setShowModalMsg(errorMsg)
    }
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          setToken("grating...");
          getToken().then(userToken => setToken(userToken.idToken.jwtToken));
          break;
        case "signOut":
          setToken(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          if (data.message === "User+is+not+enabled") {
            setShowModal(true)
            setShowModalMsg("Your User ID is pending approval, please contact mea support for approving your userid");
          } else if (data.message === "User+is+not+assigned+to+the+client+application.%3B+error%3Daccess_denied") {
            setShowModal(true)
            setShowModalMsg("Your User ID is not granted access to mea platform, please contact your IT support for granting access to mea platform");
          }
          console.log("Sign in failure", data);
          break;
        default:
      }
    });
    checkUploadStatus();
    loadAuthType();
    loadUsageInstruction();
    changeFedeartedButtonLabel();
  }, []);

  useEffect(() => {
    if (pendingUser === true) {
      setShowModal(true);
      setShowModalMsg('Your User ID is pending approval, please contact mea support for approving your userid');
    }
  }, [pendingUser])

  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_MICROSOFT === "true") {
      setShowMicrosftBtn(true);
    }
  }, [showMicrosftBtn])

  async function getConfigValue(configName) {
    try {
      const body = {
        "target": "getConfigValue",
        configName: configName
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      return data.response;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  useEffect(() => {
    (async function init() {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const inviteId = urlParams.get('inviteid');
        if (inviteId !== null) {
          await getAcceptInvite(inviteId);
        }
      } catch (error) {
        console.error('Error processing invite:', error);
      }
    })();
  }, []);

  const getAcceptInvite = async(userSub) => {
    setLoading(true);
    try {    

      const body = {
        "target": "acceptinvite",
        user_sub: userSub
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      return { status: data.statusCode === 200, result: data };
    } catch (err) {
      console.error('err: ', err);
      return { status: false, error: err };
    } finally {
      setLoading(false);
    }
  }

  var authTypeVal = undefined;
  async function loadAuthType() {
    if (authTypeVal === undefined) {
      authTypeVal = await getConfigValue("auth_type");
    }
    setAuthType(authTypeVal);
    if(authTypeVal === "client") {
      if(location.search?.toLowerCase().startsWith("?fed")) {
        try {
          await Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
          });
        } catch {
          localStorage.setItem("navigator", location.pathname);
          setIsFedDirect(true);
          handleFedLogin();
        }
      } else {
        setIsFedDirect(false);
      }
    } else {
      setIsFedDirect(false);
    } 
    return authTypeVal;
  }

  var showUsageInstructionVal = undefined;
  async function loadUsageInstruction() {
    if (authTypeVal === undefined) {
      showUsageInstructionVal = await getConfigValue("ShowUsageInstruction");
    }
    setShowUsageInstruction(showUsageInstructionVal);
    return showUsageInstructionVal;
  }

  var federatedButtonLabelVal = undefined;
  async function changeFedeartedButtonLabel() {
    if (federatedButtonLabelVal === undefined) {
      federatedButtonLabelVal = await getConfigValue("FederatedButtonLabel");
    }
    setFederatedButtonLabel(federatedButtonLabelVal);
    return federatedButtonLabelVal;
  }

  var disableUploadVal = undefined;
  var disableUploadValTs;
  function isCacheValid(cacheValTs) {
    if (cacheValTs === undefined) {
      return false;
    }
    const now = new Date();
    const msBetweenDates = Math.abs(cacheValTs.getTime() - now.getTime());
    const secBetweenDates = msBetweenDates / 1000;
    if (secBetweenDates > 300) {
      return false;
    }
    return true;
  }

  async function checkUploadStatus() {
    if (disableUploadVal === undefined || (isCacheValid(disableUploadValTs) === false)) {
      disableUploadVal = await getConfigValue("DisableUpload");
      disableUploadValTs = new Date();
    }
    setDisableUpload(disableUploadVal === 'true');
    return disableUploadVal === 'true';
  }

  let handleFedLogin = async function (event) {
    console.log(`handleFedLogin`);
    if(event !== undefined) {
      event.preventDefault();
      console.log(`handleFedLogin event`);
    }
    if(process.env.REACT_APP_MEA_SSO_DOMAIN !== undefined) {
      console.log(`handleFedLogin provider: "AzureAD"`);
      Auth.federatedSignIn({ provider: "AzureAD" }).then(() => {
      }).catch(e => {
        console.log(e)
      });
    } else {
      console.log(`handleFedLogin provider: "Okta"`);
      Auth.federatedSignIn({ provider: "Okta" }).then(() => {
      }).catch(e => {
        console.log(e)
      });
    }
  }

  async function loginRetry(fn, event, delay) {
    try {
      if (counter < 5) {
        counter += 1;
        await delayPromise(delay);
        var loginUser = await fn(event);
        return loginUser;
      }
    } catch (err) {
      console.error(err);
    }
    setShowModalMsg(
      "User is not verified or User is not commissioned for use. Contact mea system admin"
    );
  }

  async function delayPromise(delay) {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  let handleLogin = async function (event) {
    event.preventDefault();
    if (email.trim().length === 0)
      return;
    if (password.trim().length === 0)
      return;
    if (validateEmail(email) !== true)
      return;
    setLoading(true);
    var user;
    try {
      localStorage.setItem("transitions", "off");
      if (authType === "mea") {
        //attempt signout of all devices
        await Auth.signOut({ global: true });
      }
      await prepareUserVault(email, password);
      user = await Auth.signIn(email, password);
      logAudit(undefined, undefined, "User", "Login", "UI", undefined, undefined).then((data) => { });
      localStorage.removeItem("transitions");
      setUserObj(user);
      setChallengeName(user.challengeName);
      if (user.challengeName === "MFA_SETUP") {
        Auth.setupTOTP(user).then(async (code) => {
          setShowQRCode(true);
          setQRCode(code);
          setUsername(user.username);
          localStorage.setItem('mfaState','MFA_SETUP');
        });
      } else if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
        setShowTotpCode(true);
        localStorage.setItem('mfaState', 'SOFTWARE_TOKEN_MFA');
      } else {
        if (process.env.REACT_APP_MFANAME !== undefined) {
          setChallengeName("MFA_SETUP");
          Auth.setupTOTP(user).then(async (code) => {
            setShowQRCode(true);
            setQRCode(code);
            setUsername(user.username);
            localStorage.setItem('mfaState','MFA_SETUP');
          });
        }
        await setUserVault(password);
      }
      setLoading(false); // Login successful, stop the loader animation
    } catch (err) {
      if (err.message === 'User is disabled.')
        setShowModalMsg('Your registration is pending approval. You will receive an email once approved, this may take up to 24 hours.');
      else {
        if (err.message === "PreAuthentication invocation failed due to error Socket timeout while invoking Lambda function.") {
          await loginRetry(handleLogin, event, 5000);
        } else {
          setShowModalMsg(err.message);
        }
      }
      setShowModal(true);
      console.error(err);
      setLoading(false); // Login failed, stop the loader animation
    }
  }

  let onCompleteTotp = async function (event) {
    event.preventDefault();
    try {
      if (challengeName === "MFA_SETUP") {
        localStorage.setItem("mfasetupready", "true");
        var resp = await Auth.verifyTotpToken(userObj, totpCode);
        console.log(resp);
        Auth.setPreferredMFA(userObj, 'TOTP');
        await setUserVault(password, qrCode);
      } else if (challengeName === "SOFTWARE_TOKEN_MFA") {
        const loggedUser = await Auth.confirmSignIn(
          userObj, // Return object from Auth.signIn()
          totpCode, // Confirmation code
          challengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
        );
        setUserObj(loggedUser);
        var uInfo = loggedUser.signInUserSession.idToken.payload;
        await setUserVault(password, undefined, uInfo.given_name, uInfo.family_name, uInfo.phone_number, uInfo['custom:company'], uInfo['custom:country'], uInfo['cognito:groups'].join(','), uInfo.sub);
      }
      localStorage.removeItem('mfaState');
    } catch (err) {
      if (err.message === 'User is disabled.')
        setShowModalMsg('Your registration is pending approval. You will receive an email once approved, this may take up to 24 hours.');
      else
        setShowModalMsg(err.message);
      setShowModal(true);
      console.error(err);
    }
  }

  async function prepareUserVault() {
    try {
      const body = {
        "target": "prepareUserVault",
        UserId: email,
        passcode: password
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      console.log("prepareUserVault resp:", data);
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function onRegisterAuthenticator() {
    try {
      const body = {
        "target": "fetchUserVault",
        UserId: email,
        passcode: password
      };
      const init = {
        body: body
      };
      const result = await invokeLambdaWithRetry("instanceHandler", init);
      if (result.response !== undefined) {
        var bytes = CryptoJS.AES.decrypt(result.response.mfasetupcode, password);
        result.response.mfasetupcode = bytes.toString(CryptoJS.enc.Utf8);
        setQRCode(result.response.mfasetupcode);
        setShowQRCode(true);
        setUsername(userObj.username);
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  const allowEmailChars = e => {
    if (isEmailChar(e.key) === false) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  const redirectToRegister = () => {
    let path = `/register`;
    history.push(path);
  }

  const redirectToPwdReset = () => {
    let path = `/pwdreset`;
    history.push(path);
  }

  const validateUser = async(email) => {
    setLoading(true);
    try {
      localStorage.setItem("msUser", email);
      const body = {
        "target": "fetchUserVault",
        UserId: email,
        microsoft: true
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      if ((data?.response?.user_sub === "inactive" &&
        (data?.response?.Active === 0 || data?.response?.groups === "MEA_PENDINGUSERS")) ||
        (data?.response?.user_sub === null &&
          (data?.response?.Active === 1 || data?.response?.groups !== "MEA_PENDINGUSERS")) ||
        data?.response?.user_sub?.startsWith("invite-")
      ) {
        let path = `/microsoftauth`;
        history.push(path);
      } else if (data?.response?.user_sub &&
        data?.response?.Active === 1 &&
        data?.response?.groups !== "MEA_PENDINGUSERS") {
        console.log(`handleFedLogin provider: "AzureADOpen"`);
        Auth.federatedSignIn({ provider: "AzureADOpen" }).then(() => {
        }).catch(e => {
          console.log(e);
        });
      }
      return { status: data.statusCode === 200, result: data };
    } catch (err) {
      console.error('err: ', err);
      return { status: false, error: err };
    } finally {
      setLoading(false);
    }
  }

  const redirectToMicrosoftAuth = async() => {
    let checkMsUser = localStorage.getItem("msUser");
    const urlParams = new URLSearchParams(window.location.search);
    const inviteId = urlParams.get('inviteid');
    if (inviteId !== null) {
      console.log(`handleFedLogin provider: "AzureADOpen"`);
      Auth.federatedSignIn({ provider: "AzureADOpen" }).then(() => {
      }).catch(e => {
        console.log(e);
      });
    } else if (checkMsUser === null || checkMsUser !== null) {
      if (checkMsUser !== null) {
        await validateUser(checkMsUser);
      } else {
        let path = `/microsoftauth`;
        history.push(path);
      }
    }
  }

  return (
    <>
      <div className="container-scroller">
        {disableUpload &&
          <Marquee cssNamespace="horizontal" text="our AI is currently training itself on advanced insurance data and is unavailable for use. uploading new submissions is paused now, please try again later." />
        }
        {(showUsageInstruction === "true" || showUsageInstruction) &&
          <div className="usage-tourtext">
            <h4>how to use mea ingest module</h4>
            <ul className="pl-3 mb-1">
              <li>create an account and login</li>
              <li>upload submission documents for data extraction</li>
              <li>view extracted data on mea platform</li>
            </ul>
            <ul className="mb-1">
              <li>if you need help, please Click <b>Turn Tour On</b> in the submission screen for a step by step walk through or contact us on <a href="mailto:hello@meaplatform.com">hello@meaplatform.com</a></li>
            </ul>
          </div>
        }
        <div className="container-fluid page-body-wrapper full-page-wrapper d-flex align-items-center">
          <div className="content-wrapper auth-pages auth">
            <div className="auth-form-transparent">
              <div className="row mx-0">
                <div className="col-sm-6 d-flex justify-content-center">
                  <div className="login-form">
                  <h3>welcome</h3>
                  {isFedDirect === false &&
                    <form className="pt-3">
                      <div className="form-group" data-tour="step-1" >
                        <div className="input-group">
                          <input type="email" className="form-control form-control-sm border-left-0" id="inputEmail" readOnly={showQRCode === true} placeholder="email address" value={email} onKeyDown={allowEmailChars} onChange={(event) => { setEmail(event.target.value); }} />
                        </div>
                      </div>
                        {showQRCode === false && showTotpCode === false &&
                          <>
                            <div className="form-group pos-rel" data-tour="step-2" >
                              <div className="input-group mb-1">
                                <input type={showPassword ? "text" : "password"} className="form-control form-control-sm border-left-0" id="inputPassword" value={password} placeholder="password"
                                  onChange={(event) => { setPassword(event.target.value); }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault();
                                      handleLogin(e);
                                    }
                                  }} />
                              </div>
                              {password && <span className="show-pwd"><button className="btn p-0 auth-link text-black"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowPassword(!showPassword);
                                }}
                              >
                                {showPassword ? 'hide' : 'show'}
                              </button>
                              </span>}
                            </div>
                          </>
                        }

                      {showQRCode === false && showTotpCode === false && <><div className="my-2 d-flex justify-content-start align-items-center">
                        <button type="button" className="btn p-0 auth-link text-green" onClick={redirectToPwdReset}>forgot password?</button>
                      </div>
                        <div className="my-3 d-flex justify-content-center">
                          <button type="button" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" disabled={email.trim().length === 0 || password?.trim().length === 0} id="btnLogin" onClick={handleLogin}>login</button>
                        </div>
                        <div className="text-center mt-2 font-weight-light mt-5 mb-3">
                          <span className="txt-sm">don't have an account? </span>
                          <button type="button" className="btn p-0 text-primary" onClick={redirectToRegister}>create</button>
                        </div>
                        <div className="row login-microsoft mt-2">
                        {showMicrosftBtn &&
                        <div className="col-12 d-flex justify-content-center mb-3"><button type="button" className="btn btn-block btn-primary font-weight-medium btn-microsoft" id="btnMicrosoft" onClick={redirectToMicrosoftAuth}><img src={microsoft} alt="logo-microsoft" /> <b>continue with microsoft</b></button></div>
                        }
                        {/* <div className="col-12 d-flex justify-content-center mt-2"><button type="button" className="btn p-0 text-primary">need help?</button></div> */}
                      </div></>
                      }

                      {showQRCode === true && <><div className="form-group text-center">
                        <label htmlFor="exampleInputPassword">TOTP - setup qa code</label>
                        <div className="input-group d-flex justify-content-center">
                          <QRCodeSVG size={256} value={`otpauth://totp/AWSCognito:${username}?secret=${qrCode}&issuer=${process.env.REACT_APP_MFANAME}`} includeMargin={true} />
                        </div>
                      </div></>}

                      <div className="form-group">
                        {(showQRCode === true || showTotpCode === true) && <>
                          <div className="input-group">
                            <input type="text" maxLength={10} className="form-control form-control-md border-left-0 mr-2 input-totp" id="inputTotp" placeholder="enter TOTP" value={totpCode}
                              onChange={(event) => { setTotpCode(event.target.value); }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  onCompleteTotp(e);
                                }
                              }}
                            />
                            <button type="button" className="btn btn-primary" onClick={onCompleteTotp}>submit</button>
                          </div>
                        </>
                        }

                        {showTotpCode === true && <>
                          <div className="text-center mt-4 font-weight-light">
                            <button type="button" className="btn p-0 text-primary" onClick={onRegisterAuthenticator}>register</button>
                            <span className="txt-sm"> authenticator? </span>
                          </div>
                        </>}
                      </div>

                      {showQRCode !== true && (authType === "client") && <>
                        <div className="my-3 d-flex justify-content-center">
                          <button type="button" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" onClick={handleFedLogin} disabled={!((authType === "client") && (!email.toLowerCase().endsWith("@meaplatform.com")))}>{federatedButtonLabel}</button>
                        </div>
                      </>
                      }
                    </form>
                  }
                  </div>
                </div>
                <div className="col-sm-6 d-flex align-items-center justify-content-center">
                  <div className="brand-logo">
                    <img src={logo} alt="logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid readmea-txt">
          <div className="row">
            <div className="col-12 text-center font-weight-light" data-tour="step-3" >
              <span className="txt-md">read mea </span>
              <label className="form-check-label text-muted">
                <a href={process.env.PUBLIC_URL + '/docs/meaprivpol.pdf'} target="_blank" rel="noreferrer">privacy policies</a>
              </label>
              <span className="txt-md"> and </span>
              <label className="form-check-label text-muted">
                <a href={process.env.PUBLIC_URL + '/docs/meatac.pdf'} target="_blank" rel="noreferrer">terms and conditions</a>
              </label>
            </div>
            <div className="col-12">
              <p className="font-weight-medium text-center">&copy; mea platform limited {new Date().getFullYear()}.</p>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal-mini modal-primary new-modal"
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={false}
        centered={true}
      >
        <Modal.Header className="justify-content-end pt-3">
          <button className="closeicon" onClick={() => setShowModal(false)}><RiCloseLine /></button>
        </Modal.Header>
        <Modal.Body className="text-center pt-0 pb-3 px-3">
          <p className="identiy-icons"><RiUserUnfollowLine /></p>
          <p>{showModalMsg}</p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center pb-0">
          <p>powered by <span>mea platform</span></p>
        </Modal.Footer>
      </Modal>
      {/* Mini Modal */}
      <Modal
            className="modal-centre"
            backdrop="static"
            keyboard={false}
            show={isLoading}
            animation={false}
            centered
          >
            <Modal.Body className="text-center">
              <CirclesLoader isLoading={isLoading} />
            </Modal.Body>
          </Modal>
          {/* End Modal */}
    </>
  );
}

export default Start;
