import React, { useEffect, useState } from "react";
import {
    Container,
    Modal
} from "react-bootstrap";
import { Auth } from 'aws-amplify';

import { getParameterFromManager } from "../../services/Utils";
import { loadS3File, invokeLambdaWithRetry } from '../../services/Helper';

function DisplaySubmission({ displaySubmission, setDisplaySubmission, setLoading, submissionIdentifier }) {
    const [trgEntNameMapping, setTrgEntNameMapping] = useState({});
    const [markCompleteData, setMarkCompleteData] = useState({});

    useEffect(() => {
        async function loadDisplayData() {
            if(submissionIdentifier === "" || submissionIdentifier === undefined || displaySubmission === false)
                return;
            var mapp = await getParameterFromManager('TRIAGE_ENTITYNAME_MAPPING');
            setTrgEntNameMapping(mapp);
            var msg = await fetchMergeRecord(submissionIdentifier);
            if(msg && msg?.length === 1 && msg[0].jsonMarkCompletePath !== null) {
                var mcData = await loadS3File(msg[0].jsonMarkCompletePath);
                setMarkCompleteData(mcData);
            }
        }        
        loadDisplayData();
    }, [submissionIdentifier]);


    async function fetchMergeRecord(submissionIdentifier) {
        try {
            const user = await Auth.currentAuthenticatedUser({
                bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
            });

            const body = {
                "access_token": user.signInUserSession.idToken.jwtToken,
                "target": "fetchMergeRecord",
                "submissionIdentifier": submissionIdentifier
            };
            const init = {
                body: body
            };
            const resp = await invokeLambdaWithRetry("instanceHandler", init);
            return resp.response;
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    function closedisplaySubmission() {
        setDisplaySubmission(false);
    }

    function getDisplayData(keyPath) {
        if(submissionIdentifier === "" || submissionIdentifier === undefined || displaySubmission === false || Array.isArray(markCompleteData) === false)
            return "-";
        var keys = keyPath.split(":");
        var lookupDef = undefined;
        for(var i = 0; i < keys.length; i ++) {
            if(lookupDef === undefined) {
                lookupDef = trgEntNameMapping[keys[i]];
            } else {
                lookupDef = lookupDef[keys[i]];
            }
        }
        if(lookupDef === undefined) {
            lookupDef = [keys[keys.length - 1]];
        }
        var entityColIndex = markCompleteData[0][0].findIndex(column => {
            return column.value.toString().trim().toLowerCase() === "ENTITY NAME".toLowerCase();
        });
        var outputColIndexes = markCompleteData[0][0].reduce(function(ind, el, i) {
            if (el.value.toString().trim().toLowerCase().startsWith("output "))
                ind.push({index: i, col: el});
            return ind;
        }, []);
        outputColIndexes.sort(function(a, b) {
            var acv = a.col.value.toString().trim().toLowerCase();
            var bcv = b.col.value.toString().trim().toLowerCase();
            if ( acv < bcv ){
                return -1;
              }
              if ( acv > bcv ){
                return 1;
              }
              return 0;
        });
        for(var m = 0; m < lookupDef.length; m ++)  {
            for (var ecRow = 0; ecRow < markCompleteData[0].length; ecRow++) {
                if (markCompleteData[0][ecRow][entityColIndex].value !== undefined && markCompleteData[0][ecRow][entityColIndex].value.toString().trim().toLowerCase() === lookupDef[m].toString().trim().toLowerCase()) {
                    for(var n = 0; n < outputColIndexes.length; n ++)  {
                        var etValue = markCompleteData[0][ecRow][outputColIndexes[n].index].value;
                        if(etValue !== undefined && etValue !== null && etValue.toString().trim().length !== 0) {
                            return etValue;
                        }
                    }
                }
            }
        }
        return "-";
    }

    return (
        <>
            <Modal dialogClassName="modal-fullscreen displaysubmission" show={displaySubmission} fullscreen={"true"} backdrop="static" keyboard={false} animation={false}>
                <Modal.Title className="modal-title w-100 h4 mt-2">
                    <div className="row mx-0 mb-2">
                        <div className="col-lg-11 col-md-11 col-sm-11 col-10">
                            <h5 className="dsp-sub-hdg pl-2">Policy Detail</h5>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-1 col-2">
                            <button type="button" className="close" onClick={() => {
                                closedisplaySubmission()
                            }
                            }>
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">Close</span>
                            </button>
                        </div>
                    </div>
                </Modal.Title>
                <Modal.Body className="pt-1">
                    <Container fluid className="px-0 dsp-sub">
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                                        <h5>Account Name</h5>
                                        <p>{getDisplayData("Account Name")}</p>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-3 col-4">
                                        <h5>Underwriter Submission Reference</h5>
                                        <p>{submissionIdentifier}</p>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                                        <h5>Underwriter Name</h5>
                                        <p>{getDisplayData("Underwriter Name")}</p>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                                        <h5>Broker Reference</h5>
                                        <p>{getDisplayData("Broker Reference")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="party-details">
                            <h5 className="d-block">Party Details</h5>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-2">
                                    <label>Address Line 1</label>
                                    <p>{getDisplayData("Party Details:Address Line 1")}</p>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-2">
                                    <label>Address Line 2</label>
                                    <p>{getDisplayData("Party Details:Address Line 2")}</p>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-2">
                                    <label>Address Line 3</label>
                                    <p>{getDisplayData("Party Details:Address Line 3")}</p>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-2">
                                    <label>Postal / Zip Code</label>
                                    <p>{getDisplayData("Party Details:Postal / Zip Code")}</p>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-2">
                                    <label>Telephone Number</label>
                                    <p>{getDisplayData("Party Details:Telephone Number")}</p>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-2">
                                    <label>Key Contact</label>
                                    <p>{getDisplayData("Party Details:Key Contact")}</p>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-2">
                                    <label>Email Address</label>
                                    <p>{getDisplayData("Party Details:Email Address")}</p>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="party-details">
                            <h5 className="d-block">Policy Details</h5>
                            <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <label>Product</label>
                                            <p>{getDisplayData("Policy Details:Product")}</p>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <label>Policy Type</label>
                                            <p>{getDisplayData("Policy Details:Policy Type")}</p>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <label>Location</label>
                                            <p>{getDisplayData("Policy Details:Location")}</p>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <label>Period From</label>
                                            <p>{getDisplayData("Policy Details:Period From")}</p>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <label>Period To</label>
                                            <p>{getDisplayData("Policy Details:Period To")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                                    <div className="white-box">
                                        <div className="row">
                                            <div className="col-12 mb-2">
                                                <label>Policy Limits</label>
                                                <p>
                                                {getDisplayData("Policy Details:Policy Limits:Policy Limits")}
                                                <span className="sm-txt d-block">any one occurrence and in the annual aggregate in respect of Flood and Earthquake separately.</span>
                                                </p>
                                            </div>
                                            <hr></hr>
                                            <div className="col-10 offset-2 mb-2">
                                                <h5 className="d-block mb-2">Program Sub Limits Schedule</h5>
                                                <div className="row sub-limit-sch">
                                                    <div className="col-lg-2 col-md-2 col-sm-4 col-4">
                                                        <p className="grey-txt">Earthquake</p>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-8 col-8">
                                                        <p>{getDisplayData("Policy Details:Policy Limits:Program Sub Limits Schedule:Earthquake")}</p>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                                                        <p className="grey-txt">any one occurrence and in the annual aggregate</p>
                                                    </div>
                                                </div>
                                                <div className="row sub-limit-sch">
                                                    <div className="col-lg-2 col-md-2 col-sm-4 col-4">
                                                        <p className="grey-txt">Flood</p>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-8 col-8">
                                                        <p>{getDisplayData("Policy Details:Policy Limits:Program Sub Limits Schedule:Flood")}</p>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                                                        <p className="grey-txt">any one occurrence and in the annual aggregate</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default DisplaySubmission;