import UserProfile from "views/UserProfile.js";
import SubmissionsList from "views/SubmissionsList.js";
import Triage from "views/Triage.js";
import Management from "views/Management.js";
import Settings from "views/Settings.js";

const dashboardRoutes = [
  [  
    {
      path: "/submissions",
      name: "Submissions",
      component: SubmissionsList,
      layout: "/user",
    },
    {
      path: "/claims",
      name: "Claims",
      component: SubmissionsList,
      layout: "/user",
    },
    {
      path: "/triage",
      name: "Triage",
      component: Triage,
      layout: "/user",
    },
    {
      path: "/profile",
      name: "User Profile",
      component: UserProfile,
      layout: "/user",
    }
  ],
  [
    {
      path: "/submissions",
      name: "Submissions",
      component: SubmissionsList,
      layout: "/user",
    },
    {
      path: "/claims",
      name: "Claims",
      component: SubmissionsList,
      layout: "/user",
    },
    {
      path: "/triage",
      name: "Triage",
      component: Triage,
      layout: "/user",
    },
    {
      path: "/profile",
      name: "User Profile",
      component: UserProfile,
      layout: "/user",
    },
    {
      separator: true
    },
    {
      path: "/management",
      name: "Management",
      component: Management,
      layout: "/user",
    },
    {
      path: "/settings",
      name: "Settings",
      component: Settings,
      layout: "/user",
    }
  ]  
];

export default dashboardRoutes;
