const playsteps = {
  "submissions" : [
    {
      selector: '[data-tour="step-1"]',
      content: "Click here to create a new submission."
    },
    {
      selector: '[data-tour="step-2"]',
      content: "Add a reference name for your submission document."
    },
    {
      selector: '[data-tour="step-3"]',
      content: "Choose a submission document to upload."
    },
    {
      selector: '[data-tour="step-4"]',
      content: "Click here to select a template."
    },
    {
      selector: '[data-tour="step-5"]',
      content: "Click here to select the group."
    },
    {
      selector: '[data-tour="step-6"]',
      content: "Click here to upload your submission document."
    },
    {
      selector: '[data-tour="step-7"]',
      content: "Click here to see the processing status of your submission document extraction or wait for an email to your registered account letting you know your extraction is complete."
    },
    {
      selector: '[data-tour="step-8"]',
      content: "Expand your reference name to see your extracted document progress."
    },
    {
      selector: '[data-tour="step-9"]',
      content: "Click on the Quality Assurance tool icon to view your extracted data and original document in the quality assurance tool."
    }
  ],
  "qatool" : [
    {
      selector: '[data-tour="step-10"]',
      content: "Your submission document."
    },
    {
      selector: '[data-tour="step-11"]',
      content: "Zoom in/out and rotate left/right."
    },
    {
      selector: '[data-tour="step-12"]',
      content: "Data that has been extracted from your document into a structured form."
    },
    {
      selector: '[data-tour="step-13"]',
      content: "Add or delete rows/columns."
    }
  ]
};

export default playsteps;