import React, { useEffect } from "react";
import { useState } from "react";

function CirclesLoader({ isLoading }) {
  const [circles, setCircles] = useState([0, 1, 2, 3, 4]);

  useEffect(() => {
    setCircles((prevCircles) => {
      const newCircles = [...prevCircles];
      const first = newCircles.shift(); // Remove the first element
      newCircles.push(first);           // Add it to the end
      return newCircles;
    });

    const interval = setInterval(() => {
      setCircles((prevCircles) => {
        const newCircles = [...prevCircles];
        const first = newCircles.shift(); // Remove the first element
        newCircles.push(first);           // Add it to the end
        return newCircles;
      });
    }, 1000); // Adjust the timing as needed

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loader-container">
          {circles.map((circle, index) => (
            <div key={index} className={`circle circle-${circle}`} />
          ))}
        </div>
      )}
    </>
  );
}

export default CirclesLoader;