import React from "react";
import { useLocation, Route, Switch } from "react-router-dom";
import { Auth } from 'aws-amplify';

import Header from "components/Navbars/Header";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import { getParameterFromManager } from "../services/Utils";
import { signOutUser } from '../services/Helper';

import routes from "routes.js";
import SubmissionsList from "views/SubmissionsList.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function AuthenticatedLayout() {
  const location = useLocation();
  const history = useHistory();
  const mainPanel = React.useRef(null);
  let [isAdmin, setIsAdmin] = React.useState(false);
  let [enableTriage, setEnableTriage] = React.useState(false);
  const [submissionModal, setSubmissionModal] = React.useState(false);
  //for the showClaimsUI
  const [showClaims, setshowClaims] = React.useState(false);

  const getRoutes = (routes) => {
    var isAuthUser = false;
    for(var i = 0; i < routes.length; i++) {
      if (routes[i].layout === "/user" && routes[i].path !== '/undefined') {
        isAuthUser = true;
      }
    }
    if(isAuthUser === true) {
      routes.push({
        path: "/submissions/qatool",
        name: "QA Tool",
        component: SubmissionsList,
        layout: "/user",
      });
    }

    var rts = routes.map((prop, key) => {
      if (prop.layout === "/user" && prop.path !== '/undefined') {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} submissionModal={submissionModal} setSubmissionModal={setSubmissionModal} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
    return rts;
  };
  
  React.useEffect(() => {
    async function checkUserAnAdmin() {
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const groups = user.signInUserSession.idToken.payload["cognito:groups"];
        if (groups !== undefined) {
          if (groups.includes('MEA_ADMIN'))
            setIsAdmin(true);
          else 
            setIsAdmin(false);
        }
        const enableTriagePS = await getParameterFromManager("ENABLE_TRIAGE");
        setEnableTriage(enableTriagePS);

      }
      catch (err) {
        if (err === "The user is not authenticated") {
          signOutUser();
        }
      }
    }    
    checkUserAnAdmin();
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);

  React.useEffect(() => {
    (async function initF() {
      let showClaimsPS = await getParameterFromManager("SHOW_CLAIMS_UI");
      setshowClaims(showClaimsPS);
    })();
  });

  React.useEffect(() => {
    (async function init() {
    if (showClaims === true && (location.pathname === "/user/submissions" || location.pathname === "/user/claims")) {
      history.push('/user/claims')
    } else if (showClaims === false && (location.pathname === "/user/submissions" || location.pathname === "/user/claims")) {
      history.push('/user/submissions')
    }else{
      history.push(location.pathname)
    }
    })();
  }, [showClaims])
  
  function getRouteMap(ebAdmin, enableTriage, showClaims) {
    var routesToUse;
    if(ebAdmin) {
      routesToUse = [...routes[1]];
    } else {
      routesToUse = [...routes[0]];
    }
    if(enableTriage === false) {
      var colTriageIndex = routesToUse.findIndex(route => {
        return route.name === "Triage";
      });
      if(colTriageIndex !== -1) {
        routesToUse.splice(colTriageIndex, 1);
      }
    }
    if (showClaims === true) {
      var colSubmissionIndex = routesToUse.findIndex(route => {
        return route.name === "Submissions";
      });
      if (colSubmissionIndex !== -1) {
        routesToUse.splice(colSubmissionIndex, 1);
      }
    } 
    else if (showClaims === false) {
      var colClaimsIndex = routesToUse.findIndex(route => {
        return route.name === "Claims";
      });
      if (colClaimsIndex !== -1) {
        routesToUse.splice(colClaimsIndex, 1);
      }
    }
    return routesToUse;
  }

  return (
    <>
      <div className="wrapper">
        <Sidebar color="black" routes={getRouteMap(isAdmin, enableTriage, showClaims)} />
        <div className="main-panel" ref={mainPanel}>
          <Header submissionModal={submissionModal} setSubmissionModal={setSubmissionModal} />
          <div className="content p-0">
            <Switch>{getRoutes(getRouteMap(isAdmin, enableTriage, showClaims))}</Switch>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default AuthenticatedLayout;