import { SSMClient, GetParametersCommand } from "@aws-sdk/client-ssm";
import { Auth } from 'aws-amplify';
import config from '../aws-exports';
import { Mutex } from 'async-mutex';

let clientLock = new Mutex();

const paramsNeeded = [
    "DISPLAY_FMT_QA_BTN",
    "DISPLAY_SEND_QA_DATA",
    "CONFIDENCESCORE_ALIASMAP",
    "EXTRACT_MSG_FILE",
    "LOB_CODES_VALIDATION",
    "USE_TEMPLATE",
    "ENABLE_PAYMENT",
    "MEA_VALIDATIONS_CHECK",
    "ANTIVIRUSSCAN_SUBMISSIONS",
    "ANTIVIRUSSCAN_OTHERS",
    "DISPLAY_FMT_QA_BTN",
    "DISPLAY_SEND_QA_DATA",
    "NOTIFY_SUBMISSION_PROCESSED",
    "USE_ADOBE_FOR_MERGE",
    "USE_ADOBE_FOR_MERGE_FAILURES",
    "USE_EB_FOR_NOTIFICATIONS",
    "ENABLE_COUNTRY_CODE_VALIDATION",
    "ENVIRONMENT_DETAILS",
    "MEAPLATFORM_RELEASE_VERSION",
    "MEAPLATFORM_BUILD_VERSION",
    "ENABLE_QA",
    "ENABLE_TOUR",
    "ENABLE_USER_DATAVALIDATIONS",
    "ENABLE_TRIAGE",
    "TRIAGE_ENTITYNAME_MAPPING",
    "ENABLE_QA_ROW_BUTTONS",
    "SUBMISSION_REJECTION_REASONS",
    "MEA_TIMEZONE",
    "SHAREPOINT_ROOT_PATH",
    "TEMPLATE_CONTROLLER",
    "OCR_EXTRACT_METHOD",
    "SHOW_NEW_QA_TOOL",
    "AI_FLOW_CONTROLLER",
    "SUPPORT_MULTI_EDIT",
    "SEND_QA_DATA_CFG",
    "SHOW_CLAIMS_UI",
    "SOV_LOSSRUN_TRANSFORM_CFG",
    "AUTOCOMPLETE_CFG",
    "COPY_FILE_S3",
    "USER_GROUPNAME_CONFIG",
    "TAGS_META_INFO"
];

const paramsDirectNeeded = [
];

const noParse = [
    "MEAPLATFORM_RELEASE_VERSION",
    "MEAPLATFORM_BUILD_VERSION",
];

var paramsLoaded;
var paramsLoadedTs;

const getParams = async (params, credentials, region) => {
    const parameterStore = new SSMClient({
        apiVersion: '2014-11-06',
        region: region,
        credentials: credentials
    });
    try {
        const data = await parameterStore.send(
            new GetParametersCommand({
                Names: params
            })
        );
        return data.Parameters;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

function isCacheValid() {
    if (paramsLoadedTs === undefined) {
        return false;
    }
    const now = new Date();
    const msBetweenDates = Math.abs(paramsLoadedTs.getTime() - now.getTime());
    const secBetweenDates = msBetweenDates / 1000;
    if (secBetweenDates > 300) {
        return false;
    }
    return true;
}

export async function getParameterFromManager(paramName) {
    var backend = config.aws_user_files_s3_bucket.split('-');
    var env = backend[backend.length - 1];
    if ((paramsLoaded === undefined) || (isCacheValid() === false)) {
        paramsLoaded = undefined;
        let release = await clientLock.acquire();
        var credentials = await Auth.currentCredentials();
        var params = Array.from(paramsNeeded, x => `${x}-${env}`).concat(paramsDirectNeeded);
        var chunkParams = sliceIntoChunks(params, 10);
        for (var i = 0; i < chunkParams.length; i++) {
            var paramsLoadedResp = await getParams(chunkParams[i], credentials, config.aws_project_region);
            if (paramsLoaded === undefined) {
                paramsLoaded = paramsLoadedResp;
            } else {
                paramsLoaded = paramsLoaded.concat(paramsLoadedResp);
            }
        }
        paramsLoadedTs = new Date();
        release();
    }
    var existParam = paramsLoaded.filter(obj => {
        return obj.Name === `${paramName}-${env}`;
    });
    if (existParam !== undefined && existParam.length > 0) {
        if (noParse.includes(paramName)) {
            return existParam[0].Value;
        } else {
            return JSON.parse(existParam[0].Value);
        }
    } else {
        existParam = paramsLoaded.filter(obj => {
            return obj.Name === `${paramName}`;
        });
        if (existParam !== undefined && existParam.length > 0) {
            if (noParse.includes(paramName)) {
                return existParam[0].Value;
            } else {
                return JSON.parse(existParam[0].Value);
            }
        }
    }
    console.log(`paramName: ${paramName} is not found`);
    return null;
}

export async function updateParameterInManager(paramName, newValue) {
    var backend = config.aws_user_files_s3_bucket.split('-');
    var env = backend[backend.length - 1];
    if (paramsLoaded === undefined)
        return;
    var existParam = paramsLoaded.filter(obj => {
        return obj.Name === `${paramName}-${env}`;
    });
    if (existParam !== undefined && existParam.length > 0) {
        if (noParse.includes(paramName)) {
            existParam[0].Value = newValue;
            console.log(`paramName: ${paramName} updated in store`);
            return;
        } else {
            existParam[0].Value = JSON.parse(newValue);
            console.log(`paramName: ${paramName} updated in store`);
            return;
        }
    } else {
        existParam = paramsLoaded.filter(obj => {
            return obj.Name === `${paramName}`;
        });
        if (existParam !== undefined && existParam.length > 0) {
            if (noParse.includes(paramName)) {
                existParam[0].Value = newValue;
                console.log(`paramName: ${paramName} updated in store`);
                return;
            } else {
                existParam[0].Value = JSON.parse(newValue);
                console.log(`paramName: ${paramName} updated in store`);
                return;
            }
        }
    }
    console.log(`paramName: ${paramName} is not found`);
    return null;
}
