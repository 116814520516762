import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import './index.css';

const PopUpModal = ({
  show: initialShow = false,
  handleClose: externalHandleClose,
  title: initialTitle = "",
  body: initialBody = "",
  primaryAction: initialPrimaryAction = { text: "OK", onClick: () => {} },
  secondaryAction: initialSecondaryAction = null,
}) => {
  const [show, setShow] = useState(initialShow);
  const [title, setTitle] = useState(initialTitle);
  const [body, setBody] = useState(initialBody);
  const [primaryAction, setPrimaryAction] = useState(initialPrimaryAction);
  const [secondaryAction, setSecondaryAction] = useState(initialSecondaryAction);

  useEffect(() => {
    setShow(initialShow);
    setTitle(initialTitle);
    setBody(initialBody);
    setPrimaryAction(initialPrimaryAction);
    setSecondaryAction(initialSecondaryAction);
  }, [initialShow, initialTitle, initialBody, initialPrimaryAction, initialSecondaryAction]);

  const handleClose = () => {
    setShow(false);
    if (externalHandleClose) externalHandleClose();
  };

  const handlePrimaryAction = () => {
    primaryAction.onClick();
    setShow(false);
  };

  const handleSecondaryAction = () => {
    if (secondaryAction) {
      secondaryAction.onClick();
    }
    setShow(false);
  };

  return (
    <Modal id="popUpModal" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>{title}</Modal.Title>
        <Button
          id="popUpCloseBtn"
          className="close-button"
          variant="link"
          onClick={handleClose}
          aria-label="Close"
          title="Close"
        >
          <span aria-hidden="true">&times;</span>
        </Button>
      </Modal.Header>
      <hr />
      <Modal.Body id="popUpModalBody" style={{ fontSize: '1rem' }}>{body}</Modal.Body>
      <Modal.Footer>
        {secondaryAction && (
          <Button id="popUpSecondaryBtn" variant="secondary" onClick={handleSecondaryAction}>
            {secondaryAction.text}
          </Button>
        )}
        <Button id="popUpPrimaryBtn" variant="primary" onClick={handlePrimaryAction}>
          {primaryAction.text}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopUpModal;
