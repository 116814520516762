import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from "react-bootstrap";
import { Auth, Hub } from 'aws-amplify';
import { Switch, Route, Redirect } from "react-router-dom";
import AuthenticatedLayout from "layouts/AuthenticatedLayout.js";
import StartPageLayout from "layouts/StartPage.js";
import RegisterPageLayout from "layouts/RegisterPage.js";
import PasswordResetLayout from "layouts/PasswordResetPage.js";
import MicrosoftAuthLayout from 'layouts/MicrosoftAuthLayout';
import { TourProvider } from "@reactour/tour";
import { BrowserRouter } from "react-router-dom";
import { setUserVault, invokeLambdaWithRetry } from './services/Helper';
import {getParameterFromManager} from './services/Utils';
import ReloadTimerUtils from '../src/components/Utils/ReloadTimerUtils';
import './App.css';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [statusMsg, setStatusMsg] = useState();
  const [prevBuildValue, setPrevBuildValue] = useState("");
  const [currentBuildValue, setCurrentBuildValue] = useState("");
  const [showReloadModal, setShowReloadModal] = useState(false);
  const [showNoReloadModal, setShowNoReloadModal] = useState(false);
  const [showUiTimerModal, setShowUiTimerModal] = useState(false);
  const [oldBuildValue, setOldBuildValue] = useState('');

  useEffect(() => {
    const interval = setInterval(async() => {
      if (!showReloadModal) {
        setOldBuildValue(prevBuildValue);
        setShowUiTimerModal(false);
        let buildValueParam;
        if ((window.location.pathname === "/login"|| window.location.pathname === "/") && currentUser === null) {
          buildValueParam = await getBuildVersionValue();
        } else {
          buildValueParam = await getParameterFromManager("MEAPLATFORM_BUILD_VERSION");
        }
        setCurrentBuildValue(buildValueParam);
        let envStatusAutoreload = process.env.REACT_APP_DISABLE_AUTORELOAD;
        if (envStatusAutoreload === undefined || envStatusAutoreload === null || envStatusAutoreload === "false") {
          if (prevBuildValue === undefined || prevBuildValue === null || prevBuildValue === buildValueParam || prevBuildValue === "") {
            setShowReloadModal(false);
          } else {
            if ((window.location.pathname === "/login"|| window.location.pathname === "/") && currentUser === null) {
              window.location.reload();
            } else {
              setShowReloadModal(true);
            }
          }
        }
        setPrevBuildValue(buildValueParam);
      }
    }, 15 * 60 * 1000); // 15 minutes in milliseconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [prevBuildValue, currentUser]);

  async function getBuildVersionValue() {
    try {
      const body = {
        "target": "build-version-value"
      };
      const init = {
        body: body
      };
      const resp = await invokeLambdaWithRetry("instanceHandler", init);
      console.log("getBuildVersionValue resp:", resp);
      return resp.response;
    } catch (err) {
      console.log('err: ', err);
      return false;
    }
  }

  useEffect(() => {
    Hub.listen('auth', (event) => {
      if (event.payload.event === "signIn" || event.payload.event === "verify") {
        if (process.env.REACT_APP_MFANAME === undefined) {
          setCurrentUser(event.payload.data);
        }
        else if (process.env.REACT_APP_MFANAME !== undefined && (event.payload.data.preferredMFA !== "NOMFA" || localStorage.getItem("mfasetupready") === "true")) {
          if (event.payload.data.username.startsWith("okta_") || event.payload.data.username.startsWith("azuread_") || event.payload.data.username.startsWith("azureadopen_")) {//includes azureadopen
            var uInfo = event.payload.data.signInUserSession.idToken.payload;
            setUserVault(undefined, undefined, uInfo.given_name, uInfo.family_name, uInfo.phone_number, uInfo['custom:company'], uInfo['custom:country'], uInfo['cognito:groups'].join(','), uInfo.sub);
          } else if (localStorage.getItem("mfasetupready") === "true") {
            localStorage.removeItem("mfasetupready");
          }
          setCurrentUser(event.payload.data);
        }
      } else if (event.payload.event === "signOut") {
        if (localStorage.getItem("transitions") !== "off")
          setCurrentUser(null);
      }
    })
  }, []);

  function reqFedLogout(userAttributes) {
    const index = userAttributes.findIndex(userAttribute => {
      return userAttribute.Name === "email" && userAttribute.Value.toLowerCase().endsWith("@meaplatform.com");
    });
    if (index !== -1)
      return false;
    return true;
  }

  const checkAndSignOutIncompleteMFA = async () => {
    const savedMfaState = localStorage.getItem('mfaState');
    if (savedMfaState) {
      if (savedMfaState === 'MFA_SETUP') {
        await Auth.signOut();
        console.log('Signed out user due to incomplete MFA_SETUP');
        window.location.href = '/';
      } else if (savedMfaState === 'SOFTWARE_TOKEN_MFA') {
        await Auth.signOut();
        console.log('Signed out user due to incomplete SOFTWARE_TOKEN_MFA');
        window.location.href = '/';
      }
      localStorage.removeItem('mfaState');
    }
  };
  
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        checkAndSignOutIncompleteMFA();
        if (currentUser === null) {
          setShowModal(true);
          setStatusMsg("Detecting existing session");
        }
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        if(window.location.pathname === "/logout") {
          window.location.href = '/login';
          if (user && user.storage && user.storage[user.userDataKey] && JSON.parse(user.storage[user.userDataKey]).UserAttributes && reqFedLogout(JSON.parse(user.storage[user.userDataKey]).UserAttributes)) {
            await Auth.signOut();
          } else {
            await user.signOut();
          }
        } else {
          setCurrentUser(user);
        }
      }
      catch (err) {
        console.error(err);
        if(window.location.pathname === "/logout") {
          window.location.href = '/login';
        }
      }
      setLoading(false);
      setShowModal(false);
    }
    fetchData();
  }, []);
  var pendingUsers = false;
  if (currentUser) {
    let data = currentUser.signInUserSession.accessToken.payload;
    if (data[`cognito:groups`]?.length !== 0) {
      if (data[`cognito:groups`]?.includes('MEA_PENDINGUSERS')) {
        pendingUsers = true;
      }
    }
  }
  
  return (
    <>
    <BrowserRouter>
      <TourProvider onClickMask={(e) => e.preventDefault()}
        prevButton={({ currentStep, setCurrentStep, steps }) => {
          const first = currentStep === 0
          return (
            <button className='backbtn'
              onClick={() => {
                if (first) {
                  //setCurrentStep((s) => steps.length - 1)
                  setCurrentStep(0);
                } else {
                  setCurrentStep((s) => s - 1)
                }
              }}
            >
              Back
            </button>
          )
        }}
        nextButton={({
          Button,
          currentStep,
          stepsLength,
          setIsOpen,
          setCurrentStep,
          steps,
        }) => {
          const last = currentStep === stepsLength - 1
          return (
            <button className='backbtn'
              onClick={() => {
                if (last) {
                  setIsOpen(false)
                } else {
                  setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1))
                }
              }}
            >
              {last ? 'Done!' : 'Next'}
            </button>
          )
        }}
      >
        <React.StrictMode>
          <Switch>
            {(!currentUser || pendingUsers) && <Route path="/login" render={(props) => <StartPageLayout {...props} pendingUser={pendingUsers} />} />}
            {!currentUser && <Route path="/register" render={(props) => <RegisterPageLayout {...props} />} />}
            {!currentUser && <Route path="/pwdreset" render={(props) => <PasswordResetLayout {...props} />} />}
            {!currentUser && <Route path="/microsoftauth" render={(props) => <MicrosoftAuthLayout {...props} />} />}
            {!currentUser && <Route path="/" render={(props) => <StartPageLayout {...props} />} />}
            {!currentUser && <Redirect from="/login" to="/login" />}
            {!currentUser && <Redirect from="/register" to="/register" />}
            {!currentUser && <Redirect from="/pwdreset" to="/pwdreset" />}
            {!currentUser && <Redirect from="/microsoftauth" to="/microsoftauth" />}

            {currentUser && <Route path="/user/" render={(props) => <AuthenticatedLayout {...props} />} />}
            {currentUser && currentUser !== 'NOTSET' && <Redirect from="/user/profile" to="/user/profile" />}
            {currentUser && currentUser !== 'NOTSET' && <Redirect from="/user/submissions" to="/user/submissions" />}
            {currentUser && currentUser !== 'NOTSET' && <Redirect from="/user/triage" to="/user/triage" />}
            {currentUser && currentUser !== 'NOTSET' && <Redirect from="/user/claims" to="/user/claims" />}
            {currentUser && currentUser !== 'NOTSET' && <Redirect from="/user/" to="/user/submissions" />}
            {currentUser && currentUser !== 'NOTSET' && <Redirect from="/login" to={!pendingUsers ? "/user/submissions" : "/login"} />}
            {currentUser && currentUser !== 'NOTSET' && <Redirect from="/" to="/user/submissions"/>}
          </Switch>
        </React.StrictMode>
      </TourProvider>
    </BrowserRouter>

      {showUiTimerModal && <ReloadTimerUtils
        showUiTimerModal={showUiTimerModal}
        setShowUiTimerModal={setShowUiTimerModal}
      />
      }
    
    {isLoading && (
      <div className='existingsession'>
        <Modal
            className="modal-md modal-primary"
            show={showModal}
            onHide={() => setShowModal(false)}
            backdrop="static"
            centered
            animation={false}
          >
            <Modal.Body className="text-center">
              <p>{statusMsg}</p>
            </Modal.Body>
          </Modal>
      </div>
    )}

      <Modal
        className="modal-md modal-primary preview-detailed-summary"
        show={showReloadModal}
        onHide={() => setShowReloadModal(false)}
        backdrop="static"
        centered
        animation={false}
        backdropClassName="question-modal"
      >
        <Modal.Title className="modal-title modal-header mt-0 mb-3 summary-border-header">
          <Row className="w-100 pb-2">
            <Col className="col-12 text-center">
              <h3><b>Attention !!!</b></h3>
            </Col>
          </Row>
        </Modal.Title>
        <Modal.Body className="text-start p-0">
          <div className="text-center">
            <h5>Build updates are available, would you like to reload the page to get the updates?</h5>
            <p className='mb-1'><b>Old Build Version:</b> {oldBuildValue}</p>
            <p className='mb-1'><b>New Build Version:</b> {currentBuildValue}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className='d-block'>
          <div className='d-flex justify-content-center'>
            <button
              type="button"
              className="btn btn-primary btn-md font-weight-medium"
              onClick={async () => {
                window.location.reload();
              }}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn-secondary btn-md font-weight-medium"
              onClick={() => {
                setShowReloadModal(false);
                setShowNoReloadModal(true);
                console.log("No popup-ShowNoReloadModal", showNoReloadModal);
              }}
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        className="modal-md modal-primary preview-detailed-summary"
        show={showNoReloadModal}
        onHide={() => setShowNoReloadModal(false)}
        backdrop="static"
        centered
        animation={false}
        backdropClassName="question-modal"
      >
        <Modal.Title className="modal-title modal-header mt-0 mb-3 summary-border-header">
          <Row className="w-100 pb-2">
            <Col className="col-12 text-center">
              <h3><b>Attention !!!</b></h3>
            </Col>
          </Row>
        </Modal.Title>
        <Modal.Body className="text-start p-0">
          <div className="text-center">
            <h5>After 10mins, the page gets auto reload</h5>
          </div>
        </Modal.Body>
        <Modal.Footer className='d-block'>
          <div className='d-flex justify-content-center'>
            <button
              type="button"
              className="btn btn-primary btn-md font-weight-medium"
              onClick={() => {
                setShowUiTimerModal(true);
                setShowReloadModal(false);
                setShowNoReloadModal(false);
              }}
            >
              Ok
            </button>
          </div>
        </Modal.Footer>
      </Modal>            
    </>
  );
}

export default App;