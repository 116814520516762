import React, { Component } from "react";
import { Container, Button, Modal, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import { getParameterFromManager } from '../../services/Utils';
import config from '../../aws-exports';

class Footer extends Component {
  constructor(props){
    super(props);
    this.state={ showModal: false, showModalMsg: {
      instanceName: "",
      instanceType: "",
      instanceRegion: "",
      releaseNumber: "",
      buildNumber: ""
    }}
  }

  async getBuildInfo() {
    try {
        return await Promise.all([
          await getParameterFromManager(`MEAPLATFORM_BUILD_VERSION`),
          await getParameterFromManager(`MEAPLATFORM_RELEASE_VERSION`),
          await getParameterFromManager(`ENVIRONMENT_DETAILS`)
        ])
    } catch (e) {
      console.log(e);
    }
  }

  async handleGetBuildInfo() {
    this.setState(prevState =>  ({...prevState, showModal: true}))
    if (this.state.showModalMsg?.instanceName && this.state.showModalMsg?.instanceType && this.state.showModalMsg?.instanceRegion && this.state.showModalMsg?.releaseNumber && this.state.showModalMsg?.buildNumber) return;
    const [buildVersion, releaseVersion, envDetails] = await this.getBuildInfo();
    this.setState(prevState =>  ({...prevState, showModalMsg: {
      instanceName: envDetails?.instance_name,
      instanceType: envDetails?.instance_type,
      instanceRegion: config.aws_project_region,
      releaseNumber: releaseVersion,
      buildNumber: buildVersion
    }}))
  }

  render() {
    return (
      <footer className="footer pt-2 pb-2 pl-3 pr-3">
        <Container fluid>
          <nav>
            <ul className="footer-menu">
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Home
                </a>
              </li>
              <li style={{color: "#C8C8C8"}}>{" "}|{" "}</li>
              <li>
                <a href={window.location.href} onClick={(e) => {
                  e.preventDefault();
                  this.handleGetBuildInfo();
                }}>build info</a>
              </li>
            </ul>
            <p className="copyright text-center">
              © {new Date().getFullYear()}{" "}
              mea platform
            </p>
          </nav>
        </Container>
        <Modal
          className="modal-md modal-primary"
          size="lg"
          show={this.state.showModal}
          onHide={() => this.setState(prevState =>  ({...prevState, showModal: false}))}
          animation={false}
        >
          <Modal.Body style={{overflow: "auto", paddingBottom: 0}}>
            <Container>
              <Row>
                <Col className="mb-3" sm={4}>
                  <div style={{fontFamily: '"Poppins-Regular", sans-serif', fontWeight: 600}}>Instance Name</div>
                  <div>{this.state.showModalMsg.instanceName}</div>
                </Col>
                <Col className="mb-3" sm={4}>
                  <div style={{fontFamily: '"Poppins-Regular", sans-serif', fontWeight: 600}}>Instance Type</div>
                  <div>{this.state.showModalMsg.instanceType}</div>
                </Col>
                <Col className="mb-3" sm={4}>
                  <div style={{fontFamily: '"Poppins-Regular", sans-serif', fontWeight: 600}}>Instance Region</div>
                  <div>{this.state.showModalMsg.instanceRegion}</div>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3" sm={4}>
                  <div style={{fontFamily: '"Poppins-Regular", sans-serif', fontWeight: 600}}>Release Number</div>
                  <div>{this.state.showModalMsg.releaseNumber}</div>
                </Col>
                <Col sm={8}>
                  <div style={{fontFamily: '"Poppins-Regular", sans-serif', fontWeight: 600}}>Build Number</div>
                  <OverlayTrigger overlay={<Tooltip>{this.state.showModalMsg.buildNumber}</Tooltip>} placement="bottom">
                    <span className="d-inline-block">
                      <div style={{ whiteSpace: "nowrap", width: "250px", overflow: "hidden", textOverflow: "ellipsis", cursor: "pointer" }}>{this.state.showModalMsg.buildNumber}</div>
                    </span>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Container>
            <hr />
          </Modal.Body>
          <div className="modal-footer">
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={() => this.setState(prevState =>  ({...prevState, showModal: false}))}
            >
              Close
            </Button>
          </div>
        </Modal>
      </footer>
    );
  }
}

export default Footer;
