import React, { useEffect } from "react";
// react-bootstrap components
import {
  Modal
} from "react-bootstrap";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { isEmailChar, invokeLambdaWithRetry } from '../services/Helper';
import logo from '../assets/images/logo.png';
import "../assets/css/style.css";
import "../assets/vendors/ti-icons/css/themify-icons.css";
import { RiCloseLine, RiUserFollowLine, RiUserUnfollowLine, RiMailCheckLine } from "react-icons/ri";
import "../assets/vendors/base/vendor.bundle.base.css";
import CirclesLoader from "../layouts/CirclesLoader";

function MicrosoftAuth() {
  let [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showModalMsg, setShowModalMsg] = React.useState("");
  const history = useHistory();
  let [email, setEmail] = useState("");
  const [msAuthConfirmationModal, setMsAuthConfirmationModal] = useState(false);
  const [redirectStatus, setRedirectStatus] = useState(false);

  const allowEmailChars = e => {
    if (isEmailChar(e.key) === false) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  useEffect(() => {
    let checkMsUser = localStorage.getItem("msUser");
    setEmail(checkMsUser || "");
  },[]);

  const validateUser = async(email) => {
    setLoading(true);
    try {

      localStorage.setItem("msUser", email);
      const body = {
        "target": "fetchUserVault",
        UserId: email,
        microsoft: true
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      return { status: data.statusCode === 200, result: data };
    } catch (err) {
      console.error('err: ', err);
      return { status: false, error: err };
    } finally {
      setLoading(false);
    }
  }

  const createUserVault = async () => {
    setLoading(true);
    try {
      const body = {
        "target": "newUserVault",
        UserId: email
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      if (data?.statusCode === 200) {
        setShowModal(true);
        setShowModalMsg(
          <>
            <p>Thank you for registering</p>
            <p>Once approved you will receive an invitation to access mea platform</p>
            <p>Expect approval in less than one hour</p>
          </>
        );
        setRedirectStatus(true);
      }
      return data;
    } catch (err) {
      console.error('err: ', err);
      return { status: false, error: err };
    } finally {
      setLoading(false);
    }
  }

  let validateMicrosoftAuth = async () => {
    const validationResult = await validateUser(email);
    if (validationResult?.status && Object.entries(validationResult?.result?.response).length === 0) {
      await createUserVault();
    } else if (validationResult?.status && Object.entries(validationResult?.result?.response).length > 0) {
      const { Active, groups, user_sub } = validationResult?.result?.response;
      if (user_sub === "inactive" && (Active === 0 || groups === "MEA_PENDINGUSERS")) {
        setShowModal(true);
        setShowModalMsg(
          <>
            <p>Your registration is pending approval</p>
            <p>Once approved you will receive an invitation to access mea platform</p>
            <p>Expect approval in less than one hour</p>
          </>
        );
        setRedirectStatus(false);
      } else if (((Active !== 0 && groups !== "MEA_PENDINGUSERS") && (user_sub !== null && user_sub?.startsWith("invite-"))) || (user_sub === null)) {
        setMsAuthConfirmationModal(true);
      } else if ((Active === 1 && groups !== "MEA_PENDINGUSERS") && (user_sub !== null && !user_sub?.startsWith("invite-"))) {
        Auth.federatedSignIn({ provider: "AzureADOpen" })
          .then(() => { })
          .catch(e => console.log(e));
      }
    }
  }

  const reinviteUser = async () => {
    let checkMsUser = localStorage.getItem("msUser");
    setLoading(true);
    try {
      const body = {
        "target": "reinviteuser",
        UserId: checkMsUser
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      setMsAuthConfirmationModal(false);
      return data;
    } catch (err) {
      console.error('err: ', err);
      return { status: false, error: err };
    } finally {
      setLoading(false);
    }
  }

  const redirectToLogin = () => {
    let path = `/login`;
    history.push(path);
  }

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper d-flex align-items-center">
          <div className="content-wrapper auth-pages auth">
            <div className="auth-form-transparent">
            <div className="row mx-0">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
                  <div className="p-3 login-form">
                    <h3>email address authentication</h3>
                    <h5 className="txt-sm text-center mb-3">enter your valid microsoft email id</h5>
                      <form className="pt-3">
                        <div className="form-group">
                          <div className="input-group">
                            <input type="email" className="form-control border-left-0" placeholder="email" value={email} pattern="[A-Za-z0-9]+" onKeyDown={allowEmailChars} onChange={(event) => {
                              setEmail(event.target.value);
                            }}
                            />
                          </div>
                        </div>
                        <div className="mt-4 d-flex justify-content-center">
                          <button type="button" className="btn btn-primary btn-block auth-form-btn" disabled={!(/^[^\s@]+@[^\s@]+$/.test(email) && !/meaplatform\.com$/i.test(email))} onClick={validateMicrosoftAuth}>submit</button>
                        </div>
                      </form>
                  </div>
                </div>
                <div className="col-lg-6 d-flex align-items-center justify-content-center">
                  <div className="brand-logo">
                    <img src={logo} alt="logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid readmea-txt">
          <div className="row">
            <div className="col-12 text-center font-weight-light" data-tour="step-3" >
              <span className="txt-md">read mea </span>
              <label className="form-check-label text-muted">
                <a href={process.env.PUBLIC_URL + '/docs/meaprivpol.pdf'} target="_blank" rel="noreferrer">privacy policies</a>
              </label>
              <span className="txt-md"> and </span>
              <label className="form-check-label text-muted">
                <a href={process.env.PUBLIC_URL + '/docs/meatac.pdf'} target="_blank" rel="noreferrer">terms and conditions</a>
              </label>
            </div>
            <div className="col-12">
              <p className="font-weight-medium text-center">&copy; mea platform limited {new Date().getFullYear()}.</p>
            </div>
          </div>
        </div>
      </div>

      {/* loader Modal */}
      <Modal
          className="modal-centre new-modal"
          backdrop="static"
          keyboard={false}
          show={loading}
          animation={false}
          centered
        >
          <Modal.Body className="text-center">
            <CirclesLoader isLoading={loading} />
          </Modal.Body>
        </Modal>
        {/* loader Modal */}

      {/* Mini Modal */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        centered={true}
        className="d-flex new-modal"
      >
        <Modal.Header className="justify-content-end pt-2">
          <button className="closeicon" onClick={() => setShowModal(false)}><RiCloseLine /></button>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-3 px-3">
          {redirectStatus && <p className="identiy-icons"><RiUserFollowLine /></p>}
          {!redirectStatus && <p className="identiy-icons"><RiUserUnfollowLine /></p>}
          <div className="mt-3 mb-2 text-center modal-msg">{showModalMsg}</div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center pb-0">
          <p>powered by <span>mea platform</span></p>
        </Modal.Footer>
      </Modal>
      {/* End Modal */}

      {/* Mini Modal */}
      <Modal
        className="modal-md modal-primary new-modal"
        show={msAuthConfirmationModal}
        onHide={() => setMsAuthConfirmationModal(false)}
        backdrop="static"
        animation={false}
        backdropClassName="question-modal"
        centered={true}
      >
        <Modal.Header className="justify-content-end pt-3">
          <button className="closeicon" onClick={() => {
            setMsAuthConfirmationModal(false);
            redirectToLogin();
          }}
          ><RiCloseLine /></button>
        </Modal.Header>
        <Modal.Body className="text-center pt-0 pb-3 px-3">
          <p className="identiy-icons"><RiMailCheckLine /></p>
          <p className="text-center mb-1">An invitation to access mea platform has been sent to your email address</p>
          <p className="mb-1">Would you like to regenerate the invite?</p>
          <button
            type="button"
            className="btn btn-secondary btn-md font-weight-medium mt-3 mb-2"
            onClick={() => {
              reinviteUser();
            }}
          >
            Yes
          </button>
        </Modal.Body>
        <Modal.Footer className="justify-content-center pb-0">
          <p>powered by <span>mea platform</span></p>
        </Modal.Footer>
      </Modal>
      {/* End Modal */}
    </>
  );
}

export default MicrosoftAuth;