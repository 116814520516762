import React, { useState, useEffect } from 'react';
import { Toast } from "react-bootstrap";
import { BsStopwatch } from "react-icons/bs";

function ReloadTimerUtils({ showUiTimerModal, setShowUiTimerModal }) {
    const [seconds, setSeconds] = useState(600); // 10 minutes in seconds

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(prevSeconds => {
                if (prevSeconds === 0) {
                    clearInterval(interval);
                    window.location.reload();
                    return 0;
                } else {
                    return prevSeconds - 1;
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <>
           <Toast
                className="modal-md modal-primary showui-timer"
                show={showUiTimerModal}
                animation={false}
            >
                <Toast.Body className="text-start p-0">
                    <div className="text-center">
                        <BsStopwatch /> {formatTime(seconds)}
                    </div>
                </Toast.Body>
            </Toast>
        </>
    );
}

export default ReloadTimerUtils;