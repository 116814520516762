import React from "react";
import { useState, useEffect, useRef } from "react";
// react-bootstrap components
import {
  Form,
  Modal,
  Card,
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger
} from "react-bootstrap";
import { Auth, Storage } from 'aws-amplify';
import { useLocation } from "react-router-dom";
import DataGrid from 'react-data-grid';
import { v4 as uuid } from "uuid";
import MultiSelect, { components as ReactSelectComponents } from 'react-select'
import moment from "moment/moment";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Table from 'react-bootstrap/Table';
import 'moment-timezone';
import { HiOutlineInformationCircle } from "react-icons/hi";
import { logAudit, signOutUser, downloadBlob, isEmailChar, validateEmail, renderReactFragment, isExistingLOBCode, isExistingTemplate, invokeLambdaWithRetry } from '../services/Helper';
import "../assets/css/style.css";
import "../assets/vendors/ti-icons/css/themify-icons.css";
import "../assets/vendors/base/vendor.bundle.base.css";
import { getParameterFromManager } from "../services/Utils";
import UploadFileManagement from "components/UploadFileManagement";
import Tooltip from 'react-bootstrap/Tooltip';
import CirclesLoader from "../layouts/CirclesLoader";

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;
  if (Array.isArray(values)) { values = <div className="d-flex-center" style={{ paddingLeft: '2.3px' }}>Select...<span className="multiselect-badge">{values.length}</span></div> }
  return (
    <ReactSelectComponents.ValueContainer {...props}>
      {values}
      {input}
    </ReactSelectComponents.ValueContainer>
  );
}

function Management() {
  const [showModal, setShowModal] = React.useState(false);

  const [showReports, setShowReports] = React.useState(false);
  const [reportData, setReportData] = useState([]);
  let [reportCount, setReportCount] = useState(-1);
  let [currentReportPage, setCurrentReportPage] = useState(0);

  const [showModalMsg, setShowModalMsg] = React.useState("");
  let [loading, setLoading] = useState(false);
  let [email, setEmail] = useState("");
  let [roleForUser, setRoleForUser] = useState("");
  let [allowActivation, setAllowActivation] = useState(false);
  let [userActivationStatus, setUserActivationStatus] = useState(false);
  let [userRoles, setUserRoles] = useState([]);
  let [userRoleOptions, setUserRoleOptions] = useState([]);
  let [enableCountryCodes, setEnableCountryCodes] = useState(true);
  const [enableLobCode, setEnableLobCode] = useState(true);
  const [lobCodes, setLOBCodes] = useState([]);
  const [showModalYesNo, setShowModalYesNo] = React.useState(false);
  const [showModalYesNoMsg, setShowModalYesNoMsg] = React.useState("");
  const [evtShowModalYesNo, setEvtShowModalYesNo] = React.useState();
  const [excelFormatModal, setExcelFormatModal] = React.useState(false);
  const [excelFormatModalMsg, setExcelFormatModalMsg] = React.useState("");
  let [lobCodeCount, setLOBCodeCount] = useState(-1);
  let [currentLOBCodePage, setCurrentLOBCodePage] = useState(0);
  const pageInputRef = useRef(null);// create a ref for the pagination text field
  const [lobCode, setLOBCode] = React.useState("");
  const [lobText, setLOBText] = React.useState("");
  let [createMode, setCreateMode] = React.useState("Create");
  let [assocCtryCodeOptions, setAssocCtryCodeOptions] = React.useState([]);
  let [assocCtryCodes, setAssocCtryCodes] = React.useState([]);

  const [templates, setTemplates] = useState([]);
  let [templateCount, setTemplateCount] = useState(-1);
  let [currentTemplatePage, setCurrentTemplatePage] = useState(0);
  const [templateName, setTemplateName] = React.useState("");
  const [templateFileName, setTemplateFileName] = React.useState("");
  const [templatePath, setTemplatePath] = React.useState("");
  const [evtFileSelector, setEvtFileSelector] = React.useState();

  const [displayReportFromDate, setDisplayReportFromDate] = useState(null);
  const [displayReportToDate, setDisplayReportToDate] = useState(null);
  const [reportFromDate, setReportFromDate] = useState('');
  const [reportToDate, setReportToDate] = useState('');
  const [lobCreateOrEditMultiselectSelected, setLobCreateOrEditMultiselectSelected] = useState([]);

  let [searchBased, setSearchBased] = React.useState("");
  let [offsetValue, setOffsetValue] = React.useState();
  const [timeZoneOptions, setTimeZoneOptions] = React.useState([]);
  const [timeZoneOffset, setTimeZoneOffset] = React.useState();
  const [newRepFromDate,setNewRepFromDate] = React.useState();
  const [newRepToDate,setNewRepToDate] = React.useState();
  const [serverTimeZone, setServerTimeZone] = React.useState("");

  const [searchCodeAndTemp, setSearchCodeAndTemp] = React.useState(false);

  const [addCcodeModal,setAddCcodeModal] = React.useState(false);
  const [ccValue,setCcvalue] = useState("");
  const [ccSuccessModal,setCcSuccessModal] = React.useState(false);
  const [ccWarningModal,setCcWarningModal] = React.useState(false);
  const [ccInvalidModal,setCcInvalidModal] = React.useState(false);
  const [countryCodeText,setCountryCodeText] = useState("");
  const [showArchived, setShowArchived] = useState(false);
  const [maxTemplateAllowedCount, setMaxTemplateAllowedCount] = useState();
  const [showGroupManagementModal, setShowGroupManagementModal] = useState(false);
  let [lobCodeDisplay, setLOBCodeDisplay] = React.useState("Select");
  let [lobCodeOptions, setLOBCodeOptions] = React.useState([]);
  const [associatedUserGroups, setAssociatedUserGroups] = useState([]);
  const [allAssociatedUserGroups, setAllAssociatedUserGroups] = useState([]);
  const [groupNamesList, setGroupNamesList] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [asscoiateResp, setAsscoiateResp] = useState({});
  
  const location = useLocation();
  const pageSize = 5;
  const reportPageSize = 10;

  var allowedExtensions = ".xlsx";

  const meaRoles = [
    "MEA_ADMIN",
    "MEA_CORPORATE",
    "MEA_PROCESSORS",
    "MEA_USERS",
    "MEA_APIUSERS"
  ];

  let lobColumns = [
    { key: 'LOBCode', name: 'Line of Business Code' },
    { key: 'LineOfBusiness', name: 'Line of Business' },
    { key: 'AssocCountryCodes', name: 'Associated Countries' },
    {
      key: 'Actions', name: 'Actions', formatter({ row }) {
        var fragMent = <React.Fragment>
          <button type="button" className="btn-action font-weight-medium" onClick={(e) => {
            setEvtShowModalYesNo({ "params": { "evt": e, "row": row }, "option": "delete", "type": "LOBCode" });
            setShowModalYesNoMsg(`You are about to delete the LOB ${row.LOBCode}. Do you want to continue?`);
            setShowModalYesNo(true);
          }}><i className="fa fa-trash" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Delete"></i></button>
        </React.Fragment>;
        return fragMent;
      }
    }
  ];

  let groupNamesColumn = [
    { key: 'GroupName', name: 'Group Name' },
    { key: 'LOBCode', name: 'LOB Code' },
    { key: 'CountryCode', name: 'Country Code' },
    {
      key: 'Actions', name: 'Actions', formatter({ row }) {
        var fragMent = <React.Fragment>
          <button type="button" className="btn-action font-weight-medium" onClick={(e) => {
            e.preventDefault();
            setEvtShowModalYesNo({ "params": { "evt": e, "row": row }, "option": "delete-user-group" });
            setShowModalYesNoMsg(`You are about to delete user group. Do you want to continue?`);
            setShowModalYesNo(true);
          }}>
            <i className="fa fa-trash" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Delete"></i>
          </button>
        </React.Fragment>;
        return fragMent;
      }
    }
  ];

  let templateColumns = [
    { key: 'TemplateName', name: 'TemplateName' },
    {
      key: 'TemplateFileName', name: 'Template File', formatter({ row }) {
        var fragMent = <React.Fragment>
          {
            <React.Fragment>
              <a href="/#" onClick={(e) => {
                downloadDocuments(e, row);
              }}>
                {row.TemplateFileName}
              </a>
            </React.Fragment>
          }
        </React.Fragment>
          ;
        return fragMent;
      }
    },
    {
      key: 'Actions', name: 'Actions', formatter({ row }) {
        var fragMent = <React.Fragment>
          <button type="button" className="btn-action font-weight-medium" onClick={(e) => {
            setEvtShowModalYesNo({ "params": { "evt": e, "row": row }, "option": "delete", "type": "Template" });
            setShowModalYesNoMsg(`You are about to delete the Template ${row.TemplateName}. Do you want to continue?`);
            setShowModalYesNo(true);
          }}><i className="fa fa-trash" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Delete"></i></button>
        </React.Fragment>;
        return fragMent;
      }
    }
  ];

  async function downloadDocuments(e, row) {
    e.preventDefault();
    const result = await Storage.get(row.TemplatePath, { download: true, expires: 60 });
    downloadBlob(result.Body, row.TemplateFileName);
  }

  async function notifyUser(request) {
    try {
      const body = {
        "toAddress": request.toAddress,
        "name": request.name,
        "option": request.option
      };
      const init = {
        body: JSON.stringify(body)
      };
      const data = await invokeLambdaWithRetry("notifyUserDocStage", init);
      console.log("notifyUser resp:", JSON.stringify(data));
    } catch (err) {
      console.error('err: ', err);
    }
  }

  async function createReport() {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "option": "create-report",
        "reportFromDate": reportFromDate,
        "reportToDate": reportToDate,
        ...(showArchived && { "showArchived": true })
      };
      const init = {
        body: JSON.stringify(body)
      };
      const data = await invokeLambdaWithRetry("processDocument", init, "Event");
      console.log("processDocument resp:", data);
      return true;
    } catch (err) {
      console.error('err: ', err);
      return false;
    }
  }

  async function fetchOrActivateUser(option) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "option": option,
        "userEmail": email,
        "userRole": roleForUser
      };
      const init = {
        body: JSON.stringify(body)
      };
      const data = await invokeLambdaWithRetry("activateUserAndAddToGroup", init);
      return { status: data.statusCode === 200, result: data };
    } catch (err) {
      console.error('err: ', err);
      return { status: false, error: err };
    }
  }

  let findUser = async (event) => {
    event.preventDefault();
    setLoading(true);
    var resp = await fetchOrActivateUser("getuser");
    setLoading(false);
    if (resp.status === true) {
      //Show User Activation Status
      //Show User Roles
      setUserActivationStatus(resp.result.Enabled);
      setUserRoles(resp.result.groups.filter(group => group.startsWith('MEA_')));

      var uro = [];
      for (var i = 0; i < meaRoles.length; i++) {
        if (resp.result.groups.includes(meaRoles[i]) === false) {
          uro.push(meaRoles[i]);
        }
      }
      setUserRoleOptions(uro);
      setRoleForUser(uro[0]);
      //Drop Down to Enter User Role - Current User Roles
      setAllowActivation(true);
      await fetchAssocUserGroups();
    } else {
      setShowModalMsg(`Invalid User ${email}`);
      setShowModal(true);
    }
  }

  let activateUser = async (event) => {
    event.preventDefault();
    setLoading(true);
    var resp = await fetchOrActivateUser("activation");
    let checkUser = await fetchOrActivateUser("getuser");
    setLoading(false);
    setAllowActivation(false);
    if (resp.status === true) {
      if (userActivationStatus === false && checkUser?.result?.external !== "ext_user") {
        notifyUser({ "toAddress": email, "option": "accountActivated" });
      }
      setShowModalMsg(`Successfully updated User ${email}`);
    } else {
      setShowModalMsg(`Error activating User ${email}`);
    }
    setShowModal(true);
  }

  let deleteUser = async () => {
    setLoading(true);
    var resp = await fetchOrActivateUser("deleteUser");
    setLoading(false);
    setAllowActivation(false);
    if (resp.status === true) {
      if (userActivationStatus === false) {
        notifyUser({ "toAddress": email, "option": "accountDeleted" });
      }
      setShowModalMsg(`Successfully deleted User ${email}`);
    } else {
      setShowModalMsg(`Error activating User ${email}`);
    }
    setShowModal(true);
  }

  const allowEmailChars = e => {
    if (isEmailChar(e.key) === false) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  var countryCodesVal = undefined;
  var countryCodesValTs;  
  function isCacheValid(cacheValTs) {
    if (cacheValTs === undefined) {
        return false;
    }
    const now = new Date();
    const msBetweenDates = Math.abs(cacheValTs.getTime() - now.getTime());
    const secBetweenDates = msBetweenDates / 1000;
    if (secBetweenDates > 300) {
        return false;
    }
    return true;
  }

  async function fetchCountryCodes() {
    try {
      if(countryCodesVal === undefined || (isCacheValid(countryCodesValTs) === false)) {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
          "access_token": user.signInUserSession.idToken.jwtToken,
          "target": "fetchCountryCodes"
        };
        const init = {
          body: body
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        countryCodesValTs = new Date();
        countryCodesVal = data.response;
        return countryCodesVal;
      } else {
        return countryCodesVal;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function createCountryCode() {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const CountryCodesInput = {
        "countryCode": ccValue,
        "country": countryCodeText
      };
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "createCountryCode",
        "countryCodeInput": CountryCodesInput
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      return data.response;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  useEffect(() => {
    (async function initF() {
      setLoading(true);
      await fetchLOBCodes(true);
      await fetchTemplates(true);
      var countryCodes = await fetchCountryCodes();
      countryCodes = countryCodes.map(obj => ({ label: `${obj.countryCode} - ${obj.country}`, value: obj.countryCode }));
      setAssocCtryCodeOptions(countryCodes);
      const enableCCVal = await getParameterFromManager("ENABLE_COUNTRY_CODE_VALIDATION");
      const enableLobCodeVal = await getParameterFromManager("LOB_CODES_VALIDATION");
      setEnableCountryCodes(enableCCVal);
      setEnableLobCode(enableLobCodeVal);
      setLoading(false);
    })();
  }, [location]);

  useEffect(()=>{
    getDateAndTime(newRepFromDate, newRepToDate);
  },[offsetValue])

  useEffect(() => {
    (async function fetchGroupAndLOBAndCcodeValue() {
      if(showGroupManagementModal) {
        await fetchAssocUserGroups();
      } else {
        const fetchGroupName = await getParameterFromManager('USER_GROUPNAME_CONFIG');
        setGroupNamesList(fetchGroupName?.groupNames);
        const lobCodeStatus = await getParameterFromManager('LOB_CODES_VALIDATION');
        if ((lobCodeStatus && enableCountryCodes) || (lobCodeStatus && !enableCountryCodes)) {
          fetchLOBCodeList("", "", false, undefined, 100).then((lobCodeOptions) => {
            setLOBCodeOptions(lobCodeOptions);
          }).catch((error) => {
            console.log("Error in fetchLOBCodeList", error);
          });
        }
      }
    })();
  }, [enableCountryCodes, showGroupManagementModal])

  function rowGroupNameKeyGetter(row) {
    return row?.group_id;
  }

  async function fetchAssocUserGroups(groupName) {
    try {
      setLoading(true);
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "option": "getGroupsList",
        "userEmail": email
      };

      if(groupName !== undefined) {
        body.groupName = groupName;
      }

      const init = {
        body: JSON.stringify(body)
      };
      const data = await invokeLambdaWithRetry("activateUserAndAddToGroup", init);
      setAssociatedUserGroups(data?.associateUserGroups);
      if (groupName === undefined || (groupName!==undefined && allAssociatedUserGroups?.length === 0 && data?.associateUserGroups?.length > 0)) {
        setAllAssociatedUserGroups(data?.associateUserGroups)
      }
      return data?.associateUserGroups;
    } catch (err) {
      console.error('err: ', err);
      return false;
    } finally{
      setLoading(false);
    }
  }

  const handleResetState = () => {
    setGroupName("");
    setLOBCodeDisplay("Select");
    setAssocCtryCodes([]);
    setLobCreateOrEditMultiselectSelected([]);
  }

  const handleAssociate = async(groupsList) => {
    try {
      setLoading(true);
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "option": "associateGroup",
        "userEmail": email,
        "userRole": roleForUser,
        "groupList": groupsList
      };

      const init = {
        body: JSON.stringify(body)
      };
      const data = await invokeLambdaWithRetry("activateUserAndAddToGroup", init);
      if(data?.errMsg !== undefined) {
        setShowModalMsg(data.errMsg);
        setShowModal(true);
      } else {
        setAsscoiateResp({
          createdGroupName: data.createdGroupName,
          existingGroups: data.existingGroups,
          associatedExistGroups: data.associatedExistGroups
        });
        setShowGroupModal(true);
      }
      setLOBCodeDisplay("Select");
      setAssocCtryCodes([]);
      setLobCreateOrEditMultiselectSelected([]);
      await fetchAssocUserGroups(groupName);
    } catch (err) {
      console.error('err: ', err);
      return false;
    } finally{
      setLoading(false);
    }
  }
  
  async function getDateAndTime(dateTime,toDateTime) {
    let timezoneOffset = offsetValue !== undefined ? offsetValue : 0;
    
    let inputReportFromDate = new Date(dateTime);
    let inputReportToDate = "";
    if(toDateTime !== undefined){
      inputReportToDate = new Date(toDateTime);
    }

    let newReportFromDate = new Date(inputReportFromDate.getTime() + (timezoneOffset) * 60 * 1000);
    if(toDateTime !== undefined){
      let newReportToDate = new Date(inputReportToDate.getTime() + (timezoneOffset) * 60 * 1000);
      setReportFromDate(moment(newReportFromDate).format("YYYY-MM-DD HH:mm:ss"));
      setReportToDate(moment(newReportToDate).format("YYYY-MM-DD HH:mm:ss"));
    }
    
    return newReportFromDate;
  }

  useEffect(() => {
    (
      async function getTimeZoneValue() {
        let timeZoneList = await getParameterFromManager("MEA_TIMEZONE");
        setServerTimeZone(timeZoneList.serverTimeZone);
        setSearchBased(`${timeZoneList.serverTimeZone} - Default`);
        setTimeZoneOffset(timeZoneList);
        setTimeZoneOptions(timeZoneList.timeZones);
        await checkDayLightMode();
      }
    )();
  },[])

  useEffect(() => {
    checkDayLightMode()
  }, [reportFromDate,reportToDate])

  async function checkDayLightMode(event) {
    const result = timeZoneOffset?.timeZones.find((k)=>{
      if (event === undefined) {
        return k === (searchBased === `${serverTimeZone} - Default` ? serverTimeZone : searchBased);
      } else {
        return k === (event === `${serverTimeZone} - Default` ? serverTimeZone : event);
      }
    })
    if (result) {
      const keyValue = result;
      const zoneResult = Object.entries(timeZoneOffset.tz_difference).find(([k,v]) =>{
          return k === keyValue;
      });
      const [zoneKey, zoneName] = zoneResult;

      let formatDate = moment(displayReportFromDate).format("YYYY-MM-DD");
      var date = moment.tz(formatDate, zoneName);
      let isDST = date.isDST();

      let offset1Value = moment.tz(zoneName);
      let offset2Value = moment.tz(timeZoneOffset.tz_difference[serverTimeZone]);
  
      const offsetDifference = offset2Value.utcOffset() - offset1Value.utcOffset();
      let dstOffset = null;
      timeZoneOffset.daylight_saving.find((dstValue)=>{
        if (!isDST && zoneKey === dstValue) {
          dstOffset = 60;
        } else {
          dstOffset = 0;
        }
        return "";
      })
      const timeDiffInMinutes = offsetDifference + dstOffset;
      setOffsetValue(timeDiffInMinutes);
    } 
  }

  async function onBrowseTemplate(e) {
    if (!e.target.files[0])
      return;
    setEvtFileSelector(e);
    setTemplateFileName(e.target.files[0].name);
    setTemplatePath(`templates/${uuid()}${e.target.files[0].name}`);
  }

  async function fetchLOBCodes(reloadPage) {
    try {
      if (reloadPage === true) {
        lobCodeCount = -1;
        setLOBCodeCount(-1);
      }
      if (reloadPage === true) {
        setCurrentLOBCodePage(0);
      }
      var lobCodes = await fetchLOBCodeList("", "", reloadPage);
      if (typeof lobCodes !== "undefined" && lobCodes !== null) {
        setLOBCodes(lobCodes);
      }
    } catch (err) {
      if (err === "The user is not authenticated") {
        signOutUser();
      }
      console.error(err);
    }
  }

  async function fetchLOBCodeList(srchLOBCode, srchLOBText, reloadPage, enterKeyValue, pageSizeCount) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });

      if (enterKeyValue === true) {
        lobCodeCount = -1;
        setLOBCodeCount(-1);
      }
      if (enterKeyValue === true) {
        currentLOBCodePage = 0;
        setCurrentLOBCodePage(0);
      }
      var body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "count": lobCodeCount,
        "rowStart": pageSizeCount === undefined ? currentLOBCodePage * pageSize : 0,
        "pageSize": pageSizeCount === undefined ? pageSize : pageSizeCount,
        "searchLOBCode": srchLOBCode,
        "searchLOBText": srchLOBText,
        "mode": "like"
      };
      if (reloadPage === true) {
        body.count = -1;
        body.rowStart = 0;
      }
      const init = {
        body: JSON.stringify(body)
      };
      const data = await invokeLambdaWithRetry("fetchLOBCodeList", init);
      if (lobCodeCount === -1) {
        setLOBCodeCount(data.count);
      }
      return data.lobCodes;
    } catch (err) {
      console.error('err: ', err);
      return false;
    }
  }

  async function deleteLobCode(e, lobCode) {
    e.preventDefault();
    try {
      setLoading(true);
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
          "access_token": user.signInUserSession.idToken.jwtToken,
          "target": "deleteLOBCode", 
          "LOBCode": lobCode.LOBCode
        };
        const init = {
          body: body
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        console.log(data);
        logAudit(undefined, undefined, "LOB", "Deleted", "UI", lobCode.LOBCode, lobCode.LOBText).then((data) => { });
      } catch (err) {
        if (err === "The user is not authenticated") {
          signOutUser();
        }
        console.error(err);
      }
      await fetchLOBCodes(true);
    } finally {
      setLoading(false);
    }
  }

  function rowLOBKeyGetter(row) {
    return row.LOBCode;
  }

  async function createLOBCode(lobCodeInput) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "createLOBCode", 
        "lobCodeInput": lobCodeInput
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      return data.response;
    } catch (err) {
      console.error(err);
      return false;
    }
  }


  async function updateLOBCode(lobCodeInput) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "updateLOBCode", 
        "lobCodeInput": lobCodeInput
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      return data.response;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function createOrUpdateLOBCode(e) {
    e.preventDefault();
    try {
      setLoading(true);
      if (lobCode === null || lobText === null || lobCode.trim() === "" || lobText.trim() === "")
        return;
      try {
        const lobCodeInput = {
          "LOBCode": lobCode,
          "LineOfBusiness": lobText,
          "AssocCountryCodes": assocCtryCodes.join(",")
        };

        var res;
        if (createMode === "Create") {
          res = await createLOBCode(lobCodeInput);
          console.log(res);
          logAudit(undefined, undefined, "LOB", "Created", "UI", lobCode, lobText).then((data) => { });
        } else {
          res = await updateLOBCode(lobCodeInput);
          console.log(`res : ${res}`);
          logAudit(undefined, undefined, "LOB", "Updated", "UI", lobCode, lobText).then((data) => { });
        }
        setLOBCode("");
        setLOBText("");
        setAssocCtryCodes([]);
        setLobCreateOrEditMultiselectSelected([]);
      } catch (err) {
        if (err === "The user is not authenticated") {
          signOutUser();
        }
        console.error('err: ', err);
      }
      fetchLOBCodes(true);
    } finally {
      setLoading(false);
    }
  }

  async function setCreateOrEditMode(lobCodeInput) {
    setCreateMode("Create");
    if (lobCodeInput.trim().length === 0)
      return;
    var existing = await isExistingLOBCode(lobCodeInput);
    if (existing === true)
      setCreateMode("Edit");
  }

  async function fetchTemplates(reloadPage) {
    try {
      const templateController = await getParameterFromManager('TEMPLATE_CONTROLLER');
      setMaxTemplateAllowedCount(templateController?.maxTemplatesAllowed);
      if (reloadPage === true) {
        templateCount = -1;
        setTemplateCount(-1);
      }
      var templates = await fetchTemplateList("");
      if (reloadPage === true) {
        setCurrentTemplatePage(0);
      }
      if (typeof templates !== "undefined" && templates !== null) {
        setTemplates(templates);
      }
    } catch (err) {
      if (err === "The user is not authenticated") {
        signOutUser();
      }
      console.error(err);
    }
  }

  async function fetchTemplateList(srchTemplate, enterKeyValue, enableCount) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      if (enterKeyValue === true) {
        templateCount = -1;
        setTemplateCount(-1);
      }
      if(enterKeyValue === true) {
        currentTemplatePage = 0;
        setCurrentTemplatePage(0);
      }
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "count": templateCount,
        "rowStart": currentTemplatePage * pageSize,
        "pageSize": pageSize,
        "searchTemplateName": srchTemplate,
        "mode": "like"
      };
      const init = {
        body: JSON.stringify(body)
      };
      const data = await invokeLambdaWithRetry("fetchTemplateList", init);
      if (templateCount === -1) {
        setTemplateCount(data.count);
      }
      if (enableCount === true && data.count >= maxTemplateAllowedCount) {
        return false;
      } else {
        return data.templates;
      }
    } catch (err) {
      console.error('err: ', err);
      return false;
    }
  }

  async function isCheck(templateName, templateFileName, templatePath) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "templateName": templateName,
        "templateFileName": templateFileName,
        "templatePath": templatePath,
        "mode": "check",
        "checkOutputHeaders":"true"
      };
      const init = {
        body: JSON.stringify(body)
      };
      const data = await invokeLambdaWithRetry("fetchTemplateList", init);
      return data
    } catch (err) {
      console.error('err: ', err);
      return false;
    }
  }

  async function deleteTemplate(e, Template) {
    e.preventDefault();
    try {
      setLoading(true);
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
          "access_token": user.signInUserSession.idToken.jwtToken,
          "target": "deleteTemplate", 
          "TemplateName": Template.TemplateName
        };
        const init = {
          body: body
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        console.log(data);        
        logAudit(undefined, undefined, "Template", "Deleted", "UI", Template.TemplateName, Template.TemplatePath).then((data) => { });
      } catch (err) {
        if (err === "The user is not authenticated") {
          signOutUser();
        }
        console.error(err);
      }
      await fetchTemplates(true);
    } finally {
      setLoading(false);
    }
  }

  async function retrieveReportsData(searchParams) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "datatype": "reportsdata",
        "searchParams": searchParams,
        "rowStart": currentReportPage * reportPageSize,
        "pageSize": reportPageSize
      };
      const init = {
        body: JSON.stringify(body)
      };
      const data = await invokeLambdaWithRetry("fetchDocumentList", init);
      return data;
    } catch (err) {
      console.error('err: ', err);
      return false;
    }
  }

  async function fetchReportsData(reloadPage) {
    try {
      setLoading(true);
      if (reloadPage === true) {
        setReportCount(-1);
        setCurrentReportPage(0);
      }
      var searchParams = {
        currentReportPage: 0,
        reportCount: -1
      }
      if (reloadPage !== true) {
        searchParams = {
          currentReportPage: currentReportPage,
          reportCount: reportCount
        }
      }
      var data = await retrieveReportsData(searchParams);
      if (typeof data.report !== "undefined" && data.report !== null) {
        setReportData(data.report);
      }
      if (data.report !== undefined) {
        setReportCount(data.count);
      }
      setLoading(false);
    } catch (err) {
      if (err === "The user is not authenticated") {
        signOutUser();
      }
      console.error(err);
    }
  }

  function rowTemplateKeyGetter(row) {
    return row.TemplateName;
  }

  async function createTemplate(e) {
    e.preventDefault();
    setLoading(true);
    templateCount = -1;
    setTemplateCount(-1);
    var templatesAllCount = await fetchTemplateList("", undefined, true);
    if (templatesAllCount === false) {
      setShowModalMsg(`You have exceeded Max templates allowed count. Please contact Support team`);
      setShowModal(true);
      setTemplateName("");
      setTemplateFileName("");
      setTemplatePath("");
      evtFileSelector.target.value = null;
      setEvtFileSelector(null);
      fetchTemplates(true);
      setLoading(false);
    } else {
      try {
        if (templateName === null || templateFileName === null || templatePath === null || templateName.trim() === "" || templateFileName.trim() === "" || templatePath.trim() === "")
          return;
        var existing = await isExistingTemplate(templateName);
        if (existing === true) {
          setShowModalMsg(`Template ${templateName} already exists, delete first to set a new template`);
          setShowModal(true);
        }
        await Storage.put(templatePath, evtFileSelector.target.files[0]);

        var result = await isCheck(templateName, templateFileName, templatePath)
        if (result.statusCode === 400) {
          setExcelFormatModal(true)
          setExcelFormatModalMsg(result.error)
        }
        logAudit(undefined, undefined, "Template", "Created", "UI", templateName, templatePath).then((data) => { });
        fetchTemplates(true);
      } catch (err) {
        console.log(err)
      } finally {
        setTemplateName("");
        setTemplateFileName("");
        setTemplatePath("");
        evtFileSelector.target.value = null;
        setEvtFileSelector(null);
        setLoading(false);
      }
    }
  }

  const onSearchTextChange = async(event)=>{
    const searchValue = event.target.value;
    if (searchValue.length < 1) {
      setLoading(true);
      setSearchCodeAndTemp(false);
      if (lobCode.length === 1) {
        await fetchLOBCodes(true);
      } else {
        await fetchTemplates(true);
      }
      setLoading(false);
    }
  }

  const onSearchFetchList = async(e) =>{
    let searchCode = e.target.value;
    if (lobCode.length >= 1) {
      setSearchCodeAndTemp(true);
      if (searchCode.length >= 3) {
        setLoading(true);
        let enterKeyValue = true;
        let searchLOBList = await fetchLOBCodeList(lobCode,"",false,enterKeyValue);
        setLOBCodes(searchLOBList);
        setLoading(false);
      }
    } else {
      setSearchCodeAndTemp(true);
      if (searchCode.length >= 3) {
        setLoading(true);
        let enterKeyValue = true;
        let searchTempList = await fetchTemplateList(templateName,enterKeyValue);
        setTemplates(searchTempList);
        setLoading(false);
      }
    }
  }

  async function deleteUserGroups(user_profile_id) {
    try {
      setLoading(true);
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "option": "deleteAssociatedUserGroup",
        "user_profile_id": user_profile_id,
        "userEmail": email
      };

      const init = {
        body: JSON.stringify(body)
      };
      const data = await invokeLambdaWithRetry("activateUserAndAddToGroup", init);
      console.log(data);
      await fetchAssocUserGroups();
    } catch (err) {
      console.error('err: ', err);
      return false;
    } finally{
      setLoading(false);
    }
  }

  const handleClose = () => {
    if (allAssociatedUserGroups?.length > 0) {
      setShowGroupManagementModal(false);
    }
  };

  return (
    <>
      <Container fluid>
        {/* Mini Modal */}
        <Modal
          className="modal-centre"
          backdrop="static"
          keyboard={false}
          show={loading}
          animation={false}
          centered
        >
          <Modal.Body className="text-center">
            <CirclesLoader isLoading={loading} />
          </Modal.Body>
        </Modal>
        {/* End Modal */}

        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-6 col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <p className="card-title">User Activation</p>
                <div className="row">
                  <div className="col-12 activ-user">
                    <div className="form-group mb-1">
                      <label>Email</label>
                      <div className="input-group">
                        <input type="email" className="form-control" placeholder="Email" pattern="[A-Za-z0-9]+" onKeyDown={allowEmailChars} onChange={(event) => { setUserRoles([]); setUserRoleOptions([]); setRoleForUser(""); setAllowActivation(false); setEmail(event.target.value); }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-end">
                  {associatedUserGroups?.length > 0 ? <div className="col-7 mt-2 pl-3 pr-3 text-left d-flex align-items-center"  >
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={(props) => (
                        <Tooltip id="overlay-example" className="associate-tooltip" {...props}>
                          {associatedUserGroups?.map(item => item?.GroupName)?.join(", ")}
                        </Tooltip>
                      )}
                    >
                      <div className="d-flex align-items-center">  <HiOutlineInformationCircle className="mr-1 hover-cursor" size={12} color="#000" /><p className="text-black-sm mb-0 hover-cursor" >Assigned Groups Name</p></div>
                    </OverlayTrigger></div> : <></>}
                  <div className="col-5 mt-2 pl-3 pr-3 text-right">
                    <button type="button" className="btn btn-primary btnone-minwidth" disabled={validateEmail(email) === false} onClick={findUser}>Find</button>
                  </div>
                </div>

                {allowActivation &&
                  <div className="row">
                    <div className="col-5">
                      <div className="form-group mb-0 mt-3">
                        <label className="mb-0">Status : {userActivationStatus === true ? "Enabled" : "Disabled"}</label>
                        <label>Roles : {userRoles.join(" ,")} </label>                        
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="form-group mb-0 mt-1">
                        <label className="mb-0">Assign a New Role</label>
                        <select className="form-control" id="exampleFormControlSelect2" value={roleForUser} onChange={(event) => {
                          setRoleForUser(event.target.value);
                        }}>
                          {
                            userRoleOptions.map(userRoleOption => (
                              <option key={userRoleOption}>{userRoleOption}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                }

                <div className="row d-flex justify-content-end mt-2">
                  <div className="col-6">
                    <button type="button" className="btn btn-primary btnone-minwidth" disabled={allowActivation === false} onClick={() => setShowGroupManagementModal(true)}>Group Management</button>
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <button type="button" className="btn btn-primary btnone-minwidth mr-1" disabled={allowActivation === false} onClick={activateUser}>Activate</button>
                    <button type="button" className="btn btn-primary btnone-minwidth" disabled={allowActivation === false} onClick={async (e) => {
                      e.preventDefault();
                      setEvtShowModalYesNo({ "params": { "evt": e }, "option": "delete-user" });
                      setShowModalYesNoMsg(`You are about to delete user ${email}. Do you want to continue?`);
                      setShowModalYesNo(true);
                    }}>Delete</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-6 col-12 grid-margin stretch-card reports-block">
            <div className="card">
              <div className="card-body">
                <p className="card-title">Reports</p>
                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <label>From</label>
                    <DatePicker
                      placeholderText="dd/mm/yyyy"
                      className="form-control date-fld"
                      selected={displayReportFromDate}
                      dateFormat="dd/MM/yyyy HH:mm aa"
                      showTimeSelect
                      maxDate={new Date()}
                      onChange={async(event) => {
                        const fromDateTimeFormat =
                          moment(event).format("YYYY-MM-DD HH:mm:ss");
                          let newReportFrom = await getDateAndTime(fromDateTimeFormat);
                        if (reportToDate !== "") {
                          if (fromDateTimeFormat <= newRepToDate) {
                            setDisplayReportFromDate(event);
                            setReportFromDate(moment(newReportFrom).format("YYYY-MM-DD HH:mm:ss"));
                            setNewRepFromDate(fromDateTimeFormat);
                          } else {
                            setDisplayReportFromDate(event);
                            setReportFromDate("");
                            setNewRepFromDate("");
                          }
                        } else {
                          setDisplayReportFromDate(event);
                          setReportFromDate(moment(newReportFrom).format("YYYY-MM-DD HH:mm:ss"));
                          setNewRepFromDate(fromDateTimeFormat);
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <label>To</label>
                    <DatePicker
                      placeholderText="dd/mm/yyyy"
                      className="form-control date-fld"
                      selected={displayReportToDate}
                      dateFormat="dd/MM/yyyy HH:mm aa"
                      showTimeSelect
                      minDate={displayReportFromDate || null} 
                      maxDate={new Date()}
                      onChange={async(event) => {
                        const toDateTimeFormat =
                          moment(event).format("YYYY-MM-DD HH:mm:ss");
                        const newReportTo = await getDateAndTime(toDateTimeFormat);
                        if (reportFromDate !== "") {
                          if (toDateTimeFormat >= newRepFromDate) {
                            setDisplayReportToDate(event);
                            setReportToDate(moment(newReportTo).format("YYYY-MM-DD HH:mm:ss"));
                            setNewRepToDate(toDateTimeFormat);
                          } else {
                            setDisplayReportToDate("");
                            setReportToDate("");
                            setNewRepToDate("");
                          }
                        } else {
                          setDisplayReportToDate(event);
                          setReportToDate(moment(newReportTo).format("YYYY-MM-DD HH:mm:ss"));
                          setNewRepToDate(toDateTimeFormat);
                        }
                      }}
                    />
                  </div>

                </div>
                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <label>Search Based on</label>
                    <select className="form-control form-control-md" id="exampleFormControlSelect2" 
                    disabled={reportToDate.length === 0}
                    value={searchBased} 
                    onChange={async (event) => {
                      setSearchBased(event.target.value);
                      await checkDayLightMode(event.target.value);
                    }}>
                      {
                        timeZoneOptions && timeZoneOptions.map(zone => (
                          <option key={zone}>{zone === serverTimeZone ? `${serverTimeZone} - Default` : zone}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <div className="row justify-content-between">
                  <div className="mt-2 pl-3 pr-3 d-flex align-items-center">
                    <div className="showarchive d-flex align-items-center mr-2">
                      <input type="checkbox" id="Show-archived-checkbox" className="border-left-0"
                       onChange={() => { setShowArchived(!showArchived); }} checked={showArchived} 
                      />
                      <label className="mb-0 ml-1">Archived Submissions</label>
                    </div>
                    <button type="button" className="btn btn-primary btn-minwidth" disabled={!reportFromDate || !reportToDate || reportFromDate?.toLowerCase() === 'invalid date' || reportToDate?.toLowerCase() === 'invalid date' || reportFromDate > reportToDate} onClick={async (e) => {
                      setEvtShowModalYesNo({ "params": { "evt": e }, "option": "create-report" });
                      setShowModalYesNoMsg(`You are about to generate reports for date ${newRepFromDate} to ${newRepToDate} with ${searchBased} Time Zone. Do you want to continue?`);
                      setShowModalYesNo(true);
                    }}>Generate Report</button>
                  </div>
                  <div className="mt-2 pl-3 pr-3">
                    <button type="button" className="btn btn-primary btn-minwidth" onClick={async () => {
                      setLoading(true);
                      await fetchReportsData(true);
                      setShowReports(true);
                      setLoading(false);
                    }}>View Reports</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover submissions-listing-management" style={{ overflowY: "inherit" }}>
              <Card.Header className="pl-0 pr-0">
                <Card.Title className="mb-0">
                  <div className="row mx-0">
                    <div className="col-12">
                      <h4 className="card-title d-inline mt-1">Line of Business</h4>
                      <div className="row mt-2 mx-0">
                        <div className="col-lg-1 col-md-1 col-sm-2 col-3 pl-0 pr-1">
                          <input type="text" className="form-control form-control-md" maxLength="8" placeholder="LOB Code" 
                            onChange={async(event) => { 
                              setSearchCodeAndTemp(false);
                              setLOBCode(event.target.value); 
                              setCreateOrEditMode(event.target.value);
                              onSearchTextChange(event);
                              setTemplateName("");
                              if (event.target.value.length > 0 && templateName === "") {
                                await fetchTemplates(true);
                              }
                            }} 
                            value={lobCode} 
                            onKeyDown={async (e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                onSearchFetchList(e);
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-3 pl-1 pr-1">
                          <input type="text" className="form-control form-control-md" placeholder="Line of Business" onChange={(event) => { setLOBText(event.target.value); }} value={lobText} />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-3 col-12 lob-dd pl-1 pr-1">
                          {assocCtryCodeOptions.length && <MultiSelect
                            closeMenuOnSelect={false}
                            value={lobCreateOrEditMultiselectSelected}
                            onChange={(state) => {
                              setAssocCtryCodes(state.map(el => el.value))
                              setLobCreateOrEditMultiselectSelected(state)
                            }}
                            isMulti
                            placeholder={<div className="d-flex-center">Select...<span className="multiselect-badge">0</span></div>}
                            isSearchable={false}
                            hideSelectedOptions={false}
                            options={assocCtryCodeOptions.map(countryObj => ({ label: countryObj.label, value: countryObj.value }))}
                            styles={{
                              option: (provided, { isSelected, isFocused }) => ({
                                ...provided,
                                backgroundColor: (isSelected && '#e6f4fa') || (isFocused && 'rgb(240, 240, 240)') || 'transparent',
                                color: (isSelected && '#0351ff') || (isFocused && '#9a9a9a') || '#9a9a9a',
                                fontSize: '12px',
                                marginBottom: '5px',
                                textTransform: 'uppercase',
                                fontWeight: 400,
                                padding: '10px 0px',
                                '&:hover': {
                                  backgroundColor: 'rgb(240, 240, 240)',
                                  color: isSelected ? '#0351ff' : '#9a9a9a',
                                },
                              }),
                              dropdownIndicator: (base, state) => ({
                                ...base,
                                transition: "all .2s ease",
                                transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
                              })
                            }}
                            components={{
                              ValueContainer,
                              Option: ({ children, isSelected, ...props }) => {
                                return (
                                  <ReactSelectComponents.Option {...props} isSelected={isSelected}>
                                    {children}
                                    {isSelected ? <span className="check_icon"></span> : null}
                                  </ReactSelectComponents.Option>
                                );
                              }
                            }}
                          />}
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-1 col-3 pl-1 pr-1"><button type="button" className="btn btn-primary btn-md font-weight-medium mr-1" disabled={lobCode.trim().length === 0 || lobText.trim().length === 0 || (enableCountryCodes === true && assocCtryCodes.length === 0)} onClick={createOrUpdateLOBCode}>{createMode}</button></div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-3 d-flex justify-content-end pl-0">
                          <button type="button" className="btn btn-primary btn-md font-weight-medium ml-1" onClick={async(e) => {
                            setLoading(true);
                            setAddCcodeModal(true);
                            setLoading(false);
                          }}>Add Country Code</button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row mx-0 d-flex justify-content-end">
                        <div className="col-12 text-right mt-4 pagnatn">
                          <button type="button" disabled={currentLOBCodePage === 0} className="btn btn-primary btn-sm font-weight-medium mr-2" onClick={async (e) => {
                            e.preventDefault();
                            setLoading(true);
                            currentLOBCodePage = currentLOBCodePage - 1;
                            setCurrentLOBCodePage(currentLOBCodePage);
                            if (searchCodeAndTemp === true) {
                              let searchLOBList = await fetchLOBCodeList(lobCode,"",false);
                              setLOBCodes(searchLOBList);
                            } else {
                              await fetchLOBCodes(false);
                            }
                            pageInputRef.current.value = ''; // clear the text field
                            setLoading(false);
                          }}>Prev</button>
                          <p className="d-inline-block gotopage mr-1">
                            <input type="number" autoComplete="off" maxLength={4} className="form-control" placeholder="Go to Page" ref={pageInputRef} onKeyDown={async (e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                setLoading(true);
                                let totalLobCodeCount = (Math.ceil(lobCodeCount / pageSize));
                                if (e.target.value <= 0) {
                                  e.target.value = 1;
                                }
                                if (e.target.value > totalLobCodeCount) {
                                  e.target.value = totalLobCodeCount;
                                }
                                let pageNumber = parseInt(e.target.value);
                                currentLOBCodePage = pageNumber - 1;
                                setCurrentLOBCodePage(currentLOBCodePage);
                                if (searchCodeAndTemp === true) {
                                  let searchLOBList = await fetchLOBCodeList(lobCode,"",false);
                                  setLOBCodes(searchLOBList);
                                } else {
                                  await fetchLOBCodes(false);
                                }
                                setLoading(false);
                              }
                            }} />
                          </p>
                          <p className="d-inline">Page {currentLOBCodePage + 1} of {lobCodeCount === -1 ? 1 : Math.ceil(lobCodeCount / pageSize) === 0 ? 1 : Math.ceil(lobCodeCount / pageSize)}</p>
                          <button type="button" disabled={((currentLOBCodePage + 1) === (Math.ceil(lobCodeCount / pageSize))) || (lobCodeCount === 0)} className="btn btn-primary btn-sm font-weight-medium ml-2" onClick={async (e) => {
                            e.preventDefault();
                            setLoading(true);
                            currentLOBCodePage = currentLOBCodePage + 1;
                            setCurrentLOBCodePage(currentLOBCodePage);
                            if (searchCodeAndTemp === true) {
                              let searchLOBList = await fetchLOBCodeList(lobCode,"",false);
                              setLOBCodes(searchLOBList);
                            } else {
                              await fetchLOBCodes(false);
                            }
                            pageInputRef.current.value = ''; // clear the text field
                            setLoading(false);
                          }}>Next</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive pt-1">
                <DataGrid columns={lobColumns} rows={lobCodes} defaultColumnOptions={{ resizable: true }}
                  rowKeyGetter={rowLOBKeyGetter}
                  rowHeight={45}
                  className="fill-grid"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover submissions-listing-management">
              <Card.Header className="pl-1 pr-1">
                <Card.Title className="row mx-0 mb-0">
                  <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                    <h4 className="card-title d-inline mt-1">Template Management</h4>
                    <div className="row mt-2">
                      <div className="col-lg-4 col-sm-6 col-12">
                        <input type="text" className="form-control form-control-md" id="templateNameInput" placeholder="Template Name" 
                          onChange={async(event) => { 
                            setSearchCodeAndTemp(false);
                            setTemplateName(event.target.value); 
                            onSearchTextChange(event);
                            setLOBCode("");
                            setLOBText("");
                            setLobCreateOrEditMultiselectSelected([]);
                            if (event.target.value.length > 0 && lobCode === "") {
                              await fetchLOBCodes(true);
                            }
                          }} 
                          value={templateName} 
                          onKeyDown={async (e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              onSearchFetchList(e);
                            }
                          }}
                        />
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <Form.Control
                          placeholder="Browse Template"
                          type="file"
                          id="templateFile"
                          accept={allowedExtensions}
                          multiple={false}
                          disabled={false}
                          size="md"
                          className="select-template"
                          onChange={(e) => {
                            onBrowseTemplate(e);
                          }}
                        ></Form.Control>
                      </div>
                      <div className="col-lg-4 col-sm-12 col-12">
                        <button id="uploadBtn" type="button" className="btn btn-primary btn-md font-weight-medium" disabled={templateName === '' ||templateFileName.trim().length === 0} onClick={createTemplate}>Upload Template</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-right mt-4 pagnatn">
                    <button type="button" disabled={currentTemplatePage === 0} className="btn btn-primary btn-sm font-weight-medium mr-2" onClick={async (e) => {
                      e.preventDefault();
                      setLoading(true);
                      currentTemplatePage = currentTemplatePage - 1;
                      setCurrentTemplatePage(currentTemplatePage);
                      if (searchCodeAndTemp === true) {
                        let searchTempList = await fetchTemplateList(templateName);
                        setTemplates(searchTempList);
                      } else {
                        await fetchTemplates(false);
                      }
                      setLoading(false);
                      pageInputRef.current.value = ''; // clear the text field
                    }}>Prev</button>
                    <p className="d-inline-block gotopage mr-1">
                      <input type="number" autoComplete="off" maxLength={4} className="form-control" placeholder="Go to Page" ref={pageInputRef} onKeyDown={async (e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          setLoading(true);
                          let totalTemplateCount = (Math.ceil(templateCount / pageSize));
                          if (e.target.value <= 0) {
                            e.target.value = 1;
                          }
                          if (e.target.value > totalTemplateCount) {
                            e.target.value = totalTemplateCount;
                          }
                          let pageNumber = parseInt(e.target.value);
                          currentTemplatePage = pageNumber - 1;
                          setCurrentTemplatePage(currentTemplatePage);
                          if (searchCodeAndTemp === true) {
                            let searchTempList = await fetchTemplateList(templateName);
                            setTemplates(searchTempList);
                          } else {
                            await fetchTemplates(false);
                          }
                          setLoading(false);
                        }
                      }} />
                    </p>
                    <p className="d-inline">Page {currentTemplatePage + 1} of {templateCount === -1 ? 1 : Math.ceil(templateCount / pageSize) === 0 ? 1 : Math.ceil(templateCount / pageSize)}</p>
                    <button type="button" disabled={((currentTemplatePage + 1) === (Math.ceil(templateCount / pageSize))) || (templateCount === 0)} className="btn btn-primary btn-sm font-weight-medium ml-2" onClick={async (e) => {
                      e.preventDefault();
                      setLoading(true);
                      currentTemplatePage = currentTemplatePage + 1;
                      setCurrentTemplatePage(currentTemplatePage);
                      if (searchCodeAndTemp === true) {
                        let searchTempList = await fetchTemplateList(templateName);
                        setTemplates(searchTempList);
                      } else {
                        await fetchTemplates(false);
                      }
                      setLoading(false);
                      pageInputRef.current.value = ''; // clear the text field
                    }}>Next</button>
                  </div>
                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive">
                <DataGrid columns={templateColumns} rows={templates} defaultColumnOptions={{ resizable: true }}
                  rowKeyGetter={rowTemplateKeyGetter}
                  rowHeight={40}
                  className="fill-grid"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        { 
          <UploadFileManagement />
        }

        {/* Mini Modal */}
        <Modal
          className="modal-mini modal-primary"
          show={showModal}
          onHide={() => setShowModal(false)}
          animation={false}
          centered={true}
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile">
              <i className="nc-icon nc-bulb-63"></i>
            </div>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>{showModalMsg}</p>
          </Modal.Body>
          <div className="modal-footer justify-content-center">
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={() => setShowModal(false)}
            >
              Close
            </Button>
          </div>
        </Modal>
        {/* End Modal */}

        {/* Mini Modal */}
        <Modal
          className="modal-large modal-primary mt-3"
          show={showReports}
          onHide={() => setShowReports(false)}
          animation={false}
        >
          <Modal.Body className="text-center">
            <h5>View Reports</h5>
            <hr></hr>
            <div className="col-12">
              <div className="table-responsive">
                <Table striped>
                  <thead>
                    <tr>
                      <th>From Date</th>
                      <th>To Date</th>
                      <th>Report</th>
                      <th>Generated By</th>
                      <th>Generated Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData && reportData.map(item => (
                      <React.Fragment key={`ReportsData_${item.ReportsID}`}>
                        <tr>
                          <td>{item.FromDate}</td>
                          <td>{item.ToDate}</td>
                          <td>
                            <a href="/#" onClick={async (e) => {
                              e.preventDefault();
                              const result = await Storage.get(`reports/${item.ReportsS3FullPath}`, { download: true, expires: 60 });
                              downloadBlob(result.Body, item.ReportsS3FullPath);
                            }}>
                              {item.ReportsS3FullPath}
                            </a>
                          </td>
                          <td>{item.GeneratedBy}</td>
                          <td>{item.CreateDate}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="col-12 d-flex justify-content-end mt-4 pagnatn">
                <button type="button" disabled={currentReportPage === 0} className="btn btn-primary btn-sm font-weight-medium mr-2" onClick={async (e) => {
                  e.preventDefault();
                  setLoading(true);
                  currentReportPage = currentReportPage - 1;
                  setCurrentReportPage(currentReportPage);
                  await fetchReportsData(false);
                  setLoading(false);
                }}>Prev</button>
                <p className="d-inline-block mt-2">Page {currentReportPage + 1} of {reportCount === -1 ? 1 : Math.ceil(reportCount / reportPageSize) === 0 ? 1 : Math.ceil(reportCount / reportPageSize)}</p>
                <button type="button" disabled={((currentReportPage + 1) === (Math.ceil(reportCount / reportPageSize))) || (reportCount === -1)} className="btn btn-primary btn-sm font-weight-medium ml-2" onClick={async (e) => {
                  e.preventDefault();
                  setLoading(true);
                  currentReportPage = currentReportPage + 1;
                  setCurrentReportPage(currentReportPage);
                  await fetchReportsData(false);
                  setLoading(false);
                }}>Next</button>
              </div>
            </div>

          </Modal.Body>
          <div className="modal-footer justify-content-center">
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={() => setShowReports(false)}
            >
              Close
            </Button>
          </div>
        </Modal>
        {/* End Modal */}

        {/* Mini Modal */}
        <Modal
          className="modal-mini modal-primary"
          show={showModalYesNo}
          onHide={() => setShowModalYesNo(false)}
          animation={false}
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile">
              <i className="nc-icon nc-bulb-63"></i>
            </div>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>{renderReactFragment(showModalYesNoMsg)}</p>
          </Modal.Body>
          <div className="modal-footer justify-content-center">
            <button type="button" className="btn btn-secondary btn-md font-weight-medium" onClick={async () => {
              if (evtShowModalYesNo.option === "delete") {
                if (evtShowModalYesNo.type === "LOBCode") {
                  deleteLobCode(evtShowModalYesNo.params.evt, evtShowModalYesNo.params.row);
                }
                else if (evtShowModalYesNo.type === "Template") {
                  deleteTemplate(evtShowModalYesNo.params.evt, evtShowModalYesNo.params.row);
                }
              } else if (evtShowModalYesNo.option === "create-report") {
                setLoading(true);
                await createReport(true);
                setLoading(false);
              } else if (evtShowModalYesNo.option === "delete-user") {
                setLoading(true);
                await deleteUser();
                setLoading(false);
              } else if (evtShowModalYesNo.option === "delete-user-group") {
                setLoading(true);
                await deleteUserGroups(evtShowModalYesNo.params.row.user_profile_id);
                setLoading(false);
              }
              setShowModalYesNo(false);
            }}>Yes</button>
            <button type="button" className="btn btn-primary btn-md font-weight-medium" onClick={() => {
              setShowModalYesNo(false);
              if (evtShowModalYesNo.option === "upload-docs") {
                evtShowModalYesNo.params.target.value = null;
              }
              setEvtShowModalYesNo(null);
            }}>No</button>
          </div>
        </Modal>
        {/* End Modal */}

        {/* Mini Modal */}
        <Modal
          className="modal-md modal-primary"
          show={excelFormatModal}
          onHide={() => setExcelFormatModal(false)}
          animation={false}
        >
          <Modal.Body className="text-center">
            <p>{renderReactFragment(excelFormatModalMsg)}</p>
          </Modal.Body>
          <div className="modal-footer justify-content-center">
            <button type="button" className="btn btn-secondary btn-md font-weight-medium" onClick={async () => {
              setExcelFormatModal(false);
            }}>OK</button>
          </div>
        </Modal>
        {/* End Modal */}

        {/* Mini Modal for adding country codes*/}
        <Modal
          className="modal-md modal-primary add-country-code"
          show={addCcodeModal}
          onHide={() => setAddCcodeModal(false)}
          animation={false}
        >
          <Modal.Body className="text-center">
            <h5 className="mb-3">Add Country Codes</h5>
            <div className="row">
              <div className="col-sm-6 col-12">
                <h6>Country Code</h6>
                <input type="text" id='countryCodeField' className="form-control form-control-md" placeholder="Enter Here" onChange={(e) => {
                  e.preventDefault();
                  const ccValue = e.target.value;
                  if (ccValue.length > 0 && ccValue.length < 6) {
                    setCcvalue(ccValue);
                  } else {
                    setCcInvalidModal(true);
                  }
                }} />
              </div>
              <div className="col-sm-6 col-12">
                <h6>Country</h6>
                <input type="text" id="countryField" className="form-control form-control-md" placeholder="Enter Here" onChange={(e) => {
                  e.preventDefault();
                  setCountryCodeText(e.target.value);
                }} />
              </div>
            </div>
          </Modal.Body>
          <div className="modal-footer justify-content-center">
          <button type="button" id="addCountryCodeBtn" className="btn btn-primary btn-md font-weight-medium" disabled={ccValue.length < 4 || ccValue.match(/[!@#$%^&*(),.?":{}|<>]/) || countryCodeText.match(/[0-9!@#$%^&*(),.?":{}|<>]/) || countryCodeText.length === 0} onClick={async (e) => {
              setCcvalue(ccValue);
              var countryCodes = await fetchCountryCodes();
              let countryCodesValues = countryCodes.find((ccList) => ccList.countryCode === ccValue);
              if (countryCodesValues === undefined) {
                var ccResult = await createCountryCode();
                setCcSuccessModal(true);
                setAddCcodeModal(false);
                console.log("ccResult",ccResult);
              } else {
                setCcWarningModal(true);
                setAddCcodeModal(false);
              }
            }}>Add</button>
            <button type="button" className="btn btn-secondary btn-md font-weight-medium" onClick={async () => {
              setAddCcodeModal(false);
              setCcvalue("");
              setCountryCodeText("");
            }}>Cancel</button>
          </div>
        </Modal>
        {/* End Modal */}

        {/* Mini Modal */}
        <Modal
          className="modal-md modal-primary cc-status"
          show={ccWarningModal}
          onHide={() => setCcWarningModal(false)}
          animation={false}
        >
          <Modal.Body className="text-center">
            <h5>Country Code {ccValue} already present. Give a different value for the Country Code</h5>
          </Modal.Body>
          <button type="button" className="btn btn-primary btn-sm font-weight-medium mb-3" onClick={async () => {
              setCcWarningModal(false);
              setCcvalue("");
              setCountryCodeText("");
          }}>Close</button>
        </Modal>
        {/* End Modal */}

        {/* Mini Modal */}
        <Modal
          className="modal-md modal-primary cc-status"
          show={ccSuccessModal}
          onHide={() => setCcSuccessModal(false)}
          animation={false}
        >
          <Modal.Body className="text-center">
            <h5>Country Code {ccValue} Successfully added.</h5>
          </Modal.Body>
          <button type="button" className="btn btn-primary btn-sm font-weight-medium mb-3" onClick={async () => {
              setCcSuccessModal(false);
              setCcvalue("");
              setCountryCodeText("");
          }}>Close</button>
        </Modal>
        {/* End Modal */}

        {/* Mini Modal */}
        <Modal
          className="modal-md modal-primary cc-status"
          show={ccInvalidModal}
          onHide={() => setCcInvalidModal(false)}
          animation={false}
        >
          <Modal.Body className="text-center">
            <ul className="cc-invalid-points">
              <li>Country Code length should not be more than 5 characters</li>
              <li>Country Code and Country fields should not be empty</li>
              <li>No Special characters are allowed</li>
              <li>Minimum 4 characters should be entered</li>
            </ul>
          </Modal.Body>
          <button type="button" className="btn btn-primary btn-sm font-weight-medium mb-3" onClick={async () => {
              setCcInvalidModal(false);
              setCcvalue("");
              setCountryCodeText("");
          }}>Close</button>
        </Modal>
        {/* End Modal */}

        {/* Mini Modal */}
        <Modal
          className="modal-large modal-primary mt-3"
          show={showGroupManagementModal}
          onHide={handleClose}
          backdrop="static"
          animation={false}
        >
          <Modal.Body className="text-center">
            <div className="row">
              <div className="col-6 text-left grpuser-title"><h5>Group Management</h5></div>
              <div className="col-6 text-right"><p className="user-email">{email}</p></div>
            </div>
            <hr></hr>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-2 col-md-3 col-sm-6 col-6 mb-1 pl-1 pr-1">
                <label className="mb-0 d-block text-left">Group Name</label>
                <select className="form-control dropdown-listoflobs" id="listOfGroupNames"
                  value={groupName}
                  disabled={ (!enableLobCode && !enableCountryCodes) || associatedUserGroups?.some(group => group?.GroupName?.toLowerCase() === 'default')}
                  onChange={async(event) => {
                    if(event.target.value === "Select") {
                      setGroupName("");
                      await fetchAssocUserGroups();
                    } else {
                      if(event.target.value === "Default") {
                        setLOBCodeDisplay("Select");
                        setAssocCtryCodes([]);
                        setAssocCtryCodeOptions([]);
                        setLobCreateOrEditMultiselectSelected([]);
                      }
                      setGroupName(event.target.value);
                      await fetchAssocUserGroups(event.target.value);
                    }
                }}>
                  <option key="Select">{"Select"}</option>
                  { groupNamesList && groupNamesList?.map(grpName => (
                      <option key={grpName}>{grpName}</option>
                    ))
                  }
                </select>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 col-6 mb-1 pl-1 pr-1">
                <label className="mb-0 d-block text-left">LOB Code</label>
                <select className="form-control dropdown-listoflobs" id="listOfLobs" 
                  disabled={groupName === "" || associatedUserGroups?.some(group => group?.GroupName?.toLowerCase() === 'default' && group?.CountryCode?.toLowerCase() === 'default' && group?.LOBCode?.toLowerCase() === 'default')}
                  value={lobCodeDisplay} 
                  onChange={(event) => {
                  if (event.target.value === "Select" || event.target.value === "Default") {
                    setAssocCtryCodes([]);
                    setAssocCtryCodeOptions([]);
                    setLobCreateOrEditMultiselectSelected([]);
                    setLOBCodeDisplay(event.target.value);
                  } else {
                    let findlob = lobCodeOptions.find((findlob) => findlob.LOBCode === event.target.value);
                    // Convert AssocCountryCodes to an array of objects
                    let findcccode = findlob.AssocCountryCodes.split(',').filter(code => code.trim() !== "").map(cccode => ({
                      label: cccode,
                      value: cccode
                    }));
                    setAssocCtryCodeOptions(findcccode);
                    setLOBCode(lobCodeOptions[event.target.selectedIndex - 1].LOBCode);
                    setLOBCodeDisplay(event.target.value);
                  }
                }}>
                  <option key="Select">{"Select"}</option>
                  <option key="Default">{"Default"}</option>
                  {
                    groupName !== "Default" && lobCodeOptions && lobCodeOptions.map(lobCodeOption => (
                      <option key={lobCodeOption.LOBCode} disabled={associatedUserGroups?.some(opt => opt?.LOBCode?.toLowerCase() === 'default') && lobCodeOption?.LOBCode?.toLowerCase() !== 'default'}>{`${lobCodeOption.LOBCode}`}</option>
                    ))
                  }
                </select>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-6 lob-dd pl-1 pr-1">
                <label className="mb-0 d-block text-left">CC Code <HiOutlineInformationCircle className="ml-1" size={12} color="#000" data-toggle="tooltip" data-placement="bottom" title="CC Code = Country Code" /></label>
                <MultiSelect
                  closeMenuOnSelect={false}
                  value={lobCreateOrEditMultiselectSelected}
                  onChange={(state) => {
                    setAssocCtryCodes(state.map(el => el.value));
                    setLobCreateOrEditMultiselectSelected(state);
                  }}
                  isMulti
                  placeholder={<div className="d-flex-center">Select...<span className="multiselect-badge">0</span></div>}
                  isSearchable={false}
                  hideSelectedOptions={false}
                  isDisabled={groupName === "" || lobCodeDisplay === "Select"}
                  options={
                    enableCountryCodes
                      ? [{ label: 'Default', value: 'Default' }, ...assocCtryCodeOptions.map(countryObj => ({ label: countryObj.value, value: countryObj.value, isDisabled:(associatedUserGroups?.some(option => option?.CountryCode?.toLowerCase() === 'default' || option?.LOBCode?.toLowerCase() === 'default') )  }))]
                      : [{ label: 'Default', value: 'Default' }]
                  }
                  styles={{
                    option: (provided, { isSelected, isFocused, isDisabled }) => ({
                      ...provided,
                      backgroundColor: (isSelected && '#e6f4fa') || (isFocused && 'rgb(240, 240, 240)') || 'transparent',
                      color: (isSelected && '#000') || (isFocused && '#000') || (isDisabled ? '#9a9a9a' : '#000'),
                      fontSize: '12px',
                      marginBottom: '5px',
                      fontWeight: 400,
                      padding: '10px 0px',
                      '&:hover': {
                        backgroundColor: 'rgb(240, 240, 240)',
                        color: isSelected ? '#0351ff' : '#9a9a9a',
                      },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      transition: "all .2s ease",
                      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
                    })
                  }}
                  components={{
                    ValueContainer,
                    Option: ({ children, isSelected, ...props }) => {
                      return (
                        <ReactSelectComponents.Option {...props} isSelected={isSelected}>
                          {children}
                          {isSelected ? <span className="check_icon"></span> : null}
                        </ReactSelectComponents.Option>
                      );
                    }
                  }}
                />
                {!enableCountryCodes &&<span className="text-black-xs">CCOde is disabled and hence only Default value is shown.</span>}
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 col-6 mt-4">
                <button type="button" className="btn btn-primary btnone-minwidth"
                  disabled={groupName === "" || lobCodeDisplay === "Select" || assocCtryCodes.length === 0}
                  onClick={async() => {
                    let groupList=[];
                    assocCtryCodes.forEach(ccode => groupList.push({GroupName:groupName, LOBCode:lobCodeDisplay, CountryCode:ccode}));
                    await handleAssociate(groupList);
                  }}
                >Associate</button>
              </div>
            </div>
            {associatedUserGroups?.some(group => group?.GroupName?.toLowerCase() === 'default' && group?.CountryCode?.toLowerCase() === 'default' && group?.LOBCode?.toLowerCase() === 'default') && <div class="alert alert-danger mx-3 my-3" role="alert">
              In order to associate the user to a New Group, first need to delete the Default group assigned.
            </div>}
            {allAssociatedUserGroups?.length === 0  && <div class="alert alert-danger mx-3 my-3" role="alert">
              A user must be associated with at least one group
            </div>}
            <div className="col-12 mt-3">
              <p className="d-flex justify-left">Associated Group Names List</p>
              <div className="table table-responsive grp-table">
                <DataGrid 
                  columns={groupNamesColumn} rows={associatedUserGroups}
                  defaultColumnOptions={{ resizable: true }}
                  rowKeyGetter={rowGroupNameKeyGetter}
                  rowHeight={45}
                  className="fill-grid"
                />
              </div>
            </div>

          </Modal.Body>
          <div className="modal-footer justify-content-center">
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              disabled={allAssociatedUserGroups?.length > 0 ? false : true}
              onClick={() => {
                setShowGroupManagementModal(false);
                handleResetState();
              }}
            >
              Close
            </Button>
          </div>
        </Modal>
        {/* End Modal */}
        
        {/* Mini Modal */}
        <Modal
          className="modal-md modal-primary"
          show={showGroupModal}
          onHide={() => setShowGroupModal(false)}
          animation={false}
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile">
              <i className="nc-icon nc-bulb-63"></i>
            </div>
          </Modal.Header>
          <Modal.Body className="d-flex justify-content-center">
            <ul>
              {(asscoiateResp?.createdGroupName?.length > 0 || asscoiateResp?.existingGroups?.length > 0 )&&
                <li>
                  <span><b>New Groups Associated</b></span>
                  {asscoiateResp?.createdGroupName?.map((grp, index) => (
                    <p key={index} className="mb-0">
                      {`${grp.GroupName} | ${grp.LOBCode} | ${grp.CountryCode}`}
                    </p>
                  ))}
                  {asscoiateResp?.existingGroups?.length > 0 &&
                    asscoiateResp?.existingGroups?.map((grp, index) => { 
                      if(asscoiateResp?.associatedExistGroups.length > 0) {
                        return  asscoiateResp?.associatedExistGroups?.map((assGrp) => (
                          assGrp.group_id !== grp.group_id && <p key={index} className="mb-0">
                            {`${grp.GroupName} | ${grp.LOBCode} | ${grp.CountryCode}`}
                          </p>
                        ))
                      } else {
                        return <p key={index} className="mb-0">{`${grp.GroupName} | ${grp.LOBCode} | ${grp.CountryCode}`}</p>
                      }
                    })
                  }
                </li>
              }
              {asscoiateResp?.associatedExistGroups?.length > 0 &&
                <li className="mt-1">
                  <span><b>Group already associated</b></span>
                  {asscoiateResp?.associatedExistGroups?.map((grp, index) => (
                    <p key={index} className="mb-0">
                      {`${grp.GroupName} | ${grp.LOBCode} | ${grp.CountryCode}`}
                    </p>
                  ))}
                </li>
              }
            </ul>
          </Modal.Body>
          <div className="modal-footer justify-content-center">
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={() => setShowGroupModal(false)}
            >
              Close
            </Button>
          </div>
        </Modal>
        {/* End Modal */}

      </Container>
    </>
  );
}

export default Management;