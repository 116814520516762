import React from "react";
import { useLocation } from "react-router-dom";

import Start from "views/Start";

function StartPage(props) {
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);
  return (
    <>
      <Start {...props}/>
    </>
  );
}

export default StartPage;
