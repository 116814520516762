import React from "react";
import { useState, useEffect } from "react";
// react-bootstrap components
import {
  Modal,
  Container,
  Button
} from "react-bootstrap";
import { Auth } from 'aws-amplify';
import { SSMClient, PutParameterCommand } from "@aws-sdk/client-ssm";

import config from '../aws-exports';
import { getParameterFromManager, updateParameterInManager } from "../services/Utils";
import { invokeLambdaWithRetry } from "../services/Helper";

import "../assets/css/style.css";
import "../assets/vendors/ti-icons/css/themify-icons.css";
import "../assets/vendors/base/vendor.bundle.base.css";
import CirclesLoader from "../layouts/CirclesLoader";

function Settings() {
  const [showModal, setShowModal] = React.useState(false);
  const [showModalMsg, setShowModalMsg] = React.useState("");
  let [loading, setLoading] = useState(false);

  let [settings, setSettings] = useState([
    {
      name: "EXTRACT_MSG_FILE",
      description: "Enable Message File Extraction",
      type: "ParameterStore",
      value: false
    },
    {
      name: "LOB_CODES_VALIDATION",
      description: "Enable LOB Code Validation",
      type: "ParameterStore",
      value: false,
      validateOff: async function isValidData(sets) {
        const search = obj => obj.name === "ENABLE_COUNTRY_CODE_VALIDATION";
        var index = sets.findIndex(search);
        if (sets[index].value === true) {
          setShowModalMsg("Turn off Country Code Validation before turning off LOB Codes");
          setShowModal(true);
          return false;
        }
        return true;
      }
    },
    {
      name: "ENABLE_COUNTRY_CODE_VALIDATION",
      description: "Enable Country Code validation",
      type: "ParameterStore",
      value: false,
      validate: async function isValidData(sets) {
        const search = obj => obj.name === "LOB_CODES_VALIDATION";
        var index = sets.findIndex(search);
        if (sets[index].value === false) {
          setShowModalMsg("LOB Codes must be enabled to turn on Country Code Validation");
          setShowModal(true);
          return false;
        }
        return true;
      }
    },
    {
      name: "USE_TEMPLATE",
      description: "Enable Template Usage",
      type: "ParameterStore",
      value: false,
      validate: async function isValidData() {
        var available = await areTemplatesAvailable();
        if (available)
          return true;
        setShowModalMsg("There are no templates defined in the system. A minimum of one template is needed to enable usage of template flag.");
        setShowModal(true);
        return false;
      }
    },
    {
      name: "ENABLE_PAYMENT",
      description: "Enable Paypal Payment Options",
      type: "ParameterStore",
      value: false
    },
    {
      name: "DisableUpload",
      description: "Disable users from uploading files",
      type: "PortalConfig",
      value: false
    },
    {
      name: "AUTOCOMPLETE_CFG.enable_auto_complete",
      description: "Auto Complete Submission",
      type: "ParameterStore",
      value: false
    },
    {
      name: "ANTIVIRUSSCAN_SUBMISSIONS",
      description: "Anti Virus Scan Submissions",
      type: "ParameterStore",
      value: false
    },
    {
      name: "ANTIVIRUSSCAN_OTHERS",
      description: "Anti Virus Scan other attachments",
      type: "ParameterStore",
      value: false
    },
    {
      name: "DISPLAY_FMT_QA_BTN",
      description: "Display Format QA Option",
      type: "ParameterStore",
      value: false
    },
    {
      name: "DISPLAY_SEND_QA_DATA",
      description: "Display Send QA Data Option",
      type: "ParameterStore",
      value: false
    },
    {
      name: "NOTIFY_SUBMISSION_PROCESSED",
      description: "Send Notification on Submission getting processed",
      type: "ParameterStore",
      value: false
    },
    {
      name: "USE_ADOBE_FOR_MERGE",
      description: "Use Adobe for performing Merge",
      type: "ParameterStore",
      value: false
    },
    {
      name: "USE_ADOBE_FOR_MERGE_FAILURES",
      description: "Use Adobe for performing Merge when normal merge fails",
      type: "ParameterStore",
      value: false
    },
    {
      name: "USE_EB_FOR_NOTIFICATIONS",
      description: "Use Event Bridge Queue to send Team Notifications, instead of direct",
      type: "ParameterStore",
      value: false
    },
    {
      name: "ENABLE_TOUR",
      description: "Enable Tour",
      type: "ParameterStore",
      value: false
    },
    {
      name: "ENABLE_QA",
      description: "Enable QA",
      type: "ParameterStore",
      value: true
    },
    {
      name: "AI_FLOW_CONTROLLER.enable_ai",
      description: "Enable AI",
      type: "ParameterStore",
      value: true
    },
    {
      name: "ENABLE_TRIAGE",
      description: "Enable Triage",
      type: "ParameterStore",
      value: false
    },
    {
      name: "ENABLE_QA_ROW_BUTTONS",
      description: "QA Row Buttons",
      type: "ParameterStore",
      value: false
    },
    {
      name: "SHOW_NEW_QA_TOOL",
      description: "Show New QA Tool",
      type: "ParameterStore",
      value: false
    },
    {
      name: "SUPPORT_MULTI_EDIT",
      description: "Enable Multi Edit",
      type: "ParameterStore",
      value: false
    },
    {
      name: "SHOW_CLAIMS_UI",
      description: "Show claims",
      type: "ParameterStore",
      value: true
    }
  ]);

  async function getPortalConfig(configName) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });

      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "getConfigValue",
        "configName": configName
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      return data.response;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function areTemplatesAvailable() {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });

      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "areTemplatesAvailable"
      };
      const init = {
        body: body
      };
      const data = await invokeLambdaWithRetry("instanceHandler", init);
      return data.response;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  const putParam = async (param, value, credentials, region, keyId) => {
    console.log(`${param}: ${value}`);
    let paramName = "";
    if (param.includes(".")) {
      let startParam = param.split(".");
      paramName = startParam[0] + "-" + startParam[1].split("-")[1];
    }
    const parameterStore = new SSMClient({
      apiVersion: '2014-11-06',
      region: region,
      credentials: credentials
    });

    try {
      const data = await parameterStore.send(
        new PutParameterCommand({
          Name: param?.includes(".") ? paramName : param,
          Overwrite: true,
          Value: value.toString(),
          KeyId: keyId,
          Type: keyId === undefined ? "String" : "SecureString"
        })
      );
      if (param.includes(".")) {
        await updateParameterInManager(paramName, JSON.stringify(value));
      } else {
        await updateParameterInManager(param, value);
      }
      return data;
    } catch (err) {
      throw err;
    }
  }

  async function getParameterStore(configName) {
    let value = await getParameterFromManager(configName);
    return value;
  }

  async function putParameterStore(configName, configVal, keyId) {
    var credentials = await Auth.currentCredentials();
    var backend = config.aws_user_files_s3_bucket.split('-');
    // invokeOCRFromPF attribute must depend on Enable AI
    if (configName.includes('AI_FLOW_CONTROLLER') && typeof configVal === 'string') {
      let newParsed = JSON.parse(configVal);
      newParsed.invokeOCRFromPF = newParsed.enable_ai;
      await putParam(`${configName}-${backend[backend.length - 1]}`, JSON.stringify(newParsed), credentials, config.aws_project_region, keyId);
    } else {
      await putParam(`${configName}-${backend[backend.length - 1]}`, configVal, credentials, config.aws_project_region, keyId);
    }
  }

  async function toggleValue(nameVal) {
    const newSettings = settings.map(a => ({ ...a }));
    const search = obj => obj.name === nameVal;
    var index = newSettings.findIndex(search);
    if (newSettings[index].validate && newSettings[index].value === false && (await newSettings[index].validate(settings) === false)) {
      return;
    }
    if (newSettings[index].validateOff && newSettings[index].value === true && (await newSettings[index].validateOff(settings) === false)) {
      return;
    }
    newSettings[index].value = !newSettings[index].value;
    setSettings(newSettings);
  }

  let saveSettings = async (event) => {
    event.preventDefault();
    setLoading(true);

    for (var i = 0; i < settings.length; i++) {
      if (settings[i].type === "ParameterStore") {
        if (settings[i].name.includes(".")) {
          let res = await getParameterFromManager(settings[i].name.split(".")[0]);
          res[settings[i].name.split(".")[1]] = settings[i].value;
          res = JSON.stringify(res, null, 2);
          await putParameterStore(settings[i].name, res);
        } else {
          await putParameterStore(settings[i].name, settings[i].value);
        }
      } else {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });

        var body = {
          "access_token": user.signInUserSession.idToken.jwtToken,
          "target": "updateConfigValue",
          configName: settings[i].name,
          configValue: settings[i].value
        };
        const init = {
          body: body
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        console.log(`updateConfigValueMutation : ${JSON.stringify(data)}`);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    (async function initF() {
      setLoading(true);
      const newSettings = settings.map(a => ({ ...a }));
      for (var i = 0; i < newSettings.length; i++) {
        if (newSettings[i].type === "ParameterStore") {
          if (newSettings[i].name.includes(".")) {
            let res = await getParameterStore(newSettings[i].name.split(".")[0]);
            newSettings[i].value = res[newSettings[i].name.split(".")[1]];
          } else {
            newSettings[i].value = await getParameterStore(newSettings[i].name);
          }
        } else {
          let portalConfigValue = await getPortalConfig(newSettings[i].name);
          newSettings[i].value = JSON.parse(portalConfigValue);
        }
      }
      setSettings(newSettings);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <Container fluid>
        {/* Mini Modal */}
        <Modal
          className="modal-centre"
          backdrop="static"
          keyboard={false}
          show={loading}
          animation={false}
          centered
        >
          <Modal.Body className="text-center">
            <CirclesLoader isLoading={loading} />
          </Modal.Body>
        </Modal>
        {/* End Modal */}

        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <p className="card-title">System Settings</p>
                <div className="row row-setting">
                  {settings.map(item => (
                    <React.Fragment key={item.name}>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <div className="input-group">
                            <input type="checkbox" id={item.name} className="form-control border-left-0"
                              onChange={() => {
                                toggleValue(item.name);
                              }}
                              checked={item.value}
                            />
                          </div>
                          <label>{item.description}</label>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                <div className="row justify-content-end">
                  <div className="mt-2 pl-3 pr-3">
                    <button type="button" className="btn btn-primary" id="save-checkbox-settings" onClick={saveSettings}>Save Settings</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mini Modal */}
        <Modal
          className="modal-mini modal-primary"
          show={showModal}
          onHide={() => setShowModal(false)}
          animation={false}
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile">
              <i className="nc-icon nc-bulb-63"></i>
            </div>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>{showModalMsg}</p>
          </Modal.Body>
          <div className="modal-footer justify-content-center">
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={() => setShowModal(false)}
            >
              Close
            </Button>
          </div>
        </Modal>
        {/* End Modal */}
      </Container>
    </>
  );
}

export default Settings;
