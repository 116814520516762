import { LambdaClient, InvokeCommand, waitUntilFunctionActiveV2 } from "@aws-sdk/client-lambda";
import React from "react";
import { Auth, Storage } from 'aws-amplify';
import { v4 as uuid } from "uuid";
import config from '../aws-exports';
import * as XLSX from "xlsx";

const wait = (ms) => new Promise((res) => setTimeout(res, ms));

const callWithRetry = async (fn, reqopt, fixedSleep = undefined, depth = 0) => {
  try {
    return await fn(reqopt);
  } catch (e) {
    if (depth > 9) {
      console.error(`callWithRetry: exhausted`);
      throw e;
    }
    if (fixedSleep === undefined) {
      await wait(2 ** depth * 300);
    } else {
      await wait(fixedSleep);
    }

    return callWithRetry(fn, reqopt, fixedSleep, depth + 1);
  }
}

function deepJsonParse(obj) {
  if (typeof obj === 'string') {
    try {
      obj = JSON.parse(obj);
    } catch (e) {
      return obj; // Return the original string if it's not JSON
    }
  }

  if (typeof obj === 'object' && obj !== null) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = deepJsonParse(obj[key]);
      }
    }
  }

  return obj;
}

async function invokeLambda(reqopt) {
  const credentials = await Auth.currentCredentials();
  const lambdaClient = new LambdaClient({
    credentials: Auth.essentialCredentials(credentials),
    region: config.aws_project_region
  });
  const command = new InvokeCommand(reqopt);

  try {
    const data = await lambdaClient.send(command);
    const resp = reqopt.InvocationType ? data : deepJsonParse(JSON.parse(uint8Decode(data.Payload)));
    // console.log(`${reqopt.FunctionName} lambda response is ${JSON.stringify(resp)}`);// successful response
    if (resp?.error || resp?.errorMessage) {
      console.error(`Error in response from ${reqopt.FunctionName} lambda: ${JSON.stringify(resp)}`);
      throw new Error(`Error in response from ${reqopt.FunctionName} lambda: ${JSON.stringify(resp)}`);
    }
    return resp;
  } catch (error) {
    console.error(`Invoking ${reqopt.FunctionName} Lambda err: ${JSON.stringify(error)}`);
    if (error.name === "CodeArtifactUserPendingException") {
      console.log(`${reqopt.FunctionName} is not in Active state, Waiting for a lambda to Active`)
      // wait for the function transit from Pending to Active
      const lambdaResponse = await waitUntilFunctionActiveV2({
        client: lambdaClient,
        maxWaitTime: 300
      }, {
        FunctionName: reqopt.FunctionName
      });
      console.log(`${reqopt.FunctionName} Lambda status is ${lambdaResponse?.reason?.Configuration?.State} now and calling ${reqopt.FunctionName} lambda again with params: ${JSON.stringify(reqopt)}`);
      return invokeLambda(reqopt);
    }
    throw error;
  }
}

export async function invokeLambdaWithRetry(functionName, payload, invocationType = undefined, fixedSleep = undefined) {
  const backend = config.aws_user_files_s3_bucket.split('-');
  let reqopt = {
    FunctionName: `${functionName}-${backend[backend.length - 1]}`,
    Payload: JSON.stringify(payload)
  }

  if (invocationType) {
    reqopt.InvocationType = invocationType
  }
  // console.log(`Invoking Lambda with retry option using the following request: ${JSON.stringify(reqopt)}`);
  var result = await callWithRetry(invokeLambda, reqopt, fixedSleep);
  return result;
}

export function getDynamicTVName(tabOrViewName, nostrip) {
  var region = config.aws_project_region.replace(/-/g, "_");
  var backend = config.aws_user_files_s3_bucket.split('-');
  var backendenv = backend[backend.length - 1];
  if (nostrip)
    return `meadatastore_${region.replace(/-/g, "_")}_${backendenv}.${tabOrViewName}`;
  return `meadatastore_${region.replace(/-/g, "_")}_${backendenv}.${tabOrViewName.replace(/fqso/g, "")}`;
}

export async function signOutUser() {
  const currentUser = Auth.userPool.getCurrentUser();
  if (currentUser !== null) {
    await currentUser.signOut();
  }
  await Auth.signOut();
}

export function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}

export async function downloadExcel(buffer, fileName) {
  const newWorkbook = XLSX.utils.book_new();
  const workbook = XLSX.read(buffer, { type: 'array', cellNF: true });
  for (let i = 0; i < workbook.SheetNames.length; i++) {
    let sheetName = workbook.SheetNames[i];
    let originalSheet = workbook.Sheets[sheetName];

    if (sheetName === "Combined Sheet") {
      XLSX.utils.book_append_sheet(newWorkbook, originalSheet, "Combined Sheet");
    } else {
      XLSX.utils.book_append_sheet(newWorkbook, originalSheet, sheetName);
    }
  }
  XLSX.writeFile(newWorkbook, fileName);
}

export async function loadS3File(jsonPath) {
  const jsonS3DataRes = await Storage.get(jsonPath, { download: true });
  var json_data = await jsonS3DataRes.Body.text();
  var data = JSON.parse(json_data);
  return data;
}

export function isEmailChar(name) {
  var format = /[!#$%^&*()_+\-=[\]{};':"\\|,<>/?]+/;
  if (format.test(name)) {
    return false;
  }
  return true;
}

export async function fetchMsg(subID) {
  try {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    const body = {
      "access_token": user.signInUserSession.idToken.jwtToken,
      "target": "fetchMsg",
      "submissionIdentifier": subID
    };
    const init = {
      body: body
    };
    const resp = await invokeLambdaWithRetry("instanceHandler", init);
    return resp.response;
  } catch (err) {
    console.error(err);
    return false;
  }
}

export async function fetchDocument(docID) {
  try {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    const body = {
      "access_token": user.signInUserSession.idToken.jwtToken,
      "target": "fetchDocument",
      "document_id": docID
    };
    const init = {
      body: body
    };
    const resp = await invokeLambdaWithRetry("instanceHandler", init);
    return resp.response;
  } catch (err) {
    console.error(err);
    return false;
  }
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export async function setUserVault(password, code, given_name, family_name, phone_number, company, country, groups, user_sub) {
  try {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    var user_type = "cog_int_user";
    if (user?.attributes?.identities !== undefined) {
      var identities = JSON.parse(user.attributes.identities);
      if (identities[0].providerType === "OIDC") {
        user_type = "ext_user";
      }
    }
    console.log(user_type);
    const body = {
      "access_token": user.signInUserSession.idToken.jwtToken,
      "option": "register-profile",
      "passcode": password,
      "mfasetupcode": code,
      "given_name": given_name,
      "family_name": family_name,
      "phone_number": phone_number,
      "company": company,
      "country": country,
      "groups": groups,
      "user_type": user_type,
      "user_sub": user_sub,
      "Active": 1
    };
    const init = {
      body: JSON.stringify(body)
    };
    const resp = await invokeLambdaWithRetry("sysUserManager", init);
    console.log("setUserVault resp:", resp);
  } catch (err) {
    if (err === "The user is not authenticated") {

    }
    console.error(err);
    return false;
  }
  return true;
}

export function renderReactFragment(showModalYesNoMsg) {
  var showModalYesNoMsgParts = showModalYesNoMsg.split("<b>");
  var msgParts = [];
  for (var i = 0; i < showModalYesNoMsgParts.length; i++) {
    var partParts = showModalYesNoMsgParts[i].split("</b>");
    if (partParts.length === 1) {
      msgParts.push({ "bold": false, msg: partParts[0] });
    } else {
      msgParts.push({ "bold": true, msg: partParts[0] });
      msgParts.push({ "bold": false, msg: partParts[1] });
    }
  }
  return (
    <>
      {msgParts.map((item, key) => (
        <React.Fragment key={key}>
          {!item.bold && item.msg}
          {item.bold && <b>{item.msg}</b>}
        </React.Fragment>
      ))}
    </>
  );
}

export async function isExistingTemplate(srchTemplate) {
  try {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    const body = {
      "access_token": user.signInUserSession.idToken.jwtToken,
      "count": -1,
      "rowStart": 0,
      "pageSize": 2,
      "searchTemplateName": srchTemplate,
      "mode": "exact-no-data"
    };
    const init = {
      body: JSON.stringify(body)
    };
    const data = await invokeLambdaWithRetry("fetchTemplateList", init);
    console.log("isExistingTemplate resp:", data);
    return (data.count >= 1)
  } catch (err) {
    console.error('err: ', err);
    return false;
  }
}

export async function isExistingLOBCode(srchLOBCode) {
  try {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    const body = {
      "access_token": user.signInUserSession.idToken.jwtToken,
      "count": -1,
      "rowStart": 0,
      "pageSize": 2,
      "searchLOBCode": srchLOBCode,
      "mode": "exact-no-data"
    };
    const init = {
      body: JSON.stringify(body)
    };
    const data = await invokeLambdaWithRetry("fetchLOBCodeList", init);
    return (data.count === 1)
  } catch (err) {
    console.error('err: ', err);
    return false;
  }
}

export async function processUpload(submissionIdentifier, templateName, inputFiles, selectedGroup) {
  try {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    const body = {
      "access_token": user.signInUserSession.idToken.jwtToken,
      "submissionIdentifier": submissionIdentifier,
      "templateName": templateName,
      "inputFiles": inputFiles,
      "option": "process_upload",
      "group_id": selectedGroup,
      "source": "UI"
    };
    const init = {
      body: JSON.stringify(body)
    };
    const resp = await invokeLambdaWithRetry("extractDocument", init);
    console.log(`extractDocument processUpload response : ${JSON.stringify(resp)}`);
    return resp;
  } catch (err) {
    console.error('err: ', err);
    return false;
  }
}

export function isTemplateAllowed(documentName) {
  if (documentName.toLowerCase().endsWith(".msg"))
    return true;
  if (documentName.toLowerCase().endsWith(".eml"))
    return true;
  if (documentName.toLowerCase().endsWith(".pdf"))
    return true;
  if (documentName.toLowerCase().endsWith(".docx"))
    return true;
  if (documentName.toLowerCase().endsWith(".pptx"))
    return true;
  if (documentName.toLowerCase().endsWith(".xlsx"))
    return true;
  if (documentName.toLowerCase().endsWith(".doc"))
    return true;
  if (documentName.toLowerCase().endsWith(".ppt"))
    return true;
  if (documentName.toLowerCase().endsWith(".xls"))
    return true;
  return false;
}

export async function cptood(submissionIdentifier, filePath, fileName, movefolder) {
  try {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    const body = {
      "filePath": filePath !== undefined ? `public/${filePath}` : undefined,
      "access_token": user.signInUserSession.idToken.jwtToken,
      "SubmissionId": submissionIdentifier,
      "fileName": fileName,
      "correlid": uuid(),
      "option": movefolder === true ? (filePath === undefined ? ["move-folder"] : ["copy-file", "move-folder"]) : ["copy-file"]
    };
    const init = {
      body: JSON.stringify(body)
    };
    const resp = await invokeLambdaWithRetry("cponedrive", init, "Event");
    console.log("cptood resp:", resp);
  } catch (err) {
    console.error('err: ', err);
    return false;
  }
  return true;
}

export async function extractDocument(request, option, altPath, docName, retry, msgDoc, reprocTemplateName, group_id) {
  try {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });

    var body = {};

    if (option === "newupload" || option === "reproc-template" || option === "reproc-template-simple") {
      body = {
        "document_id": request.document_id,
        "access_token": user.signInUserSession.idToken.jwtToken,
        "documentFullPath": request.documentFullPath,
        "documentName": request.documentName,
        "submissionIdentifier": request.submissionIdentifier,
        "username": user.username,
        "option": option,
        "origin_document_id": request.origin_document_id,
        "group_id": group_id
      };
      if (msgDoc === true) {
        body.msgExploded = true;
      }
    } else if (option === "edited") {
      body = {
        "document_id": request.document_id,
        "access_token": user.signInUserSession.idToken.jwtToken,
        "documentFullPath": altPath,
        "documentName": docName,
        "submissionIdentifier": request.submissionIdentifier,
        "option": option,
        "source_extension": request.documentName.split('.')[request.documentName.split('.').length - 1].toLowerCase()
      };
    }

    if (option === "reproc-template" || option === "reproc-template-simple") {
      body.templateName = reprocTemplateName;
      body.isMerged = request.isMerged;
    }

    if (retry === true) {
      body.retry = true;
      body.templateName = reprocTemplateName;
    }

    const init = {
      body: JSON.stringify(body)
    };
    const sResp = await invokeLambdaWithRetry("extractDocument", init);
    console.log(`extractDocument sync response : ${typeof (sResp) === 'object' ? JSON.stringify(sResp) : sResp}`);
  } catch (err) {
    console.error('err: ', err);
    return false;
  }
  return true;
}

export async function notifyUser(request) {
  try {
    var body = {};
    if (request.option === "newDocumentCreated") {
      body = {
        "toAddress": request.toAddress,
        "name": request.name,
        "fileName": request.fileName,
        "option": request.option,
        "submissionIdentifier": request.submissionIdentifier
      };
    } else if (request.option === "documentComplete"
      || request.option === "documentRejected"
      || request.option === "documentInProgress"
      || request.option === "documentDeleted"
      || request.option === "qaInProgress"
      || request.option === "qaComplete"
      || request.option === "qaUploaded"
    ) {
      body = {
        "username": request.username,
        "processorname": request.processorname,
        "fileName": request.fileName,
        "option": request.option,
        "submissionIdentifier": request.submissionIdentifier
      };
    }
    const init = {
      body: JSON.stringify(body)
    };
    const data = await invokeLambdaWithRetry("notifyUserDocStage", init);
    console.log("notifyUser resp:", JSON.stringify(data));
  } catch (err) {
    console.error('err: ', err);
  }
}

var disableUploadVal = undefined;
var disableUploadValTs;
function isCacheValid(cacheValTs) {
  if (cacheValTs === undefined) {
    return false;
  }
  const now = new Date();
  const msBetweenDates = Math.abs(cacheValTs.getTime() - now.getTime());
  const secBetweenDates = msBetweenDates / 1000;
  if (secBetweenDates > 300) {
    return false;
  }
  return true;
}

export async function checkUploadStatus() {
  try {
    if (disableUploadVal === undefined || (isCacheValid(disableUploadValTs) === false)) {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "checkUploadStatus"
      };
      const init = {
        body: body
      };
      const result = await invokeLambdaWithRetry("instanceHandler", init);
      console.log("checkUploadStatus resp:", result);
      disableUploadValTs = new Date();
      disableUploadVal = (result.response?.data?.getConfigValue?.configval === 'true');
      return disableUploadVal;
    } else {
      return disableUploadVal;
    }
  } catch (err) {
    console.error(err);
    return false;
  }
}

export var disableUploadMessage = "Our AI is currently training itself on advanced insurance data and is unavailable for use. Uploading new submissions is paused now, please try again later."

export async function logAudit(document_id, submissionIdentifier, category, txtype, source, data1, data2, templateName, additionalInfo) {
  try {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    var body = {
      "document_id": document_id,
      "submissionIdentifier": submissionIdentifier,
      "Category": category,
      "TxType": txtype,
      "Source": source,
      "Data1": data1,
      "Data2": data2,
      "templateName": templateName,
      "additionalInfo": additionalInfo,
      "access_token": user.signInUserSession.idToken.jwtToken
    };
    const init = {
      body: JSON.stringify(body)
    };
    const data = await invokeLambdaWithRetry("auditor", init, "Event");
    console.log("logAudit resp:", data);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

export function uint8Decode(uint8Array) {
  const decoder = new TextDecoder('utf-8');
  return decoder.decode(uint8Array);
}

export function getMergedMeaValidationCheckRules(meaValidationRules, domain) {
  const commonRules = (meaValidationRules?.defaultDomains?.includes(domain) || domain === 'default' || domain === undefined)
    ? meaValidationRules?.default
    : {};
  const domainRules = meaValidationRules?.[domain] || {};
  return { ...commonRules, ...domainRules };
}