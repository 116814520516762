import React from "react";
import { useState, useEffect } from "react";
// react-bootstrap components
import {
  Modal,
  Button
} from "react-bootstrap";
import { Auth } from "aws-amplify";
import PhoneInput from 'react-phone-input-2'
import { signOutUser } from '../services/Helper';
import rawCountries from './rawCountries';
import 'react-phone-input-2/lib/style.css'
import "../assets/css/style.css";
import "../assets/vendors/ti-icons/css/themify-icons.css";
import "../assets/vendors/base/vendor.bundle.base.css";

function UserProfile() {
  const [showModal, setShowModal] = React.useState(false);
  const [showModalMsg, setShowModalMsg] = React.useState("");
  let [firstName, setFirstName] = useState("");
  let [lastName, setLastName] = useState("");
  let [email, setEmail] = useState("");
  let [company, setCompany] = useState("");
  let [phoneNumber, setPhoneNumber] = useState("");
  let [country, setCountry] = useState("United Kingdom");
  //const history = useHistory();

  let updateProfile = async (event) => {
    event.preventDefault();
    try {
      let user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      await Auth.updateUserAttributes(user, {
        'phone_number': "+" + phoneNumber,
        'custom:country': country
      });
    } catch (err) {
      if (err === "The user is not authenticated") {
        signOutUser();
      } else {
        setShowModalMsg(err.message);
        setShowModal(true);
      }
      console.error(err);
    }
  }

  async function populateUserProfile() {
    Auth.currentAuthenticatedUser({
      bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {
      setEmail(user.attributes.email);
      setFirstName(user.attributes.given_name);
      setLastName(user.attributes.family_name);
      setCompany(user.attributes["custom:company"]);
      setPhoneNumber(user.attributes.phone_number);
      setCountry(user.attributes["custom:country"]);
    }).catch(err => {
      if (err === "The user is not authenticated") {
        signOutUser();
      }
    });
  }

  useEffect(() => {
    populateUserProfile();
  }, []);

  return (
    <>
      <div className="row mx-0">
        <div className="col-12 grid-margin stretch-card user-profile">
          <div className="card">
            <div className="card-body">
              <p className="card-title">Edit</p>
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Email</label>
                    <div className="input-group">
                      <input type="email" className="form-control form-control-lg" placeholder="Email" value={email} disabled onChange={(event) => { setEmail(event.target.value); }} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Company</label>
                    <input type="text" className="form-control form-control-lg" placeholder="Company" value={company} disabled onChange={(event) => { setCompany(event.target.value); }} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>First Name</label>
                    <div className="input-group">
                      <input type="text" className="form-control form-control-lg" placeholder="First Name" value={firstName} disabled onChange={(event) => { setFirstName(event.target.value); }} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Last Name</label>
                    <div className="input-group">
                      <input type="text" className="form-control form-control-lg" placeholder="Last Name" value={lastName} disabled onChange={(event) => { setLastName(event.target.value); }} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12 ph-num">
                  <div className="form-group">
                    <label>Phone Number</label>
                    <PhoneInput
                      country='gb'
                      dropdownclassName=''
                      preferredCountries={['gb', 'us', 'in']}
                      preserveOrder={['preferredCountries']}
                      value={phoneNumber}
                      onChange={(phNumber) => {
                        setPhoneNumber(phNumber);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12 country-dd">
                  <div className="form-group">
                    <label>Country</label>
                    <select className="form-control form-control-lg" id="exampleFormControlSelect2" value={country} onChange={(event) => {
                      setCountry(event.target.value);
                    }}>
                      {
                        rawCountries.map(country => (
                          <option key={country[0]}>{country[0]}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="mt-2 pl-3 pr-3">
                  <button type="button" className="btn btn-primary" onClick={updateProfile}>Update</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Mini Modal */}
      <Modal
        className="modal-mini modal-primary"
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={false}
      >
        <Modal.Header className="justify-content-center">
          <div className="modal-profile">
            <i className="nc-icon nc-bulb-63"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>{showModalMsg}</p>
        </Modal.Body>
        <div className="modal-footer justify-content-center">
          <Button
            className="btn-simple"
            type="button"
            variant="link"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
      {/* End Modal */}
    </>
  );
}

export default UserProfile;
