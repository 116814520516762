import React from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Container, Nav, Button, OverlayTrigger } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { useState, useEffect } from "react";
import { FaUsers } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai"
import { useTour } from "@reactour/tour";
import Tooltip from 'react-bootstrap/Tooltip';
import { invokeLambdaWithRetry } from '../../services/Helper';

import routes from "routes.js";
import { getParameterFromManager } from "../../services/Utils";

function Header({ submissionModal, setSubmissionModal }) {
  const location = useLocation();
  const { isOpen } = useTour();
  let [username, setUsername] = useState("");
  let [authType, setAuthType] = useState("mea");
  let [userRole, setUserRole] = useState("MEA_USERS")
  const [associatedUserGroups, setAssociatedUserGroups] = useState([]);
  //for the showClaimsUI
  const [showClaims, setshowClaims] = useState(false);

  useEffect(() => {
    loadAuthType();
    setUser();
  },[]);

  useEffect(() => {
    fetchAssocUserGroups();
  }, [location]);

  var authTypePayload = undefined;
  async function loadAuthType() {
    try {
      if(authTypePayload === undefined) {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });

        var body = {
          "access_token": user.signInUserSession.idToken.jwtToken,
          "target": "getConfigValue",
          configName: "auth_type"
        };
        const init = {
          body: body
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        authTypePayload = data;
      }
      
      setAuthType(authTypePayload.response);
      return authTypePayload;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function setUser() {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      setUserRole(user?.signInUserSession?.idToken?.payload?.["cognito:groups"]);
      if(user?.attributes?.email !== undefined) {
        setUsername(user?.attributes?.email);
      }
    } catch (err) {
      console.error(err);
    }
  }

  function reqFedLogout(userAttributes) {
    if (authType === "mea")
      return false;
    const index = userAttributes.findIndex(userAttribute => {
      return userAttribute.Name === "email" && userAttribute.Value.toLowerCase().endsWith("@meaplatform.com");
    });
    if (index !== -1)
      return false;
    return true;
  }

  async function handleLogout() {
    var user = Auth.userPool.getCurrentUser();
    if (user && user.storage && user.storage[user.userDataKey] && JSON.parse(user.storage[user.userDataKey]).UserAttributes && reqFedLogout(JSON.parse(user.storage[user.userDataKey]).UserAttributes)) {
      await Auth.signOut();
    } else {
      await user.signOut();
    }
  }

  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return location.pathname === "/user/submissions"
  };

  const dispaySubmissionButton = () => {
    return location.pathname === "/user/submissions" || location.pathname === "/user/claims" ? true : false
  };

  const checkUserRole = () => {
    try {
      if (userRole.includes('MEA_PROCESSORS')) {
        return false;
      }
      else if (userRole.includes('MEA_USERS') || userRole.includes('MEA_CORPORATE') || userRole.includes('MEA_ADMIN')) {
        return true;
      }
      else {
        return false;
      }
    } catch (err) {
      console.error(err);
    }
  }
  //for the SHOW_CLAIMS_UI 
  useEffect(() => {
      (async function initF() {
        let showClaimsPS = await getParameterFromManager("SHOW_CLAIMS_UI");
        setshowClaims(showClaimsPS);
      })();
  });

  async function fetchAssocUserGroups() {
    try {
      let user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      if(!user?.attributes){
        user = await Auth.currentAuthenticatedUser({
          bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
      }
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "option": "getGroupsList",
        "userEmail": user?.attributes?.email
      };

      const init = {
        body: JSON.stringify(body)
      };
      const data = await invokeLambdaWithRetry("activateUserAndAddToGroup", init);
      setAssociatedUserGroups(data?.associateUserGroups || []);
      return data?.associateUserGroups || [];
    } catch (err) {
      console.error('err: ', err);
      return false;
    }
  }

  return (
    <Navbar bg="light" expand="lg">
      <Container fluid className="p-0">
        <div className="d-flex justify-content-center align-items-center mt-2 ml-2 ml-lg-0">
          <Button
            variant="outline-primary"
            className="d-lg-none d-flex justify-content-center align-items-center p-2"
            onClick={mobileSidebarToggle}
          >
            {/* <i className="fas fa-ellipsis-v"></i> */}
            <i className="fas fa-bars"></i>
          </Button>
          <div>
            { checkUserRole() && dispaySubmissionButton() &&
              <Button
                onClick={() => !isOpen && setSubmissionModal(!submissionModal)}
                id="newSubmissionBtn" 
                className="btn btn-primary btn-sm font-weight-medium newSubmission-btn"
                data-tour="step-1"
              >
                <AiOutlinePlus /> <span>New {showClaims ? "Claim" : "Submission"}</span>
              </Button>
            }
            <Navbar.Brand
              onClick={(e) => e.preventDefault()}
            >
              {getBrandText()}
            </Navbar.Brand>
          </div>
          </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          {/* <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span> */}
          <i className="far fa-user"></i>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav mr-auto" navbar>
            <Nav.Item>
              <Nav.Link
                data-toggle="dropdown"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                className="m-0"
              >
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Nav className="ml-auto d-flex align-items-baseline user-info" navbar>
            <Nav.Item className="mt-4 " >
              <FaUsers className="mr-3 user-name" />
              <span className="no-icon" id="loggedInUser">{username}</span>
               {associatedUserGroups?.length > 0 ? <div className="d-inline-block ml-1 pb-1">
                <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => (
                  <Tooltip id="overlay-example" className="associate-tooltip associate-tooltip-bottom" {...props}>
                    {associatedUserGroups?.map(item => item?.GroupName)?.filter((value, index, self) => value && self.indexOf(value) === index)?.join(", ")}
                  </Tooltip>
                )}
              >
               <span className="hover-cursor black-txt-hypelinks">{`[ group${associatedUserGroups?.map(item => item?.GroupName)?.filter((value, index, self) => value && self.indexOf(value) === index)?.length > 1 ?`s(${associatedUserGroups?.map(item => item?.GroupName)?.filter((value, index, self) => value && self.indexOf(value) === index)?.length}) `: ` (${associatedUserGroups?.map(item => item?.GroupName)?.filter((value, index, self) => value && self.indexOf(value) === index)?.length}) `} ]` }</span> 
              </OverlayTrigger></div> : <></>}
            </Nav.Item>
            <Nav.Item className="mt-1 btn-search-refresh">
              <Nav.Link
                id="logoutLink"
                className="m-0"
                href="/"
                onClick={(e) => {
                  handleLogout();
                }}
              >
                <span className="btn btn-primary btn-md btn-reduce">
                  Logout
                </span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
