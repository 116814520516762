import React from "react";
// react-bootstrap components
import {
  Modal,
  Button
} from "react-bootstrap";
import { useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';

import { isEmailChar, validateEmail } from '../services/Helper';
import logo from '../assets/images/logo.png';
import "../assets/css/style.css";
import "../assets/vendors/ti-icons/css/themify-icons.css";
import "../assets/vendors/base/vendor.bundle.base.css";
import 'react-phone-input-2/lib/style.css'

function User() {
  const [showModal, setShowModal] = React.useState(false);
  const [showModalMsg, setShowModalMsg] = React.useState("");
  let [password, setPassword] = useState("");
  let [showPassword, setShowPassword] = useState(false);
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [lastName, setLastName] = useState("");
  let [authenticationCode, setAuthenticationCode] = useState("");
  let [step, setStep] = useState(0);
  const history = useHistory();
  const [captchaResponse, setCaptchaResponse] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  
  const handleCaptchaChange = (response) => {
    setCaptchaResponse(response);
  };

  function convertToUserName(email) {
    return email;
  }

  const allowEmailChars = e => {
    if (isEmailChar(e.key) === false) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  let signUp = async (event) => {
    event.preventDefault();
    
    if (email.trim().length === 0) {
      setShowModalMsg("Email is required");
      setShowModal(true);
      return;
    }
    if (password.trim().length === 0) {
      setShowModalMsg("Enter password");
      setShowModal(true);
      return;
    }
    if (validateEmail(email) !== true) {
      setShowModalMsg("Enter valid email");
      setShowModal(true);
      return;
    }
    try {
      let signupParams = 
        {
          username: email, password: password,
          attributes: {
            given_name: name,
            family_name: lastName,
            email: email,
            phone_number: "+919999999999",
            'custom:company': "mea",
            'custom:tandc_accepted': 'true',
            'custom:country': "United Kingdom"
          },
          clientMetadata: {
            "captcha": JSON.stringify({ captchaResponse }),
          }
        };
      await Auth.signUp(signupParams);
      setStep(1);
    } catch (err) {
      setShowModalMsg(err.message);
      setShowModal(true);
      console.log('error signing up :', err);
    }
  }

  let confirmSignUp = async (event) => {
    event.preventDefault();
    var username = convertToUserName(email);
    if (username.trim().length === 0)
      return;
    if (authenticationCode.trim().length === 0)
      return;
    try {
      await Auth.confirmSignUp(username, authenticationCode)
        .then(async (data) => {
          let path = `/login`;
          history.push(path);
        })
        .catch((err) => {
          setShowModalMsg(err.message);
          setShowModal(true);
          return;
        });
    } catch (err) {
      console.log('error confirming signing up :', err);
      setShowModalMsg(err.message);
      setShowModal(true);
    }
    let path = `/login`;
    history.push(path);
  }

  const redirectToLogin = () => {
    let path = `/login`;
    history.push(path);
  }

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper d-flex align-items-center">
          <div className="content-wrapper auth-pages auth">
            <div className="auth-form-transparent">
            <div className="row mx-0">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
                  <div className="p-3 login-form">
                    <h3>register</h3>
                    {step === 0 &&
                      <form className="pt-3">
                        <div className="form-group">
                          <div className="input-group" style={{ position: 'relative' }}>
                            <input type="email" className="form-control border-left-0" placeholder="email" pattern="[A-Za-z0-9]+" onKeyDown={allowEmailChars} 
                            onFocus={() => setShowTooltip(true)}  // Show tooltip on focus
                            onChange={(event) => {
                              setEmail(event.target.value);
                              // Extract the part before the @ symbol
                              const namePart = event.target.value.split('@')[0];

                              // Split the name part by the dot
                              const nameParts = namePart.split('.');
                              if (nameParts.length > 1) {
                                // If there is a dot, use the parts before and after the dot
                                setName(nameParts[0]);
                                setLastName(nameParts[1]);
                              } else {
                                // If there is no dot, use the entire part as both first and last names
                                setName(namePart);
                                setLastName(namePart);
                              }
                            }}
                            onBlur={() => {
                              // Hide the tooltip when the input loses focus
                              setShowTooltip(false);
                            }}
                            />
                            {showTooltip && (
                              <div className="tooltip">
                                <div className="tooltip-arrow" />
                                Please use your business email address to register with mea platform
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group pos-rel">
                          <div className="input-group">
                            <input type={showPassword ? "text" : "password"} className="form-control border-left-0" id="exampleInputPassword" placeholder="password" pattern="[A-Za-z0-9]+" autoComplete="new-password" onChange={(event) => { setPassword(event.target.value); }} />
                          </div>
                          {password && <span className="show-pwd"><button className="btn p-0 auth-link text-black"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowPassword(!showPassword);
                            }}
                          >
                            {showPassword ? 'hide' : 'show'}
                          </button>
                          </span>}
                        </div>

                        {(process.env.REACT_APP_ENABLE_CAPTCHA !== "false") &&
                          <div className="form-group">
                            <ReCAPTCHA
                              sitekey={process.env.REACT_APP_ENABLE_CAPTCHA}
                              onChange={handleCaptchaChange}
                            />
                          </div>}

                        <div className="row mt-3 ml-3">
                          <div className="col-12">
                            <span className="mb-0 text-black-sm">your password must include the following:</span>
                            <ul className="pwd-inst">
                              <li>8-100 characters</li>
                              <li>upper & lowercase letters</li>
                              <li>at least one number or special character</li>
                            </ul>
                          </div>
                        </div>

                        <div className="mt-4 d-flex justify-content-center">
                          <button type="button" className="btn btn-primary btn-block auth-form-btn" disabled={email.length === 0 || password.length === 0 || captchaResponse.length === 0} onClick={signUp}>authenticate email address</button>
                        </div>

                        <div className="text-center mt-4 font-weight-light">
                          <span className="txt-sm mr-1">already have an account?</span>
                          <button type="button" className="btn text-primary p-0" onClick={redirectToLogin}>login</button>
                        </div>
                      </form>
                    }
                    {step === 1 &&
                      <form className="pt-3">
                        <h5 className="txt-sm text-center mb-3">Authentication code sent to your email address. Confirm the Authentication code to continue</h5>
                        <div className="form-group">
                          <div className="input-group">
                            <input type="email" className="form-control border-left-0" readOnly={true} value={email} placeholder="email" onChange={(event) => { setEmail(event.target.value); }} />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-group">
                            <input type="number" className="form-control border-left-0" id="exampleInputPassword" value={authenticationCode} placeholder="authentication code" onChange={(event) => { setAuthenticationCode(event.target.value); }} />
                          </div>
                        </div>
                        <div className="mt-4 d-flex justify-content-center">
                          <button type="button" className="btn btn-primary btn-block auth-form-btn" disabled={authenticationCode.length < 6} onClick={confirmSignUp}>confirm sign up</button>
                        </div>
                        <div className="text-center mt-4 font-weight-light">
                          <span className="txt-sm mr-1">already have an account?</span>
                          <button type="button" className="btn text-primary p-0" onClick={redirectToLogin}>login</button>
                        </div>
                      </form>
                    }
                  </div>
                </div>
                <div className="col-lg-6 d-flex align-items-center justify-content-center">
                  <div className="brand-logo">
                    <img src={logo} alt="logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid readmea-txt">
          <div className="row">
            <div className="col-12 text-center font-weight-light" data-tour="step-3" >
              <span className="txt-md">read mea </span>
              <label className="form-check-label text-muted">
                <a href={process.env.PUBLIC_URL + '/docs/meaprivpol.pdf'} target="_blank" rel="noreferrer">privacy policies</a>
              </label>
              <span className="txt-md"> and </span>
              <label className="form-check-label text-muted">
                <a href={process.env.PUBLIC_URL + '/docs/meatac.pdf'} target="_blank" rel="noreferrer">terms and conditions</a>
              </label>
            </div>
            <div className="col-12">
              <p className="font-weight-medium text-center">&copy; mea platform limited {new Date().getFullYear()}.</p>
            </div>
          </div>
        </div>
      </div>


      {/* Mini Modal */}
      <Modal
        className="modal-mini modal-primary"
        show={showModal} 
        onHide={() => setShowModal(false)}
        animation={false}
      >
        <Modal.Header className="justify-content-center">
          <div className="modal-profile">
            <i className="nc-icon nc-bulb-63"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>{showModalMsg}</p>
        </Modal.Body>
        <div className="modal-footer justify-content-center">
          <Button
            className="btn-simple"
            type="button"
            variant="link"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
      {/* End Modal */}
    </>
  );
}

export default User;
