import React from "react";
import { useLocation } from "react-router-dom";

import PasswordReset from "views/PasswordReset";

function PasswordResetPage() {
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);
  return (
    <>
      <PasswordReset/>
    </>
  );
}

export default PasswordResetPage;
