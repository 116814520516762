import React, { useEffect, useState, useRef } from "react";
import {
  Form,
  Modal,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import DisplaySubmission from "components/Utils/DisplaySubmission";
import { Auth, Storage } from 'aws-amplify';
import 'react-tabs/style/react-tabs.css';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import { downloadBlob, fetchMsg, renderReactFragment, invokeLambdaWithRetry } from '../services/Helper';
import "../assets/css/style.css";
import "../assets/vendors/ti-icons/css/themify-icons.css";
import "../assets/vendors/base/vendor.bundle.base.css";
import CirclesLoader from "../layouts/CirclesLoader";

function Triage() {
  const triageLanes = [
    "Green",
    "Amber",
    "Red"
  ]
  let [lobCode, setLOBCode] = React.useState("All");
  let [reviewStatus, setReviewStatus] = React.useState("All");
  let [lobCodeDisplay, setLOBCodeDisplay] = React.useState("All");
  let [lobCodeOptions, setLOBCodeOptions] = React.useState([]);
  const [displaySubmission, setDisplaySubmission] = useState(false);
  let [loading, setLoading] = useState(false);
  const [submissionIdentifier, setSubmissionIdentifier] = React.useState("");
  const [comments, setComments] = React.useState("");
  let [triageLane, setTriageLane] = React.useState("Green");
  let [rowCount, setRowCount] = useState(-1);
  let [currentPage, setCurrentPage] = useState(0);
  const pageInputRef = useRef(null);// create a ref for the pagination text field
  let [searchParamsChanged, setSearchParamsChanged] = useState(false);
  const [submissionReports, setSubmissionReports] = useState([]);
  const [showSetReviewed, setShowSetReviewed] = React.useState(false);
  let [selectedSubmissionIdentifier, setSelectedSubmissionIdentifier] = React.useState("");
  let [selectedDocument_id, setSelectedDocument_id] = React.useState("");
  const [showModalYesNo, setShowModalYesNo] = React.useState(false);
  const [showModalYesNoMsg, setShowModalYesNoMsg] = React.useState("");
  const [evtShowModalYesNo, setEvtShowModalYesNo] = React.useState();
  const [showModal, setShowModal] = React.useState(false);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  let [triageCommentsValue,setTriageCommentsValue] = useState()
  const [showModalMsg, setShowModalMsg] = React.useState("");

  let [triageReviewed, setTriageReviewed] = React.useState(false);
  let [switchLane, setSwitchLane] = React.useState("");
  let [declineCodeOptions, setDeclineCodeOptions] = React.useState([]);

  const pageSize = 25;

  var declineCodeOptionsVal = undefined;
  var declineCodeOptionsValTs;  
  function isCacheValid(cacheValTs) {
    if (cacheValTs === undefined) {
        return false;
    }
    const now = new Date();
    const msBetweenDates = Math.abs(cacheValTs.getTime() - now.getTime());
    const secBetweenDates = msBetweenDates / 1000;
    if (secBetweenDates > 300) {
        return false;
    }
    return true;
  }

  useEffect(() => {
    (async function initF() {
      setLoading(true);
      var lobCodeOptions = await fetchLOBCodeList();
      setLOBCodeOptions(lobCodeOptions);
      var declCodeOptions = await fetchDeclineCodeList()
      setDeclineCodeOptions(declCodeOptions);
      setLoading(false);
      setSearchParamsChanged(true);
    })();
  }, []);

  async function fetchDeclineCodeList() {
    try {
      if(declineCodeOptionsVal === undefined || (isCacheValid(declineCodeOptionsValTs) === false)) {
        declineCodeOptionsValTs = new Date();
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
          "access_token": user.signInUserSession.idToken.jwtToken,
          "target": "listTriageDeclinedReasons"
        };
        const init = {
          body: body
        };
        const data = await invokeLambdaWithRetry("instanceHandler", init);
        declineCodeOptionsVal = data.response;
        return data.response;
      } else {
        return declineCodeOptionsVal;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function fetchDocs(submissionIdentifier, document_id) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "target": "fetchDocs",
        "submissionIdentifier": submissionIdentifier,
        "document_id": document_id
      };
      const init = {
        body: body
      };
      const resp = await invokeLambdaWithRetry("instanceHandler", init);
      return resp.response?.data?.listDocumentsAnyWithMsg;
    } catch (err) {
      console.error(err);
      return false;
    }
  }    

  useEffect(() => {
    async function fetchSubmissionReports() {
      try {
        setLoading(true);
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const body = {
          "access_token": user.signInUserSession.idToken.jwtToken,
          "count": rowCount,
          "rowStart": currentPage * pageSize,
          "pageSize": pageSize,
          "datatype": "submissionreports",
          "searchType": (submissionIdentifier.trim().length > 0 || lobCode !== "All" || reviewStatus !== "All") ? "Filter" : "Full",
          "reviewStatus": reviewStatus,
          "triageLane": triageLane,
          "lobCodeFilter": lobCode !== "All" ? lobCode : "",
          "submissionIdFilter": submissionIdentifier.trim().length > 0 ? submissionIdentifier.trim() : ""
        };
        const init = {
          body: JSON.stringify(body)
        };
        const dataPayload = await invokeLambdaWithRetry("fetchDocumentList", init);
        if (rowCount === -1 && dataPayload.count !== undefined) {
          setRowCount(dataPayload.count);
        }
        setSubmissionReports(dataPayload.submissionreports);
      } catch (err) {
        console.error('err: ', err);
        return false;
      } finally {
        setLoading(false);
      }
    }
    if (searchParamsChanged === true) {
      fetchSubmissionReports();
      setSearchParamsChanged(false);
    }
  }, [searchParamsChanged, currentPage, lobCode, rowCount, submissionIdentifier, triageLane, reviewStatus]);

  async function fetchLOBCodeList() {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const body = {
        "access_token": user.signInUserSession.idToken.jwtToken,
        "count": -1,
        "rowStart": 0,
        "pageSize": 100,
        "mode": "like"
      };
      const init = {
        body: JSON.stringify(body)
      };
      const resp = await invokeLambdaWithRetry("fetchLOBCodeList", init);
      return resp.lobCodes;
    } catch (err) {
      console.error('err: ', err);
      return false;
    }
  }

  function formatLobCode(submissionIdentifier) {
    if (submissionIdentifier === null || submissionIdentifier === undefined) {
      return "";
    }
    var lobCode = submissionIdentifier.split("-")[0];
    var lobCodeOptionIndex = lobCodeOptions.filter(obj => {
      return obj.LOBCode.toLowerCase() === lobCode.toLowerCase();
    });
    if (lobCodeOptionIndex.length === 0) {
      return "";
    }
    return `${lobCodeOptionIndex[0].LOBCode}-${lobCodeOptionIndex[0].LineOfBusiness}`;
  }

  function formatDate(uploadedDateTime) {
    if (uploadedDateTime === null || uploadedDateTime === undefined) {
      return "";
    }
    return uploadedDateTime.slice(0, 10);
  }

  function formatDeclineCode(declineCodes) {
    if (declineCodes === null || declineCodes === undefined || declineCodes.trim().length === 0) {
      return "";
    }
    var declCodes = declineCodes.split(',');
    declCodes = declCodes.map(function (declineCode) {
      var declineCodeOpts = declineCodeOptions.filter(obj => {
        return obj.DeclineCode.toLowerCase() === declineCode.toLowerCase();
      });
      if (declineCodeOpts.length === 1) {
        return `${declineCodeOpts[0].DeclinedReason}`;
      }
      return "";
    });
    return declCodes.join(',');
  }

  return (
    <>
      <div className="row">
        <div className="col-12 grid-margin stretch-card user-profile">
          <div className="card">
            <div className="card-body">
              <p className="card-title mb-2">Submissions Queue</p>
              <div className="row mx-0 mr-2 mt-2 d-flex justify-content-start">
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 pl-1 pr-1" data-tour="step-1">
                  <input type="text" autoComplete="off" placeholder="Enter Submission ID" className="form-control form-control-md subid-fld" value={submissionIdentifier} onChange={(event) => { setSubmissionIdentifier(event.target.value); }} />
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 col-3 pl-1 pr-1">
                  <select className="form-control form-control-md" id="exampleFormControlSelect2" value={lobCodeDisplay} onChange={(event) => {
                    if (event.target.selectedIndex === 0) {
                      setLOBCode("All");
                      setLOBCodeDisplay(event.target.value);
                    } else {
                      setLOBCode(lobCodeOptions[event.target.selectedIndex - 1].LOBCode);
                      setLOBCodeDisplay(event.target.value);
                    }
                  }}>
                    <option key="All">{"All"}</option>
                    {
                      lobCodeOptions && lobCodeOptions.map(lobCodeOption => (
                        <option key={lobCodeOption.LOBCode}>{`${lobCodeOption.LOBCode} - ${lobCodeOption.LineOfBusiness}`}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 col-3 pl-1 pr-1">
                  <select className="form-control form-control-md" id="exampleFormControlSelect2" value={reviewStatus} onChange={(event) => {
                    setReviewStatus(event.target.value);
                  }}>
                    <option key="All">{"All"}</option>
                    <option key="Reviewed">{"Reviewed"}</option>
                    <option key="Not Reviewed">{"Not Reviewed"}</option>
                  </select>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-3 pl-2 pr-1 btn-search-refresh">
                  <button type="button" className="btn btn-primary btn-md btn-reduce mr-1" onClick={(e) => {
                    e.preventDefault();
                    setRowCount(-1);
                    setCurrentPage(0);
                    setSearchParamsChanged(true);
                  }}>Search</button>
                  <button type="button" className="btn btn-primary btn-md btn-reduce" onClick={(e) => {
                    e.preventDefault();
                    setSubmissionIdentifier("");
                    setLOBCode("All");
                    setLOBCodeDisplay("All");
                    setRowCount(-1);
                    setCurrentPage(0);
                    setSearchParamsChanged(true);
                  }}>Refresh</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <Tabs onSelect={(index) => {
            setTriageLane(triageLanes[index]);
            setRowCount(-1);
            setCurrentPage(0);
            setSearchParamsChanged(true);
          }}>
            <TabList>
              <Tab>{triageLanes[0]}</Tab>
              <Tab>{triageLanes[1]}</Tab>
              <Tab>{triageLanes[2]}</Tab>
            </TabList>
            <TabPanel>
              <div className="col-12 text-right mt-2 mb-2 p-0">
                <button type="button" className="btn btn-primary btn-sm font-weight-medium mr-2" disabled={currentPage === 0} onClick={async (e) => {
                  e.preventDefault();
                  setCurrentPage(currentPage - 1);
                  setSearchParamsChanged(true);
                  pageInputRef.current.value = ''; // clear the text field
                }}>Prev</button>
                <p className="d-inline-block gotopage mr-1">
                        <input type="number" autoComplete="off" maxLength={4} className="form-control" placeholder="Go to Page" ref={pageInputRef} onKeyDown={async (e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            let totalRowCount = (Math.ceil(rowCount / pageSize));
                            if (e.target.value <= 0 || totalRowCount <= 0) {
                              e.target.value = 1;
                            } else if (e.target.value > totalRowCount) {
                              e.target.value = totalRowCount;
                            }
                            let pageNumber = parseInt(e.target.value);
                            currentPage = pageNumber - 1;
                            setCurrentPage(currentPage);
                            setSearchParamsChanged(true);
                          }
                        }} />
                      </p>
                <p className="d-inline">{currentPage + 1} of {rowCount === -1 ? 1 : Math.ceil(rowCount / pageSize) === 0 ? 1 : Math.ceil(rowCount / pageSize)}</p>
                <button type="button" className="btn btn-primary btn-sm font-weight-medium ml-2" disabled={((currentPage + 1) === (Math.ceil(rowCount / pageSize))) || (rowCount <= 0)} onClick={async (e) => {
                  e.preventDefault();
                  setCurrentPage(currentPage + 1);
                  setSearchParamsChanged(true);
                  pageInputRef.current.value = ''; // clear the text field
                }}>Next</button>
              </div>
              <div className="triage-table">
                <table className="table table-striped sub-que">
                  <thead>
                    <tr>
                      <th scope="col">Submission ID</th>
                      <th scope="col">Source File Name</th>
                      <th scope="col">LOB Code</th>
                      <th scope="col">Date Received</th>
                      <th scope="col">Insured Name</th>
                      <th scope="col">Broker Name</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {submissionReports && submissionReports.map(item => (
                      <tr key={item.submissionIdentifier}>
                        <td onClick={async () => {
                          setSelectedSubmissionIdentifier(item.submissionIdentifier);
                          setSelectedDocument_id(item.document_id);
                          setDisplaySubmission(true);
                        }}>{item.submissionIdentifier}</td>
                        <td>{item.documentName}</td>
                        <td>{formatLobCode(item.submissionIdentifier)}</td>
                        <td>{formatDate(item.UploadedDateTime)}</td>
                        <td>{item.InsuredName}</td>
                        <td>{item.BrokerName}</td>
                        <td>
                          <button type="button" className="btn-action font-weight-medium" onClick={(e) => {
                            e.preventDefault();
                            setTriageCommentsValue(item.TriageComments)
                            setShowCommentsModal(true)
                          }}
                          ><i className="fa fa-comment-dots" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Comments"></i></button>
                          <button type="button" className="btn-action font-weight-medium" onClick={(e) => {
                            e.preventDefault();
                            setTriageReviewed(item.TriageReviewed === 1);
                            setSwitchLane("");
                            setComments("");
                            setSelectedSubmissionIdentifier(item.submissionIdentifier);
                            setSelectedDocument_id(item.document_id);
                            setShowSetReviewed(true);
                          }}
                          ><i className="fa fa-flag-checkered" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Review"></i></button>
                          <button type="button" className="btn-action font-weight-medium" onClick={async (e) => {
                            e.preventDefault();
                            var res;
                            setLoading(true);
                            if(item.document_id === null) {
                              //find that one doc in document which is not Pending Upload and get its details
                              res = await fetchDocs(item.submissionIdentifier);
                            } else {
                              //find doc based on doc_id and get its details
                              //documentName remove _merged.pdf get MsgDoc for subid
                              res = await fetchDocs(undefined, item.document_id);
                            }
                            var s3res = await Storage.get(res[0].uploadDocumentFullPath, { download: true, expires: 60 });
                            downloadBlob(s3res.Body, res[0].uploadDocumentName)
                            setLoading(false);
                          }}
                          ><i className="fa fa-list" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Download Extracted file"></i></button>
                          <button type="button" className="btn-action font-weight-medium" onClick={async (e) => {
                            e.preventDefault();
                            var res;
                            setLoading(true);
                            if(item.document_id === null) {
                              //find that one doc in document which is not Pending Upload and get its details
                              res = await fetchDocs(item.submissionIdentifier);
                            } else {
                              //find doc based on doc_id and get its details
                              //documentName remove _merged.pdf get MsgDoc for subid
                              res = await fetchMsg(item.submissionIdentifier);
                              res = res?.data?.listDocumentsAnyWithMsg;
                            }
                            var s3res = await Storage.get(res[0].documentFullPath, { download: true, expires: 60 });
                            downloadBlob(s3res.Body, res[0].documentName)
                            setLoading(false);
                          }}
                          ><i className="fa fa-download" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Download Original file"></i></button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="col-12 text-right mt-2 mb-2 p-0">
                <button type="button" className="btn btn-primary btn-sm font-weight-medium mr-2" disabled={currentPage === 0} onClick={async (e) => {
                  e.preventDefault();
                  setCurrentPage(currentPage - 1);
                  setSearchParamsChanged(true);
                  pageInputRef.current.value = ''; // clear the text field
                }}>Prev</button>
                <p className="d-inline-block gotopage mr-1">
                        <input type="number" autoComplete="off" maxLength={4} className="form-control" placeholder="Go to Page" ref={pageInputRef} onKeyDown={async (e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            let totalRowCount = (Math.ceil(rowCount / pageSize));
                            if (e.target.value <= 0 || totalRowCount <= 0) {
                              e.target.value = 1;
                            } else if (e.target.value > totalRowCount) {
                              e.target.value = totalRowCount;
                            }
                            let pageNumber = parseInt(e.target.value);
                            currentPage = pageNumber - 1;
                            setCurrentPage(currentPage);
                            setSearchParamsChanged(true);
                          }
                        }} />
                      </p>
                <p className="d-inline">{currentPage + 1} of {rowCount === -1 ? 1 : Math.ceil(rowCount / pageSize) === 0 ? 1 : Math.ceil(rowCount / pageSize)}</p>
                <button type="button" className="btn btn-primary btn-sm font-weight-medium ml-2" disabled={((currentPage + 1) === (Math.ceil(rowCount / pageSize))) || (rowCount <= 0)} onClick={async (e) => {
                  e.preventDefault();
                  setCurrentPage(currentPage + 1);
                  setSearchParamsChanged(true);
                  pageInputRef.current.value = ''; // clear the text field
                }}>Next</button>
              </div>
              <div className="triage-table">
                <table className="table table-striped sub-que">
                  <thead>
                    <tr>
                      <th scope="col">Submission ID</th>
                      <th scope="col">LOB Code</th>
                      <th scope="col">Date Received</th>
                      <th scope="col">Insured Name</th>
                      <th scope="col">Broker Name</th>
                      <th scope="col">Underwriter Review</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {submissionReports && submissionReports.map(item => (
                      <tr key={item.submissionIdentifier}>
                        <td onClick={async () => {
                          setSelectedSubmissionIdentifier(item.submissionIdentifier);
                          setSelectedDocument_id(item.document_id);
                          setDisplaySubmission(true);
                        }}>{item.submissionIdentifier}</td>
                        <td>{formatLobCode(item.submissionIdentifier)}</td>
                        <td>{formatDate(item.UploadedDateTime)}</td>
                        <td>{item.InsuredName}</td>
                        <td>{item.BrokerName}</td>
                        <td>{formatDeclineCode(item.DeclineCode)}</td>
                        <td>
                        <button type="button" className="btn-action font-weight-medium" onClick={(e) => {
                            e.preventDefault();
                            setTriageCommentsValue(item.TriageComments)
                            setShowCommentsModal(true)
                          }}
                          ><i className="fa fa-comment-dots" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Comments"></i></button>
                          <button type="button" className="btn-action font-weight-medium" onClick={(e) => {
                            e.preventDefault();
                            setTriageReviewed(item.TriageReviewed === 1);
                            setSwitchLane("");
                            setComments("");
                            setSelectedSubmissionIdentifier(item.submissionIdentifier);
                            setSelectedDocument_id(item.document_id);
                            setShowSetReviewed(true);
                          }}
                          ><i className="fa fa-flag-checkered" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Review"></i></button>
                          <button type="button" className="btn-action font-weight-medium" onClick={async (e) => {
                            e.preventDefault();
                            var res;
                            setLoading(true);
                            if(item.document_id === null) {
                              //find that one doc in document which is not Pending Upload and get its details
                              res = await fetchDocs(item.submissionIdentifier);
                            } else {
                              //find doc based on doc_id and get its details
                              //documentName remove _merged.pdf get MsgDoc for subid
                              res = await fetchDocs(undefined, item.document_id);
                            }
                            var s3res = await Storage.get(res[0].uploadDocumentFullPath, { download: true, expires: 60 });
                            downloadBlob(s3res.Body, res[0].uploadDocumentName)
                            setLoading(false);
                          }}
                          ><i className="fa fa-list" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Download Extracted file"></i></button>
                          <button type="button" className="btn-action font-weight-medium" onClick={async (e) => {
                            e.preventDefault();
                            var res;
                            setLoading(true);
                            if(item.document_id === null) {
                              //find that one doc in document which is not Pending Upload and get its details
                              res = await fetchDocs(item.submissionIdentifier);
                            } else {
                              //find doc based on doc_id and get its details
                              //documentName remove _merged.pdf get MsgDoc for subid
                              res = await fetchMsg(item.submissionIdentifier);
                              res = res?.data?.listDocumentsAnyWithMsg;
                            }
                            var s3res = await Storage.get(res[0].documentFullPath, { download: true, expires: 60 });
                            downloadBlob(s3res.Body, res[0].documentName)
                            setLoading(false);
                          }}
                          ><i className="fa fa-download" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Download Original file"></i></button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="col-12 text-right mt-2 mb-2 p-0">
                <button type="button" className="btn btn-primary btn-sm font-weight-medium mr-2" disabled={currentPage === 0} onClick={async (e) => {
                  e.preventDefault();
                  setCurrentPage(currentPage - 1);
                  setSearchParamsChanged(true);
                  pageInputRef.current.value = ''; // clear the text field
                }}>Prev</button>
                <p className="d-inline-block gotopage mr-1">
                        <input type="number" autoComplete="off" maxLength={4} className="form-control" placeholder="Go to Page" ref={pageInputRef} onKeyDown={async (e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            let totalRowCount = (Math.ceil(rowCount / pageSize));
                            if (e.target.value <= 0 || totalRowCount <= 0) {
                              e.target.value = 1;
                            } else if (e.target.value > totalRowCount) {
                              e.target.value = totalRowCount;
                            }
                            let pageNumber = parseInt(e.target.value);
                            currentPage = pageNumber - 1;
                            setCurrentPage(currentPage);
                            setSearchParamsChanged(true);
                          }
                        }} />
                      </p>
                <p className="d-inline">{currentPage + 1} of {rowCount === -1 ? 1 : Math.ceil(rowCount / pageSize) === 0 ? 1 : Math.ceil(rowCount / pageSize)}</p>
                <button type="button" className="btn btn-primary btn-sm font-weight-medium ml-2" disabled={((currentPage + 1) === (Math.ceil(rowCount / pageSize))) || (rowCount <= 0)} onClick={async (e) => {
                  e.preventDefault();
                  setCurrentPage(currentPage + 1);
                  setSearchParamsChanged(true);
                  pageInputRef.current.value = ''; // clear the text field
                }}>Next</button>
              </div>
              <div className="triage-table">
                <table className="table table-striped sub-que">
                  <thead>
                    <tr>
                      <th scope="col">Submission ID</th>
                      <th scope="col">LOB Code</th>
                      <th scope="col">Date Received</th>
                      <th scope="col">Insured Name</th>
                      <th scope="col">Broker Name</th>
                      <th scope="col">Declined Reason</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {submissionReports && submissionReports.map(item => (
                      <tr key={item.submissionIdentifier}>
                        <td onClick={async () => {
                          setSelectedSubmissionIdentifier(item.submissionIdentifier);
                          setSelectedDocument_id(item.document_id);
                          setDisplaySubmission(true);
                        }}>{item.submissionIdentifier}</td>
                        <td>{formatLobCode(item.submissionIdentifier)}</td>
                        <td>{formatDate(item.UploadedDateTime)}</td>
                        <td>{item.InsuredName}</td>
                        <td>{item.BrokerName}</td>
                        <td>{formatDeclineCode(item.DeclineCode)}</td>
                        <td>
                        <button type="button" className="btn-action font-weight-medium" onClick={(e) => {
                            e.preventDefault();
                            setTriageCommentsValue(item.TriageComments)
                            setShowCommentsModal(true)
                          }}
                          ><i className="fa fa-comment-dots" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Comments"></i></button>
                          <button type="button" className="btn-action font-weight-medium" onClick={(e) => {
                            e.preventDefault();
                            setTriageReviewed(item.TriageReviewed === 1);
                            setSwitchLane("");
                            setComments("");
                            setSelectedSubmissionIdentifier(item.submissionIdentifier);
                            setSelectedDocument_id(item.document_id);
                            setShowSetReviewed(true);
                          }}
                          ><i className="fa fa-flag-checkered" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Review"></i></button>
                          <button type="button" className="btn-action font-weight-medium" onClick={async (e) => {
                            e.preventDefault();
                            var res;
                            setLoading(true);
                            if(item.document_id === null) {
                              //find that one doc in document which is not Pending Upload and get its details
                              res = await fetchDocs(item.submissionIdentifier);
                            } else {
                              //find doc based on doc_id and get its details
                              //documentName remove _merged.pdf get MsgDoc for subid
                              res = await fetchDocs(undefined, item.document_id);
                            }
                            var s3res = await Storage.get(res[0].uploadDocumentFullPath, { download: true, expires: 60 });
                            downloadBlob(s3res.Body, res[0].uploadDocumentName)
                            setLoading(false);
                          }}
                          ><i className="fa fa-list" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Download Extracted file"></i></button>
                          <button type="button" className="btn-action font-weight-medium" onClick={async (e) => {
                            e.preventDefault();
                            var res;
                            setLoading(true);
                            if(item.document_id === null) {
                              //find that one doc in document which is not Pending Upload and get its details
                              res = await fetchDocs(item.submissionIdentifier);
                            } else {
                              //find doc based on doc_id and get its details
                              //documentName remove _merged.pdf get MsgDoc for subid
                              res = await fetchMsg(item.submissionIdentifier);
                              res = res?.data?.listDocumentsAnyWithMsg;
                            }
                            var s3res = await Storage.get(res[0].documentFullPath, { download: true, expires: 60 });
                            downloadBlob(s3res.Body, res[0].documentName)
                            setLoading(false);
                          }}
                          ><i className="fa fa-download" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Download Original file"></i></button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>

      <DisplaySubmission displaySubmission={displaySubmission} setDisplaySubmission={setDisplaySubmission} setLoading={setLoading} submissionIdentifier={selectedSubmissionIdentifier} document_id={selectedDocument_id}/>
      {/* Mini Modal */}
      <Modal
        className="modal-centre reviewmodal"
        show={showSetReviewed}
        onHide={() => setShowSetReviewed(false)}
        animation={false}
      >
        <Modal.Body className="text-center">
          <Form.Group className="mb-0">
            <Card className="strpied-tabled-with-hover mb-0">
              <Card.Body className="table-responsive">
                <Row className="justify-content-center">
                  <h4 className="modalheading mb-3">Review</h4>
                  <Form.Group className="optcheckbox col-12">
                    <input type="checkbox" className="form-control border-left-0" onChange={() => { setTriageReviewed(!triageReviewed); }} checked={triageReviewed} />
                    <label className="pl-1">Mark Reviewed</label>
                    {triageLane !== "Green" && <><input type="checkbox" value="Green" name="green" onChange={() => {
                      if (switchLane !== "Green") {
                        setSwitchLane("Green");
                      } else {
                        setSwitchLane("");
                      }
                    }} checked={switchLane === "Green"} /> <label>Green</label></>}
                    {triageLane !== "Amber" && <><input type="checkbox" value="Amber" name="amber" onChange={() => {
                      if (switchLane !== "Amber") {
                        setSwitchLane("Amber");
                      } else {
                        setSwitchLane("");
                      }
                    }} checked={switchLane === "Amber"} /> <label>Amber</label></>}
                    {triageLane !== "Red" && <><input type="checkbox" value="Red" name="red" onChange={() => {
                      if (switchLane !== "Red") {
                        setSwitchLane("Red");
                      } else {
                        setSwitchLane("");
                      }
                    }} checked={switchLane === "Red"} /> <label>Red</label></>}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="col-12">
                    <h5>Comments</h5>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter Comments"
                      rows="5"
                      value={comments}
                      onChange={(event) => { setComments(event.target.value.replace(/[&/\\#,`+()$~%.'":*?<>{}]/g, '')); }}
                    />
                  </Form.Group>
                </Row>
                <Row className="justify-content-center mt-3">
                  <Col className="col-lg-3 col-md-3 col-sm-6 col-6">
                    <button type="button" className="btn btn-primary btn-md font-weight-medium" onClick={async (e) => {
                      e.preventDefault();
                      if (comments.trim().length === 0) {
                        setShowModalMsg(`Enter comments for moving to ${switchLane} Queue`);
                        setShowModal(true);
                        return;
                      }
                      setEvtShowModalYesNo({ "params": { "evt": e, "submissionIdentifier": submissionIdentifier }, "option": "perform-triage" });
                      setShowModalYesNoMsg(`You are about to update the Submission details ${submissionIdentifier}, do you want to continue?`);
                      setShowModalYesNo(true);
                    }}>Submit</button>
                  </Col>
                  <Col className="col-lg-3 col-md-3 col-sm-6 col-6">
                    <button type="button" className="btn btn-primary btn-md font-weight-medium" onClick={() => setShowSetReviewed(false)}>Cancel</button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form.Group>
        </Modal.Body>
        <div className="modal-footer">
        </div>
      </Modal>
      {/* End Modal */}
      {/* Mini Modal */}
      <Modal
        className="modal-centre"
        backdrop="static"
        keyboard={false}
        show={loading}
        animation={false}
        centered
      >
        <Modal.Body className="text-center">
          <CirclesLoader isLoading={loading} />
        </Modal.Body>
      </Modal>
      {/* End Modal */}

      {/* Mini Modal */}
      <Modal
        className="modal-md modal-primary"
        show={showModalYesNo}
        onHide={() => setShowModalYesNo(false)}
        animation={false}
        backdropClassName="question-modal"
      >
        <Modal.Header className="justify-content-center">
          <div className="modal-profile">
            <i className="nc-icon nc-bulb-63"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p className="multi-line">{renderReactFragment(showModalYesNoMsg)}</p>
        </Modal.Body>
        <div className="modal-footer justify-content-center">
          <button type="button" className="btn btn-secondary btn-md font-weight-medium" onClick={async () => {
            if (evtShowModalYesNo.option === "perform-triage") {
              setShowSetReviewed(false);
              setLoading(true);
              try {
                const updSubmissionReport = {
                  submissionIdentifier: selectedSubmissionIdentifier,
                  document_id: selectedDocument_id,
                  TriageReviewed: triageReviewed === true ? 1 : 0,
                  TriageComments: comments.trim().length === 0 ? undefined : comments,
                  TriageLane: switchLane !== "" ? switchLane : triageLane,
                };
                const user = await Auth.currentAuthenticatedUser({
                  bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
                });
                const body = {
                  "access_token": user.signInUserSession.idToken.jwtToken,
                  "target": "updateSubmissionsReport",
                  "submissionsReportInput": updSubmissionReport
                };
                const init = {
                  body: body
                };
                const res = await invokeLambdaWithRetry("instanceHandler", init);
                console.log(res);
                setSearchParamsChanged(true);
              } finally {
                setLoading(false);
              }
            }
            setShowModalYesNo(false);
          }}>Yes</button>
          <button type="button" className="btn btn-primary btn-md font-weight-medium" onClick={() => {
            setShowModalYesNo(false);
            setEvtShowModalYesNo(null);
          }}>No</button>
        </div>
      </Modal>
      {/* End Modal */}
      {/* Mini Modal */}
      <Modal
        className="modal-mini modal-primary"
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={false}
      >
        <Modal.Header className="justify-content-center">
          <div className="modal-profile">
            <i className="nc-icon nc-bulb-63"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p className="multi-line">{renderReactFragment(showModalMsg)}</p>
        </Modal.Body>
        <div className="modal-footer justify-content-center">
          <button type="button" className="btn btn-primary btn-md font-weight-medium" onClick={() => setShowModal(false)}>Close</button>
        </div>
      </Modal>
      {/* Mini Modal */}
      <Modal
        className="modal-md modal-primary"
        show={showCommentsModal}
        animation={false}
      >
        <Modal.Body className="text-center">
        <Row>
        <Form.Group className="col-12">
          <h5>Comments</h5>
        <Form.Control
         as="textarea"
         rows="5"
         disabled
         className="text-dark bg-white"
         value={triageCommentsValue}
        />
       </Form.Group>
       </Row>
        <Row className="justify-content-center mt-3">
         <Col className="col-12">
             <button type="button" className="btn btn-primary btn-md font-weight-medium" onClick={() => setShowCommentsModal(false)}>OK</button>
         </Col>
        </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Triage;